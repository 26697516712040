import React from "react";
import Button from "@material-ui/core/Button";
import { useLocale, useSetLocale, useTranslate } from "react-admin";
import ReactCountryFlag from "react-country-flag";
import {makeStyles} from '@material-ui/styles';
import { Grid } from "@material-ui/core";
import {connect} from 'react-redux';
import {setMessage} from '../actions/message';
const useStyles = makeStyles(()=>({
    flag:{
        fontSize: '2em',
        lineHeight: '2em',
        borderRadius:50,
    },
    wrapperStyle:{
       width:200
    }
}));

const LocaleSwitcher = ({setMessage}) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles({locale:locale});
  const translate = useTranslate();
  return (
    <Grid container display="row" alignItems="center" className={classes.wrapperStyle}>
        <Grid item xs={6}>
            <Button 
                fullWidth 
                disabled={locale === "pl"} 
                onClick={() => {
                    setMessage(translate('global.messages.language'));
                    setLocale("pl"); 
                    localStorage.setItem('lang',"pl");
                }}
            >
                <ReactCountryFlag
                        countryCode="pl"
                        svg
                        className={classes.flag}
                        style={{
                            opacity: locale==="pl" && 0.5,
                        }}
                />
            </Button>
        </Grid>
        <Grid item xs={6}>
            <Button 
                fullWidth
                disabled={locale === "en"} 
                onClick={() => {
                    setMessage(translate('global.messages.language'));
                    setLocale("en"); 
                    localStorage.setItem('lang',"en");
                }}
            >
                <ReactCountryFlag
                    countryCode="gb"
                    svg
                    className={classes.flag}
                    style={{
                        opacity: locale==="en" && 0.5,
                    }}
                />
            </Button>
        </Grid>
    </Grid>
        
      
        
    
  );
};

export default connect(null,{setMessage})(LocaleSwitcher);