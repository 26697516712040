import React,{useEffect, useState} from 'react'
import {Title, useTranslate} from 'react-admin';
import MaterialTable from 'material-table';
import {history} from '../_helpers';
import { Avatar, Paper, Tabs, Tab, Box, TextField, Button,InputAdornment, Badge, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import {getUsers, getInvitations, getFollowUsers,searchUser,discardInvitation, confirmInvitation,inviteUserEmail } from '../data.sercive';
import {photoUrl} from '../config/config';
import {makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from 'react-redux';
import {setMessage} from '../actions/message';
import {removeInvitationsCount} from '../actions/profile';
import SendIcon from '@material-ui/icons/Send';


const useStyles = makeStyles((theme) => ({
    searchPaper: {
        padding:'8px 8px',  
        [theme.breakpoints.down('md')]: {
            padding:'5px 5px,'
        },               
    },
    searchInput:{
        width:'calc(100% - 97px)',
        marginRight: 8,
    },
    tabButtonStyle:{
        position:'absolute',
        top: 90,
        right: 29,
        [theme.breakpoints.down('md')]: {
            top: 72,
            right: 10,
        },
    },
    tabPaperStyle:{
        [theme.breakpoints.down('md')]: {
            marginTop: 30
        },

    },

    customBadge:{
        transform:'translate(20px,-5px)'
    },
    tableContainer:{
        width:'calc(100vw - 280px)',
        overflowX: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 57px)'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100vw - 20px)'
        },
    }
}))

const Users = ({setMessage,removeInvitationsCount,invitationsCount}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [requestData, setRequestData] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [inviteDialogIsOpen,setInviteDialogIsOpen] = useState(false);
    const [inviteEmail,setInviteEmail] = useState('');
    
    useEffect(()=>{
        setIsLoading(true);
        //Pobieranie listy użytkowników, wyszukiwanie, pobieranie followersów oraz zaproszeń w zależności od wybranej karty  
        const fetchData = () =>{
            switch(tabValue){
                case 0:
                    return (
                        !searchValue ? 
                        getUsers()
                            .then(res=>{
                                setRequestData(res.data)
                                setIsLoading(false);
                            })
                        : 
                        searchUser(searchValue)
                            .then(res=>{
                                setRequestData(res.data)
                                setIsLoading(false);
                             })
                    )
                case 1:
                    return (
                        getFollowUsers()
                            .then(res=>{
                                setRequestData(res.data)
                                setIsLoading(false);
                            })
                    )
                case 2:
                    return (
                        getInvitations()
                            .then(res=>{
                                setRequestData(res.data)
                                setIsLoading(false);
                            })
                    )
                default:
                    return
                
            }
        }
        fetchData();
        
    },[tabValue,searchValue])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearchUser =()=>{
    searchUser(searchValue).then(res=>{
        setRequestData(res.data)
        setIsLoading(false);
    })
  }
  const removeUser = (rowData) =>{
    const index = requestData.findIndex((user)=>user.userId === rowData.id )
    const newArray = [...requestData];
    newArray.splice(index,1);
    setRequestData(newArray);
  }

    const columns = [
        { title: translate('resources.users.fields.profilePic'), field:'avatar', render: rowData => <Avatar size={40} src={ rowData.avatar && `${photoUrl}/${rowData.avatar}`}/>},
        { title: translate('resources.users.fields.firstName'), field: 'firstName' },
        { title: translate('resources.users.fields.lastName'), field: 'lastName'},
        { title: translate('resources.users.fields.country'), field: 'country'},
        { title: translate('resources.users.fields.city'), field: 'city'},
        ];
    return (
        <>
            <Title title={translate('resources.users.name')}/>
            <Paper 
                square
                className = {classes.tabPaperStyle}
            >
                <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label={translate('resources.users.usersCategory.allPublicUsers')} />
                    <Tab label={translate('resources.users.usersCategory.followUsers')} />
                    <Tab label={
                        <Badge color="primary" badgeContent={invitationsCount} classes={{ badge: classes.customBadge }}>
                            {translate('resources.users.usersCategory.invitations')}
                        </Badge>
                        }
                    />
                    
                </Tabs>
                <Button 
                        variant = "contained"
                        color="primary"
                        className= {classes.tabButtonStyle}
                        onClick={()=>setInviteDialogIsOpen(!inviteDialogIsOpen)}
                        startIcon={<SendIcon/>}
                    >
                        {translate('resources.users.usersCategory.inviteEmail')}
                    </Button>
                
            </Paper>
            <Box mb = {1}/>
            {tabValue === 0 && 
                <Paper square className = {classes.searchPaper}>
                <Box>
                    <TextField 
                        placeholder = {translate('resources.users.filters.search')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={classes.searchInput}
                        value={searchValue}
                        onChange={(e)=>{
                            setSearchValue(e.target.value)
                            }}
                    />
                    <Button 
                        variant = "contained"
                        color="primary"
                        onClick = {()=>searchValue && handleSearchUser()}
                    >
                        Search
                    </Button>
                </Box>
                
            </Paper>
            }
            <Box mb = {1}/>
            <MaterialTable
            title={translate('resources.users.name')}
            columns={columns}
            components={{
                Container: (props) => <Paper className={classes.tableContainer} {...props}/>
                    }}
            data={requestData ? requestData.map(user=>({
                id: user.id || user.userId,
                avatar:user.profilePic,
                firstName:user.firstName,
                lastName:user.lastName,
                country:user.country || user.Country,
                city:user.city,
            })) : []}
            options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 600,
                    },
                    showTitle:false,
                    toolbar: tabValue === 0 ? false : true,
                    paging:false,
                }}
            actions={tabValue !== 0 ?[
                {
                    hidden: tabValue !== 2, 
                    icon: ()=><SaveIcon color="primary"/>,
                    tooltip: translate('resources.users.inviteRequest.confirm'),
                    onClick: (event, rowData) => {
                        //Potwierdzenie zaproszenia usuwa licznik Badge przy zdjęciu profilowym oraz usuwa użytkownika z listy zapraszających
                        confirmInvitation(rowData.id)
                            .then(()=>removeUser(rowData))
                            .then(()=>removeInvitationsCount())
                            .then(()=>setMessage(translate('resources.users.inviteResponse.confirm')))
                            .catch(()=>setMessage(translate('global.error'),'error'))
                           
                    }
                },
                {
                    hidden: tabValue === 0, 
                    icon: ()=><DeleteIcon color="primary"/>,
                    tooltip: translate('resources.users.inviteRequest.discard'),
                    onClick: (event, rowData) => {
                        discardInvitation(rowData.id)
                            .then(()=>removeUser(rowData))
                            .then(()=>tabValue === 2 && removeInvitationsCount())
                            .then(()=>setMessage(translate('resources.users.inviteResponse.discard')))
                            .catch(()=>setMessage(translate('global.error'),'error'))
                            
                    }
                }
            ]:[]}
            localization={{
                pagination: {
                    labelRowsSelect: translate('tableTooltip.pagination.labelRowsSelect'),
                    firstTooltip: translate('tableTooltip.pagination.firstTooltip'),
                    previousTooltip:translate('tableTooltip.pagination.previousTooltip'),
                    nextTooltip:translate('tableTooltip.pagination.nextTooltip'),
                    lastTooltip:translate('tableTooltip.pagination.lastTooltip'),
        
                },
                toolbar: {
                    searchTooltip: translate('tableTooltip.toolbar.searchTooltip'),
                    searchPlaceholder: translate('tableTooltip.toolbar.searchPlaceholder')
                },
                header: {
                    actions: translate('tableTooltip.header.actions')
                },
                
                body: {
                    emptyDataSourceMessage: translate('tableTooltip.body.emptyDataSourceMessage'),
                    addTooltip:  translate('tableTooltip.body.addTooltip'),
                    deleteTooltip:  translate('tableTooltip.body.deleteTooltip'),
                    editRow:{
                        deleteText: translate('tableTooltip.body.editRow.deleteText'),
                        cancelTooltip: translate('tableTooltip.body.editRow.cancelTooltip'),
                        saveTooltip: translate('tableTooltip.body.editRow.saveTooltip'),
                    }
                }
            }}
            isLoading={isLoading}
            onRowClick= {(event, rowData)=> history.push(`/profile/${rowData.id}/show`)}
            />

            { /*Zapraszanie po przez email - do dopracowania po stronie serwera*/}
            <Dialog 
                open={inviteDialogIsOpen} 
                onClose={()=>setInviteDialogIsOpen(!inviteDialogIsOpen)} 
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{translate('resources.users.usersCategory.inviteEmail')}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {translate('resources.users.invite.inviteEmailText')}
                </DialogContentText>
                
                <TextField
                    autoFocus
                    margin="dense"
                    label={translate('resources.profile.fields.email')}
                    type="email"
                    value={inviteEmail}
                    onChange={(e)=>setInviteEmail(e.target.value)}
                    fullWidth
                />
                
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setInviteDialogIsOpen(!inviteDialogIsOpen)} color="primary">
                    {translate('global.action.cancel')}
                </Button>
                <Button 
                    onClick={()=>{
                        inviteUserEmail(inviteEmail)
                            .then(res=>console.log(res))
                            .then(()=>setMessage(translate("resources.users.invite.success")))
                            .catch(()=>setMessage(translate("resources.users.invite.emailFailure"),"error"));
                        setInviteDialogIsOpen(!inviteDialogIsOpen);
                    }} 
                    color="primary">
                    {translate('global.action.save')}
                </Button>
                </DialogActions>
            </Dialog>
            
        </>
    )
}
const mapStateToProps = state =>({
    invitationsCount: state.user.invitations
})

export default connect(mapStateToProps,{setMessage,removeInvitationsCount})(Users);
