import React,{useState, useEffect} from 'react'
import {Title, useTranslate} from 'react-admin';
import { makeStyles} from '@material-ui/styles';
import { CropFree, } from "@material-ui/icons";
import EventNoteIcon from '@material-ui/icons/EventNote';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Box, Typography, Button, Dialog, DialogTitle, List, ListItemIcon, Checkbox, ListItemText, ListItem, DialogActions } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import InfoStatsPanel from '../../Components/InfoStatsPanel';
import TravelsTable from '../../Components/TravelsTable';
import {getUserGroupDetails,changeSingleGroupDetails,deleteSingleGroup} from '../../actions/groups';
import { connect } from 'react-redux';
import CircularProgress from '../../Components/CircularProgress';
import ConfirmAlert from '../../Components/ConfirmAlert';
import InlineEditTextField from '../../Components/InlineEditTextField';
import SingleGroupMap from '../../Components/SingleGroupMap';
import ShareIcon from '@material-ui/icons/Share';
import html2canvas from 'html2canvas';
import {dataURItoBlob} from '../../_helpers';
import { uploadTravelImage } from '../../data.sercive';
import { travelPhotos } from '../../config/config';
import {getTravels} from '../../data.sercive';
import PhotosSection from '../../Components/PhotosSection';



const useStyles = makeStyles((theme) => ({
    icon: {
        height:150,
        width:150,
    },
    flex: {
        display:'flex',
        alignItems:'center',
        margin:'10px 0'
    },
    smallIconMargin:{
        margin:'20px 10px 20px 15px',
    },
    button: {
        margin: 5,
    },
    list :{
        paddingLeft: 10,
        maxHeight:372,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 200,
          },
        overflow:'auto',
    },
    imageStyle:{
        height:'100%',
        width:'100%',
        objectFit:'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition:'transform 0.3s ease',
        '&:hover': {
            transform:'scale(1.3)',
        }
    },
    imageBox:{
        height:150,
        width:150,
        border:'2px solid #fce4ec',
        borderRadius:"5px",
        overflow:'hidden',
        cursor:'pointer',
        transition: 'all ease-in 200ms',
        '&:active':{
            transform: 'scale(0.95)'
        }
    },
    
}));

const TravelGroupDetail = ({groupDetails,getUserGroupDetails,match,changeSingleGroupDetails,deleteSingleGroup}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [confirmIsOpen,setConfrimIsOpen] = useState(false);
    const [images,setImages] = useState([]);
    const [showTripsIsOpen, setShowTripsIsOpen] = useState(false);
    const [allTravels, setAllTravels] = useState([]);
    const [checkedTravels, setCheckedTravels] = useState([]);


    useEffect(()=>{
        getUserGroupDetails(match.params.id);
    },[match.params.id, getUserGroupDetails])

    useEffect(()=>{
        
        const images = groupDetails.groupDetail.travels && groupDetails.groupDetail.travels.reduce((acc,travel)=>{
            return acc.concat(travel.images)
        },[]);

        setImages(images);
    },[groupDetails.groupDetail.travels])


    const handleSaveChanges=(dataType,value)=>{
        const name = dataType === "name" ? value : groupDetails.groupDetail.name;
        const description = dataType === "description" ? value ? value : "" :  groupDetails.groupDetail.description;
        changeSingleGroupDetails(match.params.id,name,description)
    }
    const handleToggleTravelsDialog = () =>{
        setShowTripsIsOpen(!showTripsIsOpen);
    }

    const handleShareTravel = () =>{
        let svgElements = document.body.querySelectorAll('svg');
        svgElements.forEach(function(item) {
            item.setAttribute("width", item.getBoundingClientRect().width);
            item.style.width = null;
        })

        //Canvas mapy 
        html2canvas(document.getElementById("leafletMapGroup"),{
            scrollY: -window.scrollY, 
            useCORS: true,
            allowTaint: true,
            logging: true,
            onclone: function(document) {
                document.querySelector(".leaflet-control").style.display = "none";
                document.getElementById("button-map-center").style.display = "none";
                document.querySelector(".leaflet-control-attribution").style.display = "none"; 
                document.querySelector(".leaflet-marker-pane").style.display = "none";
            },
            width: window.innerWidth > 600 ? window.innerWidth/2 : window.innerWidth ,
            scrollX: window.innerWidth > 600 ? -window.innerWidth/6 : -window.scrollX 
            
        }).then(canvas => {
            //Canvas statystyk
            html2canvas(document.getElementById("groupStats"),{
                scrollY: -window.scrollY, 
                width: window.innerWidth < 600 ? window.innerWidth : window.innerWidth/4,
                height : window.innerWidth >= 959 ? document.getElementById("groupStats").clientHeight : document.getElementById("groupStats").clientHeight - 50,
                onclone: function(document) {
                    document.getElementById("groupStats").style.paddingLeft = "20px";
                    document.getElementById("button-box").style.display = "none";
                    if(!groupDetails.groupDetail.description){
                        document.getElementById("descriptionBox").style.display="none"
                    }  
                }
            }).then(can => {
                let mainCanvas = document.createElement('canvas');
                let context = mainCanvas.getContext('2d');
                let photo = null //travelDetail.photos && travelDetail.photos.find(photo=>photo.isCover)

                mainCanvas.width = photo ? canvas.width+can.height+canvas.height * (16/9) : canvas.width+ can.width;
                mainCanvas.height = canvas.height;

                context.drawImage(canvas, 0, 0);
                context.drawImage(can, canvas.width,0);
                const fd = new FormData();
                fd.append("file", dataURItoBlob(mainCanvas.toDataURL()));
                fd.append("description",'share')
                const id = groupDetails.groupDetail.travels[0].id;
                
                uploadTravelImage(fd,id).then(res=>{
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${`${travelPhotos}/${res.data[0].filePath}`}`)
                });
            })
        })
       
    }
    
    return (
        <>
            <Title title = {translate('resources.travelgroups.show.name')}/>
            {groupDetails.isRequesting ? <CircularProgress/> :
            <>
            <div id = "groupStats">
            <Grid container>
                <Grid item xs={12} md = {7} className={classes.flex}>
                    <Box>
                        <CropFree color="primary" className={classes.icon}/>
                    </Box>
                    <Box>
                        <InlineEditTextField size="1.5rem" placeholder ={translate('resources.travelgroups.fields.name')} defaultValue={groupDetails.groupDetail.name} save={handleSaveChanges} dataType="name"/>
                        <Typography variant="subtitle2" className={classes.flex}> <EventNoteIcon color="primary"/> {groupDetails.groupDetail && groupDetails.groupDetail.startDate}</Typography>
                        <Typography variant="subtitle2" className={classes.flex}> <EventNoteIcon color="primary"/> {groupDetails.groupDetail && groupDetails.groupDetail.endDate}</Typography>
                    </Box>
                </Grid>
                <Grid container 
                    justify={window.innerWidth > 400 ? "flex-end" : 'center'}
                    alignItems="flex-start" 
                    item xs = {12} md = {5} 
                    id="button-box"
                >
                        
                        {groupDetails.groupDetail.travels && groupDetails.groupDetail.travels.length ? 
                            (<Button 
                                variant="contained"
                                color="primary" 
                                className={classes.button}
                                startIcon={<ShareIcon/>}
                                onClick={handleShareTravel}
                            >
                                {translate('global.action.share')}
                            </Button>
                            ):(null)
                        }
                        <Button 
                            variant="outlined" 
                            className={classes.button}
                            startIcon={<DeleteIcon color="primary"/>}
                            onClick={()=>{
                                setConfrimIsOpen(true);
                               
                            }}
                        >
                            {translate('resources.travelgroups.deleteGroup')}
                        </Button>                   
                </Grid>
                <Box m={2}/>
            </Grid>
            <Grid container >
                <Grid item xs={9} className={classes.flex}>
                    <Box className={classes.smallIconMargin}>
                        <MessageIcon color="primary" fontSize="large"/>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="primary">{translate('resources.travelgroups.fields.description')}</Typography>
                        <InlineEditTextField defaultValue={groupDetails.groupDetail.description} placeholder={translate('resources.travelgroups.fields.description')} dataType="description" save={handleSaveChanges} multiline/>
                    </Box>
                </Grid>
                <Grid container item xs={3} justify="flex-end" alignItems={window.innerWidth > 400 ? "flex-end" : "center"}>
                    <Button 
                        variant = "contained"
                        color="primary"
                        onClick={()=>{
                            handleToggleTravelsDialog();
                            if(!allTravels.length){
                                getTravels()
                                    .then(res=>{
                                        setAllTravels(res.data);
                                        const checked =  res.data.filter(travel => groupDetails.groupDetail.travels.find(tr=>tr.id === travel.id));
                                        setCheckedTravels(checked);     
                                    })
                            }
                        }}
                    >
                        {translate('resources.travelgroups.addTravels')}
                    </Button>
                </Grid>
            </Grid>
            <InfoStatsPanel stats={groupDetails.groupDetail && groupDetails.groupDetail}
            />
            </div>
            <Grid container>
                <Grid item xs = {12}>
                    <SingleGroupMap mapHeight={400} travels = {groupDetails.groupDetail.travels}/>
                </Grid>
                
            </Grid> 
            <Box mt={2}/>
            <PhotosSection photos = {images} id={match.params.id} type="group"/>   
            
            {groupDetails.groupDetail &&
                <Grid container item xs={12}>               
                    <TravelsTable showTravel travels = {groupDetails.groupDetail.travels && groupDetails.groupDetail.travels}/>
                </Grid>
            }
           
        
            </>
            }
             <ConfirmAlert 
                isOpened={confirmIsOpen} 
                toggleOpen={()=>setConfrimIsOpen(!confirmIsOpen)} 
                save ={()=> deleteSingleGroup(groupDetails.groupDetail.id)}
                title={translate("resources.travelgroups.sure")}
            />
            <Dialog onClose={handleToggleTravelsDialog} aria-labelledby="simple-dialog-title" open={showTripsIsOpen}>
                <DialogTitle id="simple-dialog-title">{translate('resources.travels.tableTitle')}</DialogTitle>
                    {
                        allTravels.length ? (
                            <>
                                <List style={{minWidth:300}}>
                                    {allTravels.length && allTravels.map(travel =>(
                                        <ListItem dense button key = {travel.id}>
                                            <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checkedTravels.find(checked =>checked.id === travel.id ) ? true : false}
                                                disableRipple
                                                color="primary"  
                                                onChange={()=>{
                                                    const index = checkedTravels.findIndex(checked => checked.id === travel.id);
                                                    const newArray = [...checkedTravels];
                                                    if(index!==-1){
                                                        newArray.splice(index,1)
                                                    }
                                                    else{
                                                        newArray.push(travel)
                                                    }
                                                    setCheckedTravels(newArray)
                                                    
                                                }} 
                                            />
                                            </ListItemIcon>
                                            <ListItemText  primary={travel.title} />
                                        </ListItem>
                                    ))}
                                   
                                </List>
                                <DialogActions>
                                    <Button
                                        onClick={()=>{
                                            handleToggleTravelsDialog();
                                            const travelTableID = checkedTravels.map(travel=> ({id:travel.id}));
                                            const allUnmarkedTravels =  allTravels.filter(value=>checkedTravels.indexOf(value) === -1)
                                            const uncheckedTable = groupDetails.groupDetail.travels.filter(value=>allUnmarkedTravels.find(tr=>tr.id === value.id)).map(travel=> ({id:travel.id}));
                                            changeSingleGroupDetails(match.params.id,groupDetails.groupDetail.name,groupDetails.groupDetail.description, travelTableID, uncheckedTable)
                                        }}
                                    >
                                        {translate('global.action.save')}
                                    </Button>
                                    <Button
                                        onClick={()=>{
                                            handleToggleTravelsDialog();
                                            setCheckedTravels(groupDetails.groupDetail.travels)
                                        }}
                                    >
                                        {translate('global.action.cancel')}
                                    </Button>
                                </DialogActions>
                            </>
                        ):(
                            <CircularProgress/>
                        )
                    }
                    
                </Dialog>
        </>
        
           
        
    )
}
const mapStateToProps =state=>({
    groupDetails: state.groups,
})

export default connect(mapStateToProps,{getUserGroupDetails,changeSingleGroupDetails,deleteSingleGroup})(TravelGroupDetail);
