import {types} from '../actions/types';

const initialState={
    isRequesting:false,
    requestSuccess:false,
    userGroups:[],
    groupDetail:'',
}



const groups =(state = initialState, action) => {
    switch(action.type){
        case types.GET_GROUPS_REQUEST:
            return{
                ...state, isRequesting:true,
            }
        case types.GET_GROUPS_SUCCESS:
            return{
                ...state, isRequesting:false, requestSuccess:true, userGroups: action.payload,
            }
        case types.GET_GROUPS_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccesss:false, 
            }
        case types.GET_GROUP_DETAILS_SUCCESS:
            return{
                ...state, isRequesting:false, requestSuccesss:true, groupDetail:action.payload, 
            }
        case types.GET_GROUP_DETAILS_FAIULURE:
            return{
                ...state, isRequesting:false, requestSuccesss:false,
            }
        case types.CREATE_TRAVEL_GROUP_SUCCESS:
            return{
                ...state,isRequesting: false, requestSuccesss:true,  userGroups:[...state.userGroups, action.payload]
            }
        case types.CREATE_TRAVEL_GROUP_FAILURE:
            return{
                ...state,isRequesting: false, requestSuccesss:false, 
            }
        
        case types.CHANGE_GROUP_DETAILS_SUCCESS:
            // Lepiej przerobić na odpowiedź z serwera podczas dodawania wycieczek do grupy - reponse z podliczonym dystansem i czasem trwania
            // Server zle zlicza czas i dystans - roznice pomiedzy pojedynczymi wynikami a ogolnym wynikiem kilku wycieczek
            const stats = {distance: 0, duration: 0}
            action.payload.travels.map(travel=>{
                stats.distance += travel.distance;
                stats.duration += travel.duration;
            })
            const newGroupStats = Object.assign(action.payload, stats);
            return {
                ...state, isRequesting:false, requestSuccesss:true, groupDetail: Object.assign(state.groupDetail,newGroupStats) 
            }
        case types.CHANGE_GROUP_DETAILS_FAILURE:
            return{
                ...state, isRequesting:false, requestSuccesss:false,
            }
        case types.CHANGE_TRAVEL_IN_GROUP:
            return {
                ...state, groupDetail: {...state.groupDetail }
            }
        case types.DELETE_GROUP_SUCCESS:
            const index = state.userGroups.findIndex(group => group.id === action.payload);
            const newArrayOfGroups = [...state.userGroups];
            newArrayOfGroups.splice(index,1)

            return{
                ...state, isRequesting:false, requestSuccesss:true, groupDetail:'', userGroups: newArrayOfGroups
            }     

        case types.DELETE_GROUP_FAILURE:
            return{
                ...state, isRequesting:false, requestSuccesss:false
            }           
        default: 
            return {...state}
        
    };
};
export default groups;