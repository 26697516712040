import {loginUser, checkRefreshToken} from '../data.sercive';
import {getToken,history} from '../_helpers';

const authProvider = {
    login: ({ username, password }) =>  {
        return loginUser(username,password)
        .then(response => {
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
        }).catch(err=>{
            throw new Error(err.message)
        });
    },
    refreshToken:()=>{
        const refreshToken = localStorage.getItem('refreshToken');
        if(refreshToken){
            checkRefreshToken(refreshToken)
                .then(response=>{
                    localStorage.setItem('token', response.data.access_token)
                    localStorage.setItem('refreshToken', response.data.refresh_token)
                    history.push('/dashboard')
                })
                .catch(()=>history.push('/login'))
        }else{
            history.push('/login')
        }
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        return Promise.resolve();
        
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            console.log(error)
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem("token")  ? Promise.resolve() : Promise.reject({redirectTo:'/login'}),

    getPermissions: async() => {
        const permission = getToken();
        return await permission ? Promise.resolve(permission) : Promise.reject();
    }
    
};

export default authProvider;