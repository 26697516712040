import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardContent, FormControl, Grid, OutlinedInput, Typography, Box, MenuItem, Select, Button, TextField, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Title, useTranslate} from 'react-admin';
import { connect } from 'react-redux';
import { getAllTravels } from '../../actions/travels';
import { getUserGroups } from '../../actions/groups';
import {ConvertMinutes, history, travelIconType} from '../../_helpers';
import CircleBorder from '../../Components/CircleBorder';
import MaterialTable from 'material-table';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TitleIcon from '@material-ui/icons/Title';
import { CropFree } from "@material-ui/icons";
import {searchTravel} from '../../data.sercive';
import TimerIcon from '@material-ui/icons/Timer';
import CircularProgress from '../../Components/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Backspace';

const useStyles = makeStyles((theme) => ({
    input: {
        width:'50%'
    },
    select:{
        width:'100%',
    },
    buttons:{
        height:40,
        margin:'0px 5px',               
    },
    mainCard:{
        marginBottom:20
    },
    tableContainer:{
        width:'calc(100vw - 297px)',
        overflowX: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 57px)'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100vw - 20px)'
        },
    },
    fabButton:{
        position:'fixed',
        bottom: 15,
        right:15,
    }

}));

const Travels =({getAllTravels,travels,groups,getUserGroups})=> {
    const classes = useStyles();
    const translate = useTranslate();
    const [isLoading,setIsLoading] = useState(false);
    const [distanceRange,setDistanceRange] = useState({
        distanceFrom:'',
        distanceTo:''
    })
    const [dateRange,setDateRange] = useState({
        dateFrom:'',
        dateTo:''
    })
    const [durationRange,setDurationRange] = useState({
        durationFrom:'',
        durationTo:''
    })
    const [travelTypes,setTravelTypes] = useState('');
    const [userGroup,setUserGroup] = useState('');

    const [travelsFound, setTravelsFound] = useState('');

    useEffect(()=>{
        getAllTravels();
        getUserGroups();
    },[])

    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            filterForm();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });
   
    const [state, setState] = useState({
        columns: [
        { title: translate('resources.travels.travelsTableHeaders.distance'), field: 'distance',
            render:rowData=> 
                <CircleBorder 
                    text ={
                        rowData.distance < 1000 ? 
                            rowData.distance.toFixed(0) 
                        : (rowData.distance/1000).toFixed(1)} 
                    optionalText={
                        rowData.distance < 1000 ? "m" : 'km'}
                />
        },
        { title: translate('resources.travels.travelsTableHeaders.date'), field: 'travelDate' ,type: 'date',headerStyle:{textAlign:'left'},
            render:rowData=>
            
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                > 
                    <EventNoteIcon color="primary"/>
                    <Box mr={1}/>
                    {rowData.travelDate}
                </Grid>
            
        },
        { title: translate('resources.travels.travelsTableHeaders.title'), field: 'description', headerStyle:{textAlign:'left'},
            render:rowData=>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > 
                <TitleIcon color="primary"/>
                <Box mr={1}/>
                {rowData.title}
        </Grid>
        },
        { title: translate('resources.travels.travelsTableHeaders.type'), field: 'travelTypeName' , headerStyle:{textAlign:'left'},
            render: rowData=> 
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > 
            
                {travelIconType(rowData.travelTypeName)}
                <Box mr={1}/>
                {rowData.travelTypeName && translate(`global.type.${rowData.travelTypeName.toLowerCase()}`)}
            </Grid>
        },
        { title: translate('resources.travels.travelsTableHeaders.group'), field: 'travelGroupName' , headerStyle:{textAlign:'left'},
            render: rowData=>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > 
                
                <CropFree color="primary"/>
                <Box mr={1}/>
                {rowData.travelGroupName}
            </Grid>
        },
        { title: translate('resources.travels.travelsTableHeaders.duration'), headerStyle:{textAlign:'left'},
            render: rowData=>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > 
               
                <TimerIcon color = "primary"/>  <Box mr={1}/> {rowData.duration ? ConvertMinutes(rowData.duration) : "0 min"}
            </Grid>
        },
        ],
    });

    const filterForm = () =>{
        setIsLoading(true);
        const distanceRangeFrom = distanceRange.distanceFrom * 1000;
        const distanceRangeTo = distanceRange.distanceTo * 1000;
        const durationRangeFrom = durationRange.durationFrom * 60;
        const durationRangeTo = durationRange.durationTo * 60;
        searchTravel(dateRange.dateFrom, dateRange.dateTo, distanceRangeFrom, distanceRangeTo, durationRangeFrom, durationRangeTo, userGroup,travelTypes)
            .then(res=>{
                setIsLoading(false);
                setTravelsFound(res.data)
            })
    }

    const clearForm = () =>{
        setDistanceRange({...distanceRange, distanceFrom: '',distanceTo:''})
        setDateRange({...dateRange, dateFrom: '',dateTo:''})
        setDurationRange({...durationRange, durationFrom: '',durationTo:''})
        setTravelTypes('');
        setUserGroup('');
        setTravelsFound('');
    }
    
    return (
        <>
        <Title title = {translate('resources.travels.name')}/>
        <Card className={classes.mainCard}>
            <CardHeader
                title={<Typography variant = "h6">  {translate('global.filters.filters')}</Typography>}
            />
            <CardContent>
            <Grid container direction="row"  justify="center" alignItems="flex-end" spacing={3}>
                <Grid item xs={12} md ={6} lg={4}>
                <Box >
                    <Typography color="primary">{translate('resources.travels.filters.distance')}:</Typography>
                    <FormControl  variant="outlined" size="small" className={classes.input}>
                        <OutlinedInput
                            placeholder={translate('resources.travels.filters.from')}
                            type="number"
                            value={distanceRange.distanceFrom}
                            onChange={e=>setDistanceRange({...distanceRange, distanceFrom: e.target.value > 0 ? e.target.value : 0})}
                        />
                        </FormControl>
                        <FormControl  variant="outlined" size="small" className={classes.input}>
                        <OutlinedInput
                            placeholder={translate('resources.travels.filters.to')}
                            type="number"
                            value={distanceRange.distanceTo}
                            onChange={e=>setDistanceRange({...distanceRange, distanceTo: e.target.value > 0 ? e.target.value : 0})}
                        />
                        </FormControl>
                </Box>
                </Grid>
                <Grid item xs={12} md ={6} lg={4}>
                <Box >
                    <Typography color="primary">{translate('resources.travels.filters.date')}:</Typography>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.dateFrom}
                        onChange={e=>setDateRange({...dateRange, dateFrom: e.target.value})}
                        className={classes.input}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={dateRange.dateTo}
                        onChange={e=>setDateRange({...dateRange, dateTo: e.target.value})}
                        className={classes.input}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                </Box>
                </Grid>
                <Grid item xs={12} md ={6} lg={4}>
                <Box >
                    <Typography color="primary">{translate('resources.travels.filters.duration')}:</Typography>
                        <FormControl  variant="outlined" size="small" className={classes.input}>
                        <OutlinedInput
                            placeholder={translate('resources.travels.filters.from')}
                            type="number"
                            value={durationRange.durationFrom}
                            onChange={e=>setDurationRange({...durationRange, durationFrom: e.target.value > 0 ? e.target.value : 0})}
                        />
                        </FormControl>
                        <FormControl  variant="outlined" size="small" className={classes.input}>
                        <OutlinedInput
                         placeholder={translate('resources.travels.filters.to')}
                            type="number"
                            value={durationRange.durationTo}
                            onChange={e=>setDurationRange({...durationRange, durationTo: e.target.value > 0 ? e.target.value : 0})}
                        />
                        </FormControl>
                   
                </Box>
                </Grid>
                <Grid item xs={12} md ={6} lg={4}>
                <Box >
                    <Typography color="primary">{translate('resources.travels.filters.type')}:</Typography>
                    <FormControl variant="outlined" size="small" className={classes.select}>
                    <Select
                        displayEmpty
                        value={travelTypes ? travelTypes : ''}
                        onChange={e=>setTravelTypes(e.target.value)}
                    >

                        <MenuItem value={''}>-</MenuItem>
                        {travels.travelTypes && travels.travelTypes.map(type=>(
                            <MenuItem 
                                key={type.id}
                                value={type.id}
                            >
                                {translate(`global.select.${type.name.toLowerCase()}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>           
                </Box>
                </Grid>
                <Grid item xs={12} md ={6} lg={4}>
                    <Box >
                        <Typography color="primary">{translate('resources.travels.filters.group')}:</Typography>
                        <FormControl variant="outlined" size="small" className={classes.select}>
                            <Select
                                displayEmpty
                                value={userGroup ? userGroup : ''}
                                onChange={e=>setUserGroup(e.target.value)}
                            >
                                <MenuItem value={''}>-</MenuItem>
                                {groups && groups.map(group=>(
                                    <MenuItem 
                                        key={group.id}
                                        value={group.id}
                                    >
                                        {group.name}
                                    </MenuItem>
                                ))}
                            
                            
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} md ={6} lg={4} >
                    
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.buttons}
                        onClick={filterForm}
                        startIcon={<SearchIcon/>}
                    >
                        {translate('global.filters.filter')}
                    </Button>
                    <Button 
                        variant="contained" 
                        className={classes.buttons}
                        onClick={clearForm}
                        startIcon={<BackspaceIcon color="primary"/>}
                    >
                        {translate('global.filters.clear')}
                    </Button>
                   
                </Grid>
            </Grid>
            </CardContent>
        </Card>
            {isLoading || !travels.travels ? 
                <CircularProgress/>
            :
                <MaterialTable
                    title={translate('resources.travels.tableTitle')}
                    columns={state.columns}
                    components={{
                        Container: (props) => <Paper className={classes.tableContainer} {...props}/>
                            }}
                    data={travelsFound ? travelsFound : travels.travels ? travels.travels : []}
                    options={{
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontWeight: 600,
                            },  
                            search:false,
                            paging: false
                        }}
                        localization={{
                            pagination: {
                                labelRowsSelect: translate('tableTooltip.pagination.labelRowsSelect'),
                                firstTooltip: translate('tableTooltip.pagination.firstTooltip'),
                                previousTooltip:translate('tableTooltip.pagination.previousTooltip'),
                                nextTooltip:translate('tableTooltip.pagination.nextTooltip'),
                                lastTooltip:translate('tableTooltip.pagination.lastTooltip'),
                    
                            },
                            toolbar: {
                                searchTooltip: translate('tableTooltip.toolbar.searchTooltip'),
                                searchPlaceholder: translate('tableTooltip.toolbar.searchPlaceholder')
                            },
                            header: {
                                actions: translate('tableTooltip.header.actions')
                            },
                            
                            body: {
                                emptyDataSourceMessage: translate('tableTooltip.body.emptyDataSourceMessage'),
                                addTooltip:  translate('tableTooltip.body.addTooltip'),
                                deleteTooltip:  translate('tableTooltip.body.deleteTooltip'),
                                editRow:{
                                    deleteText: translate('tableTooltip.body.editRow.deleteText'),
                                    cancelTooltip: translate('tableTooltip.body.editRow.cancelTooltip'),
                                    saveTooltip: translate('tableTooltip.body.editRow.saveTooltip'),
                                }
                            }
                        }}
                    isLoading={travels.isRequesting}
                    onRowClick= {(event, rowData)=> history.push(`/travels/${rowData.id}/show`)}
                />
            }
            {/*<Fab
                variant="extended"
                color="primary"
                className = {classes.fabButton}
                onClick={()=>history.push('/live')}
            >
                <NavigationIcon />
                {translate('resources.travels.startTracking')}
            </Fab>
            */}
        </>
    )
}
const mapStateToProps = state => ({
    travels: state.travels,
    groups: state.groups.userGroups,
})
export default connect(mapStateToProps,{getAllTravels,getUserGroups})(Travels);
