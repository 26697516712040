import {types} from './types';
import {register} from '../data.sercive';
import {history} from '../_helpers';

export const registerUser = (email,password) => {
    return dispatch => {
        dispatch(registerRequest())

        register(email,password)
            .then(response=>{
               dispatch(registerSuccess(response.data));
               history.push('/login');
            })
            .catch(()=>{
                dispatch(registerFailure('error'));
            })
    }
    function registerRequest(){
        return {type:types.REGISTER_REQUEST}
    };
    function registerSuccess(user){
        return {type:types.REGISTER_SUCCESS, payload:user}
    }
    function registerFailure(error){
        return{type:types.REGISTER_FAILURE, payload:error}
    }
}