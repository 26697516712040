import React from 'react';
import {CircularProgress as Progress,Box } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    circularProgressWrapper:{
        widht:'100%',
        margin:'50px 0px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}));

const CircularProgress = () => {
    const classes = useStyles();
    return (
        <Box className={classes.circularProgressWrapper}>
            <Progress/>
        </Box>
    )
}

export default CircularProgress
