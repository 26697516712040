import React from 'react';
import {Title, useTranslate} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import followMe from '../assets/followme_logo_white_720.png';
import webImpuls from '../assets/WEBimpuls-logo-2015-1200px.png';

const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },
    title: {
      fontWeight:600
    },
    card:{
        background:theme.palette.primary.main,
        minHeight: 360,
    },
    logo:{
        maxHeight:80,
        marginBottom:10,
    },
    margin:{
        margin:'20px 0'
    }
  }));

const About = () => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <>
        <Title title = {translate('resources.about.name')}/>
            <Grid container spacing={2} >
            <Grid item xs={12} md={6}>
             <Card className={classes.card}>
                <CardContent>
                    <img src={followMe} alt = "logo" className={classes.logo}/>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        {translate('resources.about.first')}
                    </Typography>
                    
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        {translate('resources.about.second')}
                    </Typography>
                    <Typography variant="body1" component="p" color="secondary" paragraph>
                        {translate('resources.about.copyright')}
                    </Typography>
                    </CardContent>
                </Card>
            </Grid>
             <Grid item xs={12} md={6}>
             <Card className={classes.card}>
                <CardContent>
                    <img src={webImpuls} alt = "logo" className={classes.logo}/>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        {translate('resources.about.third')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                    {translate('resources.about.fourth')}
                    </Typography>
                </CardContent>
            </Card>
            </Grid>
            </Grid>
            
            <Typography variant="h5" component="h2" className={classes.margin}>{translate('resources.about.contact')}</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
             <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h2" className={classes.title} color="secondary" paragraph >
                        {translate('resources.about.company')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        E-mail: biuro@webimpuls.pl
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        Website: www.webimpuls.pl
                    </Typography>
                    <Typography variant="h6" component="h2" className={classes.title} color="secondary" paragraph >
                        {translate('resources.about.konrad')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Konrad Ogar
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        E-mail: konrad.ogar@webimpuls.pl
                    </Typography>
                    

                   </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
            <Card className={classes.card}>
               <CardContent>
                    <Typography variant="h6" component="h2" className={classes.title} color="secondary" paragraph >
                        {translate('resources.about.android')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Jakub Zegar
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: jakubzegar3@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Sebastian Cetera
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: cetera.sebastian95@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Arkadiusz Bodura 
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: bodura.arkadiusz@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Karol Kilian
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: karolkilian911@gmail.com
                    </Typography>
                  </CardContent>
               </Card>
           </Grid>
           <Grid item xs={12} md={6} lg={3}>
            <Card className={classes.card}>
               <CardContent>
                    <Typography variant="h6" component="h2" className={classes.title} color="secondary" paragraph >
                        {translate('resources.about.web')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Przemysław Cichoń
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: przemyslawcichon97@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Dariusz Wantuch
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: dww7426@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Adrian Sitko
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: foresai221@gmail.com
                    </Typography>
                   </CardContent>
               </Card>
           </Grid>
           <Grid item xs={12} md={6} lg={3}>
            <Card className={classes.card}>
               <CardContent>
                    <Typography variant="h6" component="h2" className={classes.title} color="secondary" paragraph >
                        {translate('resources.about.back')}
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Bartosz Wojdałowicz
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary" paragraph>
                        E-mail: bartoszwojdalowicz@gmail.com
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        Nikodem Zawirski
                    </Typography>
                    <Typography variant="body1" component="h3" color="secondary">
                        E-mail: nikon96@gmail.com
                    </Typography>
                   </CardContent>
               </Card>
           </Grid>
           </Grid>
           </>
          
    )
}
export default About;