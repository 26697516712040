import React from 'react';
import { TableRow, TableCell, Box ,Grid } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TitleIcon from '@material-ui/icons/Title';
import { CropFree } from "@material-ui/icons";
import { ConvertMinutes, history } from "../_helpers";
import { connect } from 'react-redux';
import { getSingleTravelDetail } from '../actions/travels';
import {travelIconType} from '../_helpers';
import CircleBorder from './CircleBorder';
import {useTranslate} from 'react-admin';
import TimerIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles((theme) => ({
    tableRow: {
      height:100,
      cursor:'pointer',
      pointerEvents: 'auto',
    },   
}));

const TravelRow=({showTravel, getSingleTravelDetail,currentTravel, ...props})=> {
    const classes = useStyles();
    const translate = useTranslate();
    return (
            <TableRow 
                className={classes.tableRow} 
                onClick={()=>
                    history.push(`/travels/${props.id}/show`)
                } 
                hover 
            >
                <TableCell>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    > 
                    <CircleBorder text ={props.distance < 1000 ? props.distance.toFixed(0) : (props.distance/1000).toFixed(1)} optionalText={props.distance < 1000 ? "m" : 'km'}/>
                </Grid>
                </TableCell>
                <TableCell>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        > 
                            <EventNoteIcon color="primary"/>
                            {props.travelDate}
                    </Grid>
                </TableCell>
                
                <TableCell >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        > 
                        <TitleIcon color="primary"/>
                            {props.title}
                    </Grid>
                </TableCell>
                <TableCell >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        > 
                        {travelIconType(props.travelTypeName)}
                        <Box ml ={1}/>
                        { props.travelTypeName && translate(`global.type.${props.travelTypeName.toLowerCase()}`)}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        > 
                        <CropFree color="primary"/>
                        <Box ml ={1}/>
                        {props.travelGroupName}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        > 
                        <TimerIcon color = "primary"/> {props.duration ? ConvertMinutes(props.duration) : "0 min"}
                    </Grid>
                </TableCell>
            </TableRow>
    )
}
const mapStateToProps = state =>({
    currentTravel: state.travels.travelDetail
})
export default connect(mapStateToProps, {getSingleTravelDetail})(TravelRow);
