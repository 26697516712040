import { types } from './types';

export const setMessage =(message,status)=>{
    return dispatch =>{
        dispatch({type:types.SET_MESSAGE, payload: {message,status}})
    }

}
export const disableMessage = () =>{
    return dispatch =>{
        dispatch({type:types.DISABLE_MESSAGE})
    }
}