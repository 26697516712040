import React,{useRef, useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Map, TileLayer, Polyline} from 'react-leaflet';
import Button from '@material-ui/core/Button';
import { CardMedia, Card, CardHeader} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {travelPolylineType} from '../_helpers';
import {useTranslate} from 'react-admin';
import Control from 'react-leaflet-control';



const useStyles = makeStyles(() => ({
    cardContent:{
        overflow:'hidden',
        maxWidth: '100%',
        margin: '0px auto',
        height: mapHeight=> mapHeight.maxHeight,
    },
  }));


const DashboardMap =({travels,mapHeight})=> {
    const [logs, setLogs] = useState([]);
    const [travelsLogs, setTravelsLogs] = useState([]);
    const classes = useStyles({maxHeight: mapHeight});
    const translate = useTranslate();
    const [localization, setLocalization] = useState({
        coordinates:[50.01, 20.98],
        localizationPermission:false,
    })
    
    const mapRef = useRef(null);
    
 
    useEffect(()=>{
        const map = mapRef.current.leafletElement; 
        travels && travels.length === 0 &&
            navigator.geolocation.getCurrentPosition(function success(pos) {
                let crd = pos.coords;
                setLocalization({ coordinates:[crd.latitude,crd.longitude], localizationPermission:true,})
                
            })
        localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
    },[travels,localization.localizationPermission])

    useEffect(() => {
        const map = mapRef.current.leafletElement; 
        const travelsArray = travels || [] ;
        let bounds = [];
        
        const setPolylinesToMapDashboard = () =>{
            let trav =[];
            for(let i =0; i< travelsArray.length ; i++){
                let gpsLogs =[];
                let currentLogs=[];
                travelsArray.length && travelsArray[i].logs && travelsArray[i].logs.map((log, index)=>{
                    currentLogs.push([log.latitude, log.longitude])
                    if(log.latitude ===null){
                        return
                    }
                    if(log.paused || travelsArray[i].logs.length - 1 === index){
                            gpsLogs=[...gpsLogs,currentLogs];
                            bounds=[...bounds,currentLogs];
                            currentLogs=[];     
                    } 
                })
                trav.push({ id: travelsArray[i].id, color:travelsArray[i].color, line:travelsArray[i].line,logs:gpsLogs})
            }
            setLogs(bounds);
            setTravelsLogs(trav);
           
           
            bounds.length && map.fitBounds(bounds)
           
        }
        setPolylinesToMapDashboard();
        
    }, [travels]);
    
    return (
        <Card>
            <CardHeader
                title={translate("resources.dashboard.review")}
                subheader={translate("resources.dashboard.reviewSubtitle")}
            />
            <CardMedia className={classes.cardContent}>
                <Map 
                    ref={mapRef}
                    center={localization.coordinates}
                    id="leafletMap"
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {
                      travelsLogs &&  
                      travelsLogs.map(logs => 
                        logs.logs.map((polyline,index)=>(
                            <Polyline 
                                key={index}
                                color={logs.color ? logs.color : "green"}
                                positions={polyline}
                                dashArray={travelPolylineType(logs.line)}
                                weight={8} 
                            />
                        ))
                        
                      ) 
                    }
                    <Control position="bottomleft" >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            
                            onClick={()=>{
                                const map = mapRef.current.leafletElement; 
                                logs.length ? map.flyToBounds(logs) : localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
                            }}
                        >
                        {translate("global.action.center")}
                        </Button>
                    </Control>              
                </Map>
            </CardMedia>
            
        </Card>
    )
}
DashboardMap.propTypes = {
    mapHeight:PropTypes.number,
}
DashboardMap.defaultProps = {
    mapHeight: 500,
};
const mapStateToProps = state =>({
    travels: state.travels.travels,
});

export default connect(mapStateToProps)(DashboardMap);