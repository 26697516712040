export default  {
  language: "Language",
  global: {
    action: {
      add: "Add",
      back: "Go Back",
      cancel: "Cancel",
      confirm: "Confirm",
      create: "Create",
      delete: "Delete",
      edit: "Edit",
      list: "List",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      send: "Send",
      undo: "Undo",
      expand: "Expand",
      close: "Close",
      invite: "Invite",
      share: "Share",
      recover: "Recover",
      logout: "Logout",
      center: "Center",
      deleteResponse: "Deleting successful",
      updateResponse: "Updating successful",
      createResponse: "Creating successful",
      errorResponse: "Something went wrong. Try again!",
      
    },
    select:{
      all: "All",
      general: "General stats",
      cycling: "Cycling",
      walking: "Walking",
      running: "Running",
      car: "Car",
      motorbike: "Motorbike",
      ['by wheelchair']: "Wheelchair",
      boating: "Boating",
      kayaking: "Kayaking",
      airplane: "Airplane",
      other: "Other",
      none: "None",
      male: "Male",
      female: "Female",
      global: "Global",
      ['for followers']: "For followers",
    },
    type: {
      all: "All",
      general: "General stats",
      cycling: "Cycling",
      walking: "Walking",
      running: "Running",
      car: "Car",
      motorbike: "Motorbike",
      ['by wheelchair']: "Wheelchair",
      boating: "Boating",
      kayaking: "Kayaking",
      airplane: "Airplane",
      other: "Other",
      none: "None"
    },
    filters: {
      filters: "Filters",
      filter: "Filter",
      clear: "Clear",
      none: "None"
    },
    trips: "Trips",
    photos: "Galery",
    messages:{
      language:"Language changed to Polish",
    },
    error:"Ups... Something goes wrong",
    errorInfo:"Function temporarily unavailable"
  },
  slider: {
    firstSlogan: "Collect trips on your mobile device then...",
    secondSlogan: "manage them on the website and...",
    thirdSlogan: "share them with your friends on facebook or twitter."
  },
  login: {
    login: "Sign In",
    email: "E-mail Address",
    password: "Password",
    signIn: "Sign In",
    forgotPass: "Forgot your password?",
    reset: "Reset",
    create: "Don't have an account?",
    signUp: "Sign Up",
    error: "Password or email are incorrect",
    validate: "Fill in all fields",
  },
  recovery: {
    passRecovery: "Recovery",
    error:"Ups... something went wrong",
    validate:{
      first: "This passwords are different",
      second:"Fill in all fields"
    },
    newPassword: "New password",
    confirmedPassword: "Confirm password",
    reset: "Reset",
    back: "Back to ",
    loginPage: "login page",
  },
  register: {
    register: "Sign Up",
    email: "E-mail Address",
    password: "Password",
    confirm: "Confirm password",
    signUp: "Sign Up",
    already: "Already have an account?",
    signIn: "Sign In",
    validate:{
      first: "Passwords are different",
      second: "Fill in all fields",
      third: "Such data is already taken",
    }
  },
  reset: {
    sendResetEmail:'We have send an email with further instructions',
    header: "Reset password",
    email: "E-mail Address",
    back: "Go back to Login",
    error: "This email not exist",
    validate: "Fill in all fields",
    reset: "Reset",
  },
  cancel: {
    confirm: "Confirm your account cancelation",
    back: "Go back to ",
    login: "Login",
    page: "page"
  },
  resources: {
    dashboard: {
      name: "Dashboard",
      welcome: "Welcome back",
      review: "Review Your last five trips.",
      reviewSubtitle:"When You click on a travel it will popout on the map.",
      showMore: "VIEW ALL OF THEM",
      stats: "Your stats from last month",
      empty: "It looks like You were not active in the last month. So we cant present any statistics for You."
    },
    travels: {
      name: "Travels",
      tableTitle:"Your travels",
      travelsTableHeaders: {
        distance: "Distance",
        title: "Title",
        group: "Travel group",
        type: "Travel type",
        date: "Travel date",
        action: "Details",
        duration: "Duration",
      },
      filters: {
        distance: "Distance range [km]",
        date: "Date range",
        duration: "Duration range [h]",
        type: "Travel Type",
        group: "Group",
        from: "From",
        to: "To",
      },
      fields: {
        distance: "Distance",
        title: "Title",
        travelGroupName: "Travel Group",
        travelType: "Travel Type",
        travelDate: "Travel Date",
        
      },
      setings: "Setings",
      more: "Show more",
      deleteTravel: "Delete this travel",
      sure: "Are You sure You want to delete this travel?",
      startTracking: "Start tracking",
      
      show: {
        name: "Trip Details",
        logs: "Logs",
        start: "Start",
        end: "End",
        type: "Type",
        group: "Group",
        description: "Description",
        duration: "Duration",
        distance: "Distance",
        avg: "Avg Speed",
        max: "Max Speed",
        merge: "Merge",
        split: "Split"
      },
      photo:{
        photoSure: "Are You sure You want to delete this photo?",
        cover: "Cover",
        uncover: "Uncover",
        photoPrivate: "Private",
        photoPublic: "Public",
        description:'Description',
        info: "Select a place on the route to place the photo",
        show: "Show/hide photos",
        crop: "Crop image"
      },
      logs: {
        date: "Show logs",
        longitude: "Longitude",
        latitude: "Latitude"
      },
      merge: {
        header: "Select travels to merge into this one",
        accept: "Merge Now",
        cancel: "Cancel Merge",
        title: "Title",
        travelGroup: "Travel Group",
        travelType: "Travel Type"
      },
      split: {
        info: "Mark where You want to split travel",
        accept: "Split Now",
        cancel: "Cancel Split",
        information: "Be careful, You're gonna split travel in this place!"
      },
      crop:{
        crop: "Crop travel",

      },
      playTravel: {
        play:"Play",
        stop: "Stop",
        reset: "Reset",
        delay: "Delay",
        color: "Color",
        weight: "Weight",
      }
    },
    travelgroups: {
      name: "Travel Groups",
      fields: {
        count: "Amount of travels",
        name: "Name",
        description: "Description",
        dateCreated: "Creation Date"
      },
      new: {
        new: "New Group",
        header: "Enter group name to create it",
        groupName: "Group Name"
      },
      addTravels: "Travels",
      deleteGroup: "Delete this group",
      sure: "Are You sure You want to delete this travel group?",
      show: {
        name: "Group Details",
        created: "Created:",
        time: "Time Range:",
        description: "Description",
        duration: "Duration",
        distance: "Distance",
        travelList: {
          distance: "Distance",
          title: "Title",
          type: "Type",
          date: "Date"
        }
      }
    },
    users: {
      name: "Users",
      filters: {
        search:"Search user",
        country: "Country",
        city: "City"
      },
      fields: {
        profilePic: "Avatar",
        firstName: "First Name",
        lastName: "Last Name",
        country: "Country",
        city: "City"
      },
      user:{
        forFriendsInfo:'got an invitation to followers',
        wait: "Wait for response ...",
        buttonForFriend:"Invite him to follow his profile",
        private: "This profil is private"
      },
      usersCategory:{
        allPublicUsers: "Public users",
        followUsers: "Follow users",
        invitations: "Invitations",
        inviteEmail: "Invite for email",
      },
      invite:{
        success: "Invitation sent. Wait for response",
        failure: "This user has already received your intitation. Wait for response",
        emailFailure: "This user has already received your intitation or doesn't exist",
        inviteEmailText: "If user you are looking for is not in the list, it means that his profile is private. If you know his email address, send him an invitation.",
      },
      inviteRequest:{
        confirm: "Confirm invitation",
        discard: "Discard invitation"
      },
      inviteResponse:{
        confirm: "Added to followers",
        discard: "Removed from followers",
      }
    },
    profile: {
      name: "My Profile",
      edit: "Edit Profile",
      foreignName: "User Profile",
      profilePrivate: "Private",
      profilePublic: "Public",
      publicLevelGlobal: "Global",
      publicLevelForFollowers: "For followers",
      public: "Do you want your profile to be public?",
      fields: {
        firstName: "First Name",
        lastName: "Last Name",
        description: "About",
        country: "Country",
        city: "City",
        sex: "Sex",
        birthday: "Birthday",
        changePassword: "Change password",
        currentPassword: "Current password",
        newPassword: "New password",
        email: "E-mail",
      },

      accCancel: "Account Cancelation",
      emailConfirm: "We have send a link to your email confirming the account deletion",
      sure: "Are You sure You want to cancel Your account?",
      photoSure: "Are You sure You want to cancel this photo?",
      expire: "Your account will expire:",
      accountCancelation: "ACCOUNT CANCELATION",
      cancelDeletion: "Cancel deletion",
      canceledDeletion: "Canceled deletion account",

      other: "None",
      male: "Male",
      female: "Female",
      about: "About Me",
      day: "day",
      days: "days",
      stats: "Stats",
      statTypes: {
        amount: "Amount of Travels",
        duration: "Duration",
        distance: "Distance",
        days: "Days"
      }
    },
    about: {
      name: "About",
      first:
        "The FollowMe application is used to record trips, biking, walking and all activities as well as collecting them in a convenient and attractive way. All your trips can be grouped, combined, described and shared on community portals.",
      second:
        "We wish you to visit the whole world with the FollowMe application! Have a nice trip!",
      copyright: "Copyright WEBimpuls Sp. z o. o. 2017-2019 r.",

      third:
        "This application was created in WEBimpuls IT laboratory. WEBimpuls Company has been operating on the market since 2010. We combine two kind of our skils: programming and marketing to develop companies on the Internet. We design and manufacture websites, stores, portals and advanced WEB applications. Our marketing department takes care of the image of our clients on the Internet. The high quality of the services provided is the result of many years of experience of people creating a harmonious and professional team. At any time, you can use WEBimpuls knowledge and experience with our help - we are happy to share our knowledge.",
      fourth: "We invite you to cooperation.",

      contact: "Contact Us",
      company: "Company",
      konrad: "Design and Project Manager",
      android: "Android Developers",
      web: "Web Developers",
      back: "Back-end Developer"
    },
    invite: {
      name: "Invite friends",
      header: "Share this application with your friends and family.",
      email: "Through E-mail",
      write: "Write here your friend email address",
      address: "Email Address",
      social: "Social Networks",
      on: "On social network services"
    },
    live:{
      name: "Live view",
      followers: "Followers",
      publicLive: "Public Live",
      privateLive: "Private Live",
      invitedUsers: "Invited users",
    }
  },
  tableTooltip:{
    pagination: {
      labelRowsSelect:"rows",
      firstTooltip:"First page",
      previousTooltip:"Previous page",
      nextTooltip:"Next page",
      lastTooltip:"Last page"

    },
    toolbar: {
        searchTooltip: "Search",
        searchPlaceholder: "Search"
    },
    header: {
        actions: 'Actions'
    },
    
    body: {
        emptyDataSourceMessage: 'No data to display',
        addTooltip: "Add",
        deleteTooltip: "Delete",
        editRow:{
            deleteText:"Are you sure you want to delete this row?",
            cancelTooltip:"Anuluj",
            saveTooltip:"Save",
        }
    }
  }
};
