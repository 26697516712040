import axios from 'axios';
import {baseUrlApi, grant_type, authClientId, authClientSecret} from './config/config';

//Wszystkie dostępne zapytania w aplikacji

// AUTH

export const loginUser = (username,password) =>{
    return axios.post(`${baseUrlApi}/login`,
    {
        username: username,
        password: password,
        grant_type,
        client_id: authClientId,
        client_secret: authClientSecret
    })
}
export const checkRefreshToken = (refreshToken) =>{
    return axios.post(`https://api.letfollowme.com/oauth/v2/token`,
    {
        grant_type: "refresh_token",
        client_id: authClientId,
        client_secret: authClientSecret,
        refresh_token: refreshToken
    })
}


export const register = (email,password) => {
    return axios.post(`${baseUrlApi}/register`,{
        email:email,
        password:password,
        confirm: 1,
     });
}
export const sendLinkToRecoveryPassword = (email) =>{
    return axios.post(`${baseUrlApi}/forgotPassword`,{
        email
    })
}
export const resetPassword = (token,password) =>{
    return axios.post(`${baseUrlApi}/resetPassword/${token}`,{
        password
    })
}
export const checkInvitedUserEmail = (email) =>{
    return axios.get(`${baseUrlApi}/register/${email}`);
}

 // TRAVELS
export const getTravels = ()=> {
    return axios.get(`${baseUrlApi}/travels`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`} 
    })
}
export const getTravelsToDashboard = () =>{
    return axios.get(`${baseUrlApi}/travels/dashboard/last`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`} 
    })
}
export const searchTravel = (dateFrom,dateTo,distanceFrom,distanceTo,durationFrom,durationTo,groupID,typeID)=> {
    return axios.get(`${baseUrlApi}/travels?_end=10&_order=DESC&_sort=travelDate&_start=0${dateTo ? `&maxDate=${dateTo}`: ""}${distanceTo ? `&maxDistance=${distanceTo}`: ""}${durationTo ? `&maxDuration=${durationTo}`: ""}${dateFrom ? `&minDate=${dateFrom}`: ""}${distanceFrom ? `&minDistance=${distanceFrom}`: ""}${durationFrom ? `&minDuration=${durationFrom}`: ""}${groupID ?`&travelGroupId=${groupID}`: ""}${typeID ?`&travelTypeId=${typeID}`: ""}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`} 
    })
}

export const getTravelTypes = () =>{
    return axios.get(`${baseUrlApi}/traveltypes`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const getTravelDetail =(travelID)=> {
    return axios.get(`${baseUrlApi}/travels/${travelID}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const changeTravelDetails = (travelID,title,description,travelTypeId,travelGroupId)=>{
    return axios.put(`${baseUrlApi}/travels/${travelID}`,
    {
        title: title,
        typeId: travelTypeId,
        groupId: travelGroupId,
        description: description,
    },{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
      })
}


export const uploadTravelImage = (formData,travelID) =>{
    
    return axios.post(`${baseUrlApi}/travels/${travelID}/image`,formData
    ,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const getTravelImages = (travelID) =>{
    return axios.get(`${baseUrlApi}/travels/${travelID}/images`,
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const changeTravelImage = (travelID,imageID,newPhoto) => {
    return axios.put(`${baseUrlApi}/travels/${travelID}/image/${imageID}`, newPhoto,
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const deleteTravelImages = (travelID,imageID) =>{
    return axios.delete(`${baseUrlApi}/travels/${travelID}/image/${imageID}`,
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const deleteTravel = (travelId) =>{
    return axios.delete(`${baseUrlApi}/travels/${travelId}`,{
        headers:{'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
//do poprawy - status 500
export const cropTravel = (travelId,startLogId,endLogId) =>{
    return axios.put(`${baseUrlApi}/travels/${travelId}/crop`,
    {
        startLogId,
        endLogId
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const splitTravel = (travelId,logId) =>{
    return axios.put(`${baseUrlApi}/travels/${travelId}/split`,
    {
        logId
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const mergeTravels = (mainTravelId,travelsTableId) =>{
    return axios.put(`${baseUrlApi}/travels/merge`,
    {
        mainTravel: mainTravelId,
        travels:travelsTableId
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}


//GROUPS
export const getTravelGroups = () =>{
    return axios.get(`${baseUrlApi}/travelgroups`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const getTravelGroupDetail = (groupId) =>{
    return axios.get(`${baseUrlApi}/travelgroups/${groupId}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const changeTravelGroupData = (groupId,name,description,travels,travelsToDelete) =>{
    return axios.put(`${baseUrlApi}/travelgroups/${groupId}`,
    {
        name,
        description,
        travelsToDelete,
        travels,
        
           
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const createTravelGroup = (name,description) =>{
    return axios.post(`${baseUrlApi}/travelgroups`,
    {
        name,
        description
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const deleteGroup = (groupId) =>{ 
    return axios.delete(`${baseUrlApi}/travelgroups/${groupId}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
    
}


// PROFILE

export const getUserProfileData = () =>{
    return axios.get(`${baseUrlApi}/me`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const getUserProfileStats = () =>{
    return axios.get(`${baseUrlApi}/me/stats`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const changeUserProfileData = ( firstName, lastName,description,sex,dateOfBirth,country, city, publicProfile,publicLevel ) =>{
    return axios.put(`${baseUrlApi}/me`,
        {
        firstName,
        lastName,
        description,
        sex,
        dateOfBirth,
        country,
        city,
        public: publicProfile,
        publicLevel,
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`},
    })
}
export const changeUserProfilePassword = (oldPassword, newPassword) =>{
    return axios.put(`${baseUrlApi}/me/pass`,
        {
        oldPassword,
        newPassword
        },
        {
            headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
        }
    )
}
export const deleteAccount = () =>{
    return axios.delete(`${baseUrlApi}/me`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const confirmDeletingAccount =(token)=>{
    return axios.delete(`${baseUrlApi}/me/confirm/${token}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const cancelDeletingAccount =()=>{
    return axios.put(`${baseUrlApi}/me/cancelDeletion`,{},{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const uploadUserPhoto = (photo) =>{
    return axios.post(`${baseUrlApi}/me/uploadPhoto`, photo,
    {
        headers: {
        'Authorization':`Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data"}
    })
}
export const deleteUserPhoto = (imageToDelete) =>{
    return axios.delete(`${baseUrlApi}/me/deletePhoto`, {
        params:{
            imageToDelete,
        },
            headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
        })
}

//USERS

export const getUsers = () =>{
    return axios.get(`${baseUrlApi}/users`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const getUserDetail =(userId)=>{
    return axios.get(`${baseUrlApi}/users/${userId}`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const getUserDetailStats = (userId) =>{
    return axios.get(`${baseUrlApi}/users/${userId}/stats`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

//Community
export const getFollowUsers = () =>{
    return axios.get(`${baseUrlApi}/follow/users`,{
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}

export const findUser = (user) =>{
    return axios.get(`${baseUrlApi}/follow/user/${user}`,
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const inviteUserEmail = (email) =>{
    return axios.post(`${baseUrlApi}/invite`,{
        email
    },
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const inviteUserId = (id) =>{
     return axios.post(`${baseUrlApi}/follow/user/${id}`,{},{
            headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
        })
}
export const getInvitations = () =>{
    return axios.get(`${baseUrlApi}/follow/users/request`,
    {
        headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
    })
}
export const searchUser = (userData) =>{
    return axios.get(`${baseUrlApi}/follow/search`, {
            method: 'GET',
            params:{
                filter: userData,
            },
            headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
        })
}
export const confirmInvitation = (userId) =>{
     return axios.put(`${baseUrlApi}/follow/user/${userId}`, {
                status: 1,
            },
            {
                headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
            }
        )
}
export const discardInvitation = (userId)=>{
    return axios.delete(`${baseUrlApi}/follow/user/${userId}`, {
        method: 'DELETE',
        params:{
            status: 1,
        },
            headers: {'Authorization':`Bearer ${localStorage.getItem("token")}`}
        })
}




export const addPhoto = (photo) =>{
    const fd = new FormData();
    fd.append("file", photo);
    fd.append("tags", `przemeq`);
    fd.append("upload_preset", `itsfqhtq`);
    fd.append("api_key", "247926998118615");
    fd.append("timestamp", (Date.now() / 1000) || 0);
    return axios.post("https://api.cloudinary.com/v1_1/przemeq25/image/upload", fd);
}

