import {types} from '../actions/types';

const initialState={
    isRequesting:false,
    requestSuccess:false,
    userData:'',
    userStats:[],
    error:'',
    invitations:0
    
}

const profile = (state=initialState, action)=>{
    switch(action.type){
        case types.REQUESTING_OWNER_DATA:
            return{
                ...state, isRequesting:true,
            }
        case types.GET_OWNER_DATA_SUCCESS:
            return{
                ...state,isRequesting:false, requestSuccess:true, userData:action.payload
            }
        case types.GET_OWNER_DATA_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccess:false,
            }
        case types.GET_OWNER_STATS_SUCCESS:
            return{
                ...state,isRequesting:false, requestSuccess:true, userStats:action.payload
            }
        case types.GET_OWNER_STATS_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccess:false,
            }
        case types.CHANGE_USER_PASSWORD_SUCCESS:
            return{
                ...state,isRequesting:false, requestSuccess:true, 
            }
        case types.CHANGE_USER_PASSWORD_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccess:false, error:action.payload
            }
        case types.CHANGE_USER_DATA_SUCCESS:
            return{
                ...state,isRequesting:false, requestSuccess:true, userData:Object.assign(state.userData, action.payload)
            }
        case types.CHANGE_USER_DATA_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccess:false, error:action.payload
            }
        case types.CHANGE_USER_PHOTOS_ON_PROFILE:
            return{
                ...state, userData:Object.assign(state.userData, action.payload)
            }
        case types.DELETE_USER_PHOTOS_ON_PROFILE:
            return{
                ...state, userData:Object.assign(state.userData, action.payload)
            }
        case types.CHECK_INVITATIONS:
            return{
                ...state, invitations:action.payload
            }
        case types.REMOVE_INVITATIONS:
            return{
                ...state, invitations: state.invitations-1
            }
        case types.DELETION:
            return{
                ...state, userData:{...state.userData, expirationDate:action.payload}
            }
        default:
            return{...state}
    }
}

export default profile;