import React from 'react';
import { createHashHistory } from "history";
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import AccessibleIcon from '@material-ui/icons/Accessible';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import RowingIcon from '@material-ui/icons/Rowing';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';

export const history = createHashHistory();

export const getToken =()=> localStorage.getItem('token');


export const travelIconType =(type, size="small")=> {
    switch(type){
        case 'Cycling' : return (<DirectionsBikeIcon color="primary" fontSize={size} />)
        case 'Walking' : return (<DirectionsWalkIcon color="primary" fontSize={size} />)
        case 'Car': return (<DirectionsCarIcon color="primary" fontSize={size} />)
        case 'Running': return (<DirectionsRunIcon color="primary" fontSize={size} />)
        case 'Motorbike': return (<MotorcycleIcon color="primary" fontSize={size} />)
        case 'By wheelchair': return (<AccessibleIcon color="primary" fontSize={size} />)
        case 'Kayaking': return (<RowingIcon color="primary" fontSize={size} />)
        case 'Boating': return (<DirectionsBoatIcon color="primary" fontSize={size} />)
        case 'Airplane': return (<LocalAirportIcon color="primary" fontSize={size} />)
        default: return
    }
    
}
export const travelPolylineType = (lineType = "solid") => {
    switch(lineType){
        case 'solid': return "1"
        case 'dashed': return "7 5"
        case 'dotted': return "1 17"
        default: return "1"
    }
}
export const findId = (array, name) =>{
    let object = array.find(obj=> obj.name === name)
    return object.id;
}
export const ConvertMinutes =(min)=>{
    let days = Math.floor((min/60)/1440);
    let hours = Math.floor(((min/60)%1440)/60);
    let minutes = Math.floor(((min/60)%1440)%60);
    if(days>0){
        return(days + "d " + hours + "h "+ minutes +"min");
      }else if (hours>0){
        return(hours + "h "+minutes+"min");
      }else{
          return (minutes + "min")
      }
}
export const toLocalTime = (time) =>{
    let localTime = new Date(time);
    return localTime.toLocaleDateString();
}

export function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {
        type: 'image/png'
    });
}
export const colorConverter=(color)=>{
    switch(color){
        case 'red': return "#FF0000"
        case 'green': return "#008000"
        case 'navy': return "#000080"
        case 'azure': return "#F0FFFF"
        default: return "#FF0000"
    }
}
