import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Grid, Card, CardHeader, Typography, CardContent, Box} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimerIcon from '@material-ui/icons/Timer';
import StraightenIcon from '@material-ui/icons/Straighten';
import TodayIcon from '@material-ui/icons/Today';
import {useTranslate} from 'react-admin';
import {ConvertMinutes} from '../_helpers';

const useStyles = makeStyles((theme) => ({
    flexRow:{
        display:'flex',
        flexDirection:'row',
        flexWrap: 'no-wrap',
        alignItems:'center'
    },
    flexColumn:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:5,
        width:'100%',
        position:'relative'
    },
    iconSize:{
        width:50,
        height:50
    },
    typographyStyle:{
        fontWeight: 600,
    }
}));

const StatsCard = ({stats}) => {
    const translate = useTranslate();
    const classes = useStyles();

    
    
    return (
        <Card>
            <CardHeader 
                title={stats && stats.typeName ? translate(`global.type.${stats.typeName.toLowerCase()}`) : translate('global.type.general')}
                titleTypographyProps={{align:"center"}}
            />    
            <CardContent>
                <Grid container direction = "row" spacing = {4}>
                    <Grid item xs ={6}>
                        <Box className={classes.flexRow}>
                            <LocationOnIcon color = "primary" className={classes.iconSize}/>
                            <Box className={classes.flexColumn}>
                                <Typography variant = "body2" color="primary" align="center" className={classes.typographyStyle}>{translate('resources.profile.statTypes.amount')}</Typography>
                                <Typography >{stats ? stats.travelsAmount : "0"}</Typography>
                            </Box>
                            
                        </Box>
                    </Grid>
                    <Grid item xs ={6}>
                        <Box className={classes.flexRow}>
                            <TimerIcon color = "primary" className={classes.iconSize}/>
                            <Box className={classes.flexColumn}>
                                <Typography variant = "body2" color="primary" align="center" className={classes.typographyStyle}>{translate('resources.profile.statTypes.duration')}</Typography>
                                <Typography>{stats && stats.duration ? ConvertMinutes(stats.duration) : "0 min"} </Typography>
                            </Box>
                            
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction = "row" spacing = {4} >
                <Grid item xs ={6}>
                        <Box className={classes.flexRow}>
                            <StraightenIcon color = "primary" className={classes.iconSize}/>
                            <Box className={classes.flexColumn}>
                                <Typography variant = "body2" color="primary" align="center" className={classes.typographyStyle}>{translate('resources.profile.statTypes.distance')}</Typography>
                                <Typography>{stats && stats.distance ? (stats.distance/ 1000).toFixed(2) : "0"} km</Typography>
                            </Box>
                            
                        </Box>
                    </Grid>
                    <Grid item xs ={6}>
                        <Box className={classes.flexRow}>
                            <TodayIcon color = "primary" className={classes.iconSize}/>
                            <Box className={classes.flexColumn}>
                                <Typography variant = "body2" color="primary" align="center" className={classes.typographyStyle}>{translate('resources.profile.statTypes.days')}</Typography>
                                <Typography>{stats ? stats.days : "0"} </Typography>
                            </Box>
                            
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default StatsCard
