import {types} from './types';
import {getTravels,getTravelDetail,changeTravelDetails, getTravelTypes,deleteTravel,uploadTravelImage,getTravelImages,changeTravelImage,deleteTravelImages,getTravelsToDashboard} from '../data.sercive';
import {history} from '../_helpers';
import { getOwnerProfileData,getOwnerStats } from '../actions/profile';
import {setMessage} from './message';
import authProvider from './authProvider';

function getTravelsRequest(){
    return { type: types.GET_TRAVELS_REQUEST}
}
function getTravelsRequestSuccess(travels){
    return { type: types.GET_TRAVELS_SUCCESS, payload:travels}
}
function getTravelsRequestFailure(){
    return { type: types.GET_TRAVELS_FAILURE}
}

export const getAllTravels = () => {
    return dispatch => {
        dispatch(getTravelsRequest())

        getTravels()
            .then(response=>{
                dispatch(getTravelsRequestSuccess(response.data))            
            })
            .catch((err)=>{
                authProvider.refreshToken()
                dispatch(getTravelsRequestFailure())
            })
    }
}
export const getFiveTravelsToDashboard = () => {
    return dispatch => {
        dispatch(getTravelsRequest())

        getTravelsToDashboard()
            .then(response=>{
                dispatch(getTravelsRequestSuccess(response.data))            
            })
            .then(()=>{
                dispatch(getOwnerProfileData());
                dispatch(getOwnerStats());
            })
            .catch((err)=>{
                authProvider.refreshToken()
                dispatch(getTravelsRequestFailure())
            })
    }
}
export const getAllTravelTypes = () =>{
    return dispatch => {
        getTravelTypes()
            .then(types=>{
                dispatch(getTypes(types.data))
            })
            .catch(()=>authProvider.refreshToken())
    }
    function getTypes(travelType){
        return { type: types.GET_TRAVEL_TYPES ,payload:travelType}
    }
}

export const getSingleTravelDetail = (id) => {
    return dispatch => {
        dispatch(getDetailsRequest())
        getTravelDetail(id)
            .then(response=>{
                dispatch(getTravelDetailsRequestSuccess(response.data))
            })
            .then(()=>{
                dispatch(getTravelPhotos(id))
            })
            .catch((err)=>{
                authProvider.refreshToken() 
                dispatch(getTravelDetailsRequestFailure())
            })
    }
    function getDetailsRequest(){
        return { type: types.GET_DETAILS_REQUEST}
    }
    function getTravelDetailsRequestSuccess(travels){
        return { type: types.GET_TRAVEL_DETAILS_SUCCESS, payload:travels}
    }
    function getTravelDetailsRequestFailure(){
        return { type: types.GET_TRAVEL_DETAILS_FAIULURE}
    }
    

}
export const changeSingleTravelDetails = (travelID,title,description,travelTypeId,travelGroupId) => {
    return dispatch => {
        
        changeTravelDetails(travelID,title,description,travelTypeId,travelGroupId)
            .then(response=>{
                dispatch(setMessage("update"))
                dispatch(changeTravelDetailsRequestSuccess(response.data))
            })
            .catch(err=>{
                dispatch(setMessage("error","error"))
                dispatch(changeTravelDetailsRequestFailure())
            })

    }
    function changeTravelDetailsRequestSuccess(details,id){
        return { type: types.CHANGE_TRAVEL_DETAILS_SUCCESS, payload:Object.assign(details,{id:id})}
    }
    function changeTravelDetailsRequestFailure(){
        return { type: types.CHANGE_TRAVEL_DETAILS_FAILURE}
    }
}
export const deleteSingleTravel = (travelID) => {
    return dispatch => {
        dispatch(getTravelsRequest())

        deleteTravel(travelID)
            .then(response=>{
                dispatch(setMessage("delete"));
                history.push('/travels');
                dispatch(deleteTravelRequestSuccess(travelID))
            })
            .catch(err=>{
                history.push({pathname: '/error'});
                dispatch(deleteTravelRequestFailure())
            })

    }
    function deleteTravelRequestSuccess(id){
        return { type: types.DELETE_TRAVEL_SUCCESS, payload:id}
    }
    function deleteTravelRequestFailure(){
        return { type: types.DELETE_TRAVEL_FAILURE}
    }
}

export const addPhotosToTravel =(image,travelID)=>{
    return dispatch =>{

        uploadTravelImage(image,travelID)
        .then(response=>{
            dispatch(uploadPhotos(response.data[0]));
        })
        .catch(err=> dispatch(setMessage("Required png/ jpg/ jpeg, max size 2mb","error"))
        )
    }
    function uploadPhotos(photos){
        return {type:types.ADD_TRAVEL_PHOTOS, payload: photos }
    }
}
export const getTravelPhotos = (travelID) =>{
    return dispatch =>{

        getTravelImages(travelID)
        .then(response=>{
            dispatch(getPhotos(response.data));
        })
        .catch(()=>dispatch(setMessage("error","error")))
    }
    function getPhotos(photos){
        return {type:types.GET_TRAVEL_PHOTOS, payload: photos }
    }
}
export const deleteTravelPhoto =(travelID,imageID)=>{
    return dispatch =>{

        deleteTravelImages(travelID,imageID)
        .then(()=>{
            dispatch(deletePhoto(imageID));
            dispatch(setMessage("delete"));
        })
        .catch(()=>dispatch(setMessage("error","error")))
    }
    function deletePhoto(photo){
        return {type:types.DELETE_TRAVEL_PHOTO, payload: photo }
    }
}
export const editTravelPhoto =(travelID,imageID,newPhoto)=>{
    return dispatch =>{

        changeTravelImage(travelID,imageID,newPhoto)
        .then(response=>{
            dispatch(editPhoto(response.data));
            dispatch(setMessage("update"));
        })
        .catch(()=>dispatch(setMessage("error","error")))
    }
    function editPhoto(photo){
        return {type:types.CHANGE_PHOTOS_META_DATA, payload: photo }
    }
}