import React, { useEffect } from 'react';
import AdminLayout from './AdminLayout/AdminLayout';
import {Admin,Resource,resolveBrowserLocale } from 'react-admin';
import {ThemeProvider} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  AccountBox,
  LocationOn,
  CropFree,
  Group,
  Help,
  
} from "@material-ui/icons";
import  theme  from './theme';
import Dashboard from './Pages/Dashboard';
import Travels from './Pages/Travels/Travels';
import TravelDetail from './Pages/Travels/TravelDetails';
import TravelGroups from './Pages/Groups/TravelGroups';
import TravelGroupDetail from './Pages/Groups/TravelGroupDetails';
import Profile from './Pages/Profile/Profile';
import ProfileDetail from './Pages/Profile/ProfileDetails';
import Users from './Pages/Users';
import About from './Pages/About';
//import LiveView from './Pages/LiveView';
import customRoutes from './config/customRoutes';
import Login from './AuthPages/Login';
import authProvider from './actions/authProvider';
import {history} from './_helpers';
import {baseUrlApi} from './config/config';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import Message from './Components/Message';
import {getAllTravelTypes} from './actions/travels';
import {getOwnerProfileData} from './actions/profile';
import { connect } from 'react-redux';

import englishMessages from './locale/en.js';
import polishMessages from './locale/pl.js';

const messages = {
  en: englishMessages,
  pl: polishMessages,
  
}

export const dataProvider = baseUrlApi;

// Wybór języka: przeglądarka -> jężyk użytkownika -> default en
const i18nProvider = polyglotI18nProvider(locale => messages[locale] 
    , localStorage.getItem('lang') ? 
        Object.keys(messages).find((item)=> item === localStorage.getItem('lang')) ?
            localStorage.getItem('lang') : 
            Object.keys(messages).find((item)=> item === resolveBrowserLocale()) ?
              resolveBrowserLocale() :'en'  
        : 'en' 
);

function App({getAllTravelTypes,getOwnerProfileData}) {
  
  useEffect(() => {
    if(localStorage.getItem("token")){
       getAllTravelTypes();
       getOwnerProfileData();  
    }
  }, [])

  //Aplikacja oparta na frameworku React Admin z wykorzystaniem Redux - provider w pliku index.js

  return (  
    <ThemeProvider theme={theme}>
      <Admin
        i18nProvider={i18nProvider}
        loginPage={Login}
        dataProvider={dataProvider}
        layout={AdminLayout}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        history={history}
        title="FollowMe"
      > 
        <Message/>{ /*Message - snackbar z informacjami zwrotnymi dla użytkownika, dostępny w każdym komponencie połączonym ze storem.*/}
        <Resource name = "dashboard" list = {Dashboard} icon={<DashboardIcon color="secondary"/>} />
        <Resource name = "travels" list = {Travels} show={TravelDetail} icon={<LocationOn color="secondary"/>}/>
        <Resource name = "travelgroups" list = {TravelGroups} show={TravelGroupDetail} icon={<CropFree color="secondary"/>} />
        {/*Nowa funkcjonalnośc do śledzenia udostępnionych tras ( do dokończenia) - <Resource name = "live" list = {LiveView} icon={<NavigationIcon color="secondary"/>} />*/}
        <Resource name = "users" list = {Users} icon={<Group color="secondary"/>} />
        <Resource name = "profile" list = {Profile} show = {ProfileDetail} icon={<AccountBox color="secondary"/>} />
        <Resource name = "about" list = {About} icon={<Help color="secondary"/>} />
        
      </Admin>
    </ThemeProvider>
  );
}

export default connect(null, {getAllTravelTypes,getOwnerProfileData})(App);
