import React,{useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme,} from '@material-ui/core/styles';
import  AdminDrawer  from './AdminDrawer';
import {photoUrl} from '../config/config';
import {useLogout,  useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import { Box, ListItemIcon, Badge } from '@material-ui/core';
import {history} from '../_helpers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.down('md')]: {
      display:'none'
    }
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar:{
    justifyContent:'space-between',
  },
  margin: theme.mixins.toolbar,
    
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    position:'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 10px 20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  avatarMenu:{
    display:'flex',
    alignItems:'center',
    height:64,
  },
  menuIcon:{
    transition: 'all ease-in 300ms',
    transform: open => open.open ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  menu:{
    borderRadius:0,
  }
}));

const AdminLayout =({children,user,invitationsCount})=> {
    const translate = useTranslate();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const logout = useLogout();
    const classes = useStyles({open:Boolean(anchorEl)});

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            >
            <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap id="react-admin-title">
            
            </Typography>
            <div className={classes.avatarMenu}>
              <Hidden mdDown>
                <Typography variant = "subtitle1" >{user && user.firstName} {user && user.lastName}</Typography>
              </Hidden>
              <Box mr={1}/>
              <Badge color="primary" badgeContent={invitationsCount}>
                <Avatar src={user && user.profilePic ? `${photoUrl}/${user.profilePic}` : null} onClick={()=>invitationsCount > 0 && history.push('/users')} style={{cursor: invitationsCount > 0 && 'pointer'}}/>
              </Badge>
              <IconButton
                  color="inherit"
                  aria-controls="simple-menu" 
                  disableRipple={true}
                  disableFocusRipple={true}
                  onClick={handleClick}
                  
              >
                <ArrowDropDownIcon className={classes.menuIcon}/>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}    
                PaperProps={{
                  style: {
                    backgroundColor:'#f5f5f5',
                    borderTopLeftRadius:0,
                    borderTopRightRadius:0,
                    marginTop:48
                  }
                 }}
              >
                <MenuItem 
                  onClick={()=>{
                    handleClose(); 
                    history.push("/profile");
                  }}
                >
                  <ListItemIcon>
                    <AccountBoxIcon fontSize="small" color ="primary"/>
                  </ListItemIcon>
                  <Typography>{translate("resources.profile.name")}</Typography>
                </MenuItem>
                <MenuItem 
                  onClick={()=>{ 
                    handleClose(); 
                    logout()
                    }}
                  >
                     <ListItemIcon>
                      <PowerSettingsNewIcon fontSize="small" color ="primary"/>
                    </ListItemIcon>
                    <Typography>{translate("global.action.logout")}</Typography>
                  </MenuItem>
              </Menu>
            </div>
        </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
            <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, 
            }}
            >
            <AdminDrawer onMenuClick={()=>setMobileOpen(false)}/>
            </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
            <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            >
            <AdminDrawer/>
            </Drawer>
        </Hidden>
        </nav>
        <main className={classes.content}>
        <div className={classes.margin} />
        {children}
        </main>
    </div>
    );
}

const mapStateToProps = state =>({
  user: state.user.userData,
  invitationsCount: state.user.invitations
})

export default connect(mapStateToProps)(AdminLayout);