import {getUserProfileData,getUserProfileStats,changeUserProfileData,changeUserProfilePassword,uploadUserPhoto,getInvitations,deleteUserPhoto} from '../data.sercive';
import { types } from './types';
import authProvider from './authProvider';
import {setMessage} from './message';

function profileRequest(){
    return {type: types.REQUESTING_OWNER_DATA}
}
export const getOwnerProfileData =()=>{
    return dispatch =>{
        dispatch(profileRequest())

        getUserProfileData()
            .then(response=>{
                dispatch(getInvitationsCount());
                dispatch(getProfileDataSuccess(response.data))
            })
            .catch(err=>{
                authProvider.refreshToken()
                dispatch(getProfileDataFailure())
                
            })
    }
    function getProfileDataSuccess(user){
        return {type: types.GET_OWNER_DATA_SUCCESS, payload:user}
    }
    function getProfileDataFailure(){
        return {type: types.GET_OWNER_DATA_FAILURE}
    }
}
export const getOwnerStats =()=>{
    return dispatch =>{
        dispatch(profileRequest())

        getUserProfileStats()
            .then(response=>{
                dispatch(getProfileDataSuccess(response.data))
            })
            .catch(err=>{
                authProvider.refreshToken()
                dispatch(getProfileDataFailure())
                
            })
    }
    function getProfileDataSuccess(userStats){
        return {type: types.GET_OWNER_STATS_SUCCESS, payload:userStats}
    }
    function getProfileDataFailure(){
        return {type: types.GET_OWNER_STATS_FAILURE}
    }
}

export const changeUserPassword = (oldPassword,newPassword) =>{
    return dispatch =>{
        dispatch(profileRequest())

        changeUserProfilePassword(oldPassword,newPassword)
            .then(response=>{
                dispatch(setMessage("update"))
                dispatch(changePasswordSuccess())
            })
            .catch(err=>{
                dispatch(setMessage("error","error"))
                dispatch(changePasswordFailure(err.response.message))
                
            })
    }
    function changePasswordSuccess(){
        return {type: types.CHANGE_USER_PASSWORD_SUCCESS}
    }
    function changePasswordFailure(error){
        return {type: types.CHANGE_USER_PASSWORD_FAILURE, payload:error}
    }
}

export const changeUserData = (firstName, lastName,description,sex,dateOfBirth,country, city,publicProfile,publicLevel) =>{
    return dispatch =>{
        
        changeUserProfileData( firstName, lastName,description,sex,dateOfBirth,country, city,publicProfile,publicLevel )
            .then(response=>{
                dispatch(setMessage("update"))
                dispatch(changeProfieDataSuccess(Object.assign(response.data,{...response.data, dateOfBirth:response.data.dateOfBirth ? response.data.dateOfBirth.date.substring(0,10) : null})));
            })
            .catch(err=>{
                dispatch(setMessage("error","error"))
                dispatch(changeProfieDataFailure(err.response.message))
                
            })
    }
    function changeProfieDataSuccess(userData){
        return {type: types.CHANGE_USER_DATA_SUCCESS,payload:userData}
    }
    function changeProfieDataFailure(error){
        return {type: types.CHANGE_USER_DATA_FAILURE, payload:error}
    }
}
export const changeUserPhotos = (formData)=>{
    return dispatch =>{
        uploadUserPhoto(formData)
            .then((res)=>{
                dispatch(setMessage("update"));
                dispatch(changeProfilePhotos(res.data))
            }).catch(()=>{
                dispatch(setMessage('error','error'))
            });
    }
    function changeProfilePhotos(photo){
        return {type: types.CHANGE_USER_PHOTOS_ON_PROFILE, payload:photo}
    }
}
export const deleteUserPhotos = (imageTypeToDelete)=>{
    return dispatch =>{
        deleteUserPhoto(imageTypeToDelete)
            .then((res)=>{
                dispatch(setMessage("delete"));
                dispatch(deleteUserPhotosDispatch(res.data))
            }).catch(()=>{
                dispatch(setMessage('error','error'))
            });
    }
    function deleteUserPhotosDispatch(photo){
        return {type: types.DELETE_USER_PHOTOS_ON_PROFILE, payload:photo}
    }
}
export const getInvitationsCount = ()=>{
    return dispatch =>{
        getInvitations()
            .then((res)=>dispatch(getInvitationsDispatch(res.data.length)))
    }
    function getInvitationsDispatch(invitations){
        return {type: types.CHECK_INVITATIONS, payload:invitations}
    }
}
export const removeInvitationsCount =()=>{
    return dispatch =>{
        dispatch(removeOne())
    }
    function removeOne(){
        return {type: types.REMOVE_INVITATIONS}
    }
}
export const deletionActions =(date = null)=>{
    return dispatch =>{
        dispatch(deletionDispatch(date))
    }
    function deletionDispatch(date){
        return {type: types.DELETION, payload:date}
    }
}