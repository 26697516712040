import React,{useState, useEffect} from 'react'
import AuthContainer from '../Components/AuthContainer'
import { TextField, Typography, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { resetPassword } from '../data.sercive';
import {useTranslate} from 'react-admin';


const useStyles = makeStyles((theme) => ({
    marginInput:{
        margin:'10px 0'
     },
     marginButton:{
         margin:'20px 0px 0px 0px '
     },
     link:{
        color: theme.palette.primary.main, 
        textDecoration: 'inherit' ,
        cursor: 'pointer',
    },
     errorText:{
         color:"#ff0000",
         margin:'-20px 0 20px'
     },
     typography:{
        display:'flex',
        alignItems:'center',
        margin:'10px 0px 0px 0px ',
    },
    
}));

const Recovery =({match, history})=> {

    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [error,setError] = useState('');
    const classes = useStyles();
    const translate = useTranslate();

    const handleResetPassword = () => {
        if(confirmedPassword && password){
            confirmedPassword === password ? 
                resetPassword(match.params.token,password).then(res=>{
                    history.push("/login")
                })
                .catch(()=>{
                    setError(translate('recovery.error'));
                    history.push("/error")
                })
            : 
                setError(translate('recovery.validate.first'))
            
        }
        else{
            setError(translate('recovery.validate.second'));
        }
        
    }
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            handleResetPassword();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });

    return (
        <AuthContainer title={translate('recovery.passRecovery')}>
            {error && <Typography variant="body1" className={classes.errorText}>{error}</Typography>}
            <TextField 
                label={translate('recovery.newPassword')} 
                variant="outlined" 
                fullWidth 
                type="password"
                required
                error={!password.length && error ? true : false}
                className={classes.marginInput}
                value={password}
                onChange={event => setPassword( event.target.value )}
            />
            <TextField 
                label={translate('recovery.confirmedPassword')} 
                variant="outlined" 
                fullWidth 
                type="password"
                required
                error={!confirmedPassword.length && error ? true : false}
                className={classes.marginInput}
                value={confirmedPassword}
                onChange={event => setConfirmedPassword( event.target.value )}
            />
            <Button 
                color="primary" 
                variant="contained" 
                className={classes.marginButton} 
                onClick={handleResetPassword} 
                >
                    {translate('recovery.reset')}
                
            </Button>
            <span className={classes.typography}>
                <Typography 
                    variant="body1" 
                    color="textSecondary" 
                    display="inline"
                >
                    {translate('recovery.back')}
                </Typography>
                <Button variant="text" onClick = {()=>history.push('/login')} className={classes.link}><strong>{translate('recovery.loginPage')}</strong></Button>
            </span>
        </AuthContainer>
    )
}

export default Recovery;
