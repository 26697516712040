import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import ReduxThunk from 'redux-thunk'
import { all, fork } from 'redux-saga/effects';
import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
} from 'react-admin';
import registerReducer from './reducers/registerReducer';
import travelsReducer from './reducers/travelsReducer';
import groupsReducer from './reducers/groupsReducer';
import profileReducer from './reducers/profileReducer';
import messageReducer from './reducers/messageReducer';

export default ({authProvider,dataProvider,history}) => {
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        register: registerReducer,
        travels: travelsReducer,
        groups: groupsReducer,
        user: profileReducer,
        message:messageReducer,
    });
    const resettableAppReducer = (state, action) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);
   

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers =
        (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;
  
    const store = createStore(
        resettableAppReducer,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                ReduxThunk
            ),
            
        ),        
    );
    sagaMiddleware.run(saga);
    return store;
};