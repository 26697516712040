import React,{useState, useEffect } from 'react'
import { Box, IconButton, Dialog, DialogTitle as MuiDialogTitle, DialogActions, DialogContent, Typography, FormControlLabel, Switch, TextField, withStyles } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { travelPhotos } from '../config/config';
import { deleteTravelPhoto,editTravelPhoto } from '../actions/travels';
import { setMessage } from '../actions/message';
import { connect } from 'react-redux';
import ConfirmAlert from './ConfirmAlert';
import { useTranslate} from 'react-admin';
import { Map, TileLayer, Polyline, Marker } from 'react-leaflet';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import L from 'leaflet'; 


const useStyles = makeStyles((theme) => ({
    imageStyle:{
        maxHeight: window.innerHeight - 200,
        width: 'auto',
        height:'auto',
        maxWidth: 800,
        objectFit:'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    mapStyle:{
        width:900,
        height:400
    },
    descriptionStyle:{
        width: window.innerWidth>=600 ? 500 : '100%',
        margin:'0px 0px 0px 15px'
    },
    dialogActionStyle:{
        justifyContent:(editable)=>editable.editable ? 'space-between' : 'flex-end',
        flexWrap:'wrap'
    },
    contentTypography:{
        marginLeft:theme.spacing(2)
    },
    dialogContent:{
        padding:0
    }
}))
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Do edycji/usunięcia  dodanego zdjęcia - ustawienie na mapie/ zmiana statusu i opisu
const TravelImage = ({image,close,open,deleteTravelPhoto,travelDetail,editTravelPhoto, type,setMessage}) => {
    const translate = useTranslate();
    
    const [confirmIsOpen, setConfirmIsOpen] = useState(false);
    const [editable, setEditable] = useState(false);
    const [logs,setLogs] = useState([]);
    const [markerPosition,setMarkerPostion] = useState('');
    const [isCover,setIsCover] = useState(false);
    const [isPublic,setIsPublic] = useState(false);
    const [description,setDescription] = useState('');
    const classes = useStyles({editable:editable});
    const drawTravel = () =>{
        let gpsLogs = [];
        let currentLogs = [];
        travelDetail.logs && travelDetail.logs.map((log, i)=>{
            currentLogs.push([log.latitude, log.longitude])
            if(log.paused || travelDetail.logs.length - 1 === i){
                    gpsLogs=[...gpsLogs,currentLogs];
                    currentLogs=[]; 
            }
            return gpsLogs;
        })
        
        setLogs(gpsLogs) 
    }

    const changePhotoMetaData = () =>{
        if(markerPosition){
            const id = markerPosition && travelDetail.logs.find(log=>Math.abs(log.longitude - markerPosition.lng.toFixed(6)) < 0.001 && Math.abs(log.latitude - markerPosition.lat.toFixed(6)) < 0.001)
            const newImage = Object.assign({gpsLog: id.id, isCover:isCover ? 1 : 0, isPublic: isPublic ? 1 : 0, latitude:id.latitude, longitude:id.longitude, description:description})
            editTravelPhoto(image.travelId,image.image.id,newImage)
        }else{
            const newImageWithoutLogs = Object.assign({gpsLog:0, isCover:isCover ? 1 : 0, isPublic: isPublic ? 1 : 0, description:description})
            editTravelPhoto(image.travelId,image.image.id,newImageWithoutLogs)
        }
        
    }

    useEffect(()=>{
        if(editable){
            drawTravel();
            const {latitude,longitude,description}= image.image;
            setIsCover(image.image.isCover);
            setIsPublic(image.image.isPublic);
            latitude && longitude && setMarkerPostion({lat:latitude, lng: longitude})
            setDescription(description);
        }
    },[editable])
    var myRenderer = L.canvas({ tolerance: 20 });

    const setPointToMap = (e) =>{
        let markerPos = (e.latlng)
        
        const searchAllPontis = travelDetail.logs.find(log=>
            Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.003 
            && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.003)
        const searchSpecific = travelDetail.logs.find(log=>
            Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.002 
                && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.002)
        const searchMoreSpecific = travelDetail.logs.find(log=>
            Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.001 
                && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.001)
        const searchPrecise = travelDetail.logs.find(log=>
            Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.0001 
                && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.0001)
    
        if(searchPrecise){
            setMarkerPostion({lat:searchPrecise.latitude, lng:searchPrecise.longitude})
        }else if(searchMoreSpecific){
            setMarkerPostion({lat:searchMoreSpecific.latitude, lng:searchMoreSpecific.longitude})
        }else if(searchSpecific){
            setMarkerPostion({lat:searchSpecific.latitude, lng:searchSpecific.longitude})
        }else if (searchAllPontis){
            setMarkerPostion({lat:searchAllPontis.latitude, lng:searchAllPontis.longitude})
        } 
    }

    return (
        <Dialog
        open={open}
        onClose={close}
        aria-labelledby="customized-dialog-title"
        maxWidth = "xl"
      >
        <DialogTitle id="customized-dialog-title" onClose = {close}>{image.image.description}...</DialogTitle>
        <DialogContent className = {classes.dialogContent}> 
            {!editable && image.image && image.image.filePath ? ( 
                <img src={`${travelPhotos}/${image.image.filePath}`} alt="FollowMe" className={classes.imageStyle}/>   
                ): logs.length &&(
                    <>
                        <Typography variant = "subtitle1" className = {classes.contentTypography}>{translate("resources.travels.photo.info")}</Typography>
                        <Box mb={1}/>
                        <Map 
                            bounds={logs}
                            zoom={9}
                            className={classes.mapStyle}
                        
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        /> 
                        {logs.length && logs.map((polyline,index) =>(
                            <Polyline 
                            key={index}
                            color={travelDetail.color}
                            positions={polyline}
                            weight={8} 
                            renderer={myRenderer}
                            onClick={setPointToMap}
                            />
                        ))}
                        {markerPosition && <Marker position = {[markerPosition.lat, markerPosition.lng]} onClick ={()=>setMarkerPostion('')}/>}
                        </Map>
                    </>
                )
            }
        </DialogContent>
        <DialogActions className = {classes.dialogActionStyle}>
            {editable ? (
                <>
                <Box>
                    <TextField 
                        label={translate('resources.travels.photo.description')} 
                        value={description || ""}
                        onChange={(e)=>setDescription(e.target.value)}
                        className =  {classes.descriptionStyle}
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={isPublic ? true : false}
                            onChange={()=>{
                                setIsPublic(!isPublic)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        }
                        label={!isPublic ? translate('resources.travels.photo.photoPrivate'): translate('resources.travels.photo.photoPublic')}
                    />
                        <FormControlLabel
                        control={
                        <Switch
                            checked={isCover ? true : false}
                            onChange={()=>{
                                setIsCover(!isCover)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        }
                        label={!isCover ? translate('resources.travels.photo.uncover'): translate('resources.travels.photo.cover')}
                    />
                    </Box>
                    <Box>
                        <IconButton 
                            onClick={()=>{
                                changePhotoMetaData(); 
                                setEditable(false)
                                close()
                            }} 
                            color="primary"
                        >
                            <DoneIcon />
                        </IconButton>
                    </Box>
                </>
            ):(
                <>
                    <IconButton onClick={()=> type !== "group" ? setEditable(true) : setMessage(translate('global.errorInfo'),"error")} color="primary">
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        onClick={()=> type !== "group" ? setConfirmIsOpen(!confirmIsOpen) : setMessage(translate('global.errorInfo'),"error")} 
                        color="primary" autoFocus
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            )}
        </DialogActions>
        <ConfirmAlert 
            isOpened={confirmIsOpen} 
            toggleOpen={()=>setConfirmIsOpen(!confirmIsOpen)} 
            save ={()=> {deleteTravelPhoto(image.travelId,image.image.id); close()}}
            title={translate("resources.travels.photo.photoSure")}/> 
      </Dialog>
    )
}

const mapStateToProps = state =>({
    travelDetail: state.travels.travelDetail,
})
export default connect(mapStateToProps,{deleteTravelPhoto,editTravelPhoto,setMessage})(TravelImage);
