import React,{useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/styles';
import MapIcon from '@material-ui/icons/Map';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { CropFree } from "@material-ui/icons";
import MessageIcon from '@material-ui/icons/Message';
import { Typography, Grid, Box, Button, Hidden} from '@material-ui/core';
import {travelIconType,findId} from '../../_helpers';
import {Title, useTranslate} from 'react-admin';
import InfoStatsPanel from '../../Components/InfoStatsPanel';
import {connect} from 'react-redux';
import {getSingleTravelDetail,changeSingleTravelDetails,deleteSingleTravel,addPhotosToTravel} from '../../actions/travels';
import { getOwnerStats } from '../../actions/profile';
import CircularProgress from '../../Components/CircularProgress';
import InlineEditTextField from '../../Components/InlineEditTextField';
import InlineEditSelect from '../../Components/InlineEditSelect';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmAlert from '../../Components/ConfirmAlert';
import {getUserGroups} from '../../actions/groups';
import { travelPhotos } from '../../config/config';
import SingleTravelMap from '../../Components/SingleTravelMap';
import {setMessage} from '../../actions/message';
import ShareIcon from '@material-ui/icons/Share';
import html2canvas from 'html2canvas';
import {uploadTravelImage} from '../../data.sercive';
import {dataURItoBlob} from '../../_helpers';
import PhotosSection from '../../Components/PhotosSection';
import {getAllTravelTypes} from '../../actions/travels';

const useStyles = makeStyles((theme) => ({
    icon: {
        height:120,
        width:120,
        marginLeft:'-15px',
    },
    flex: {
        display:'flex',
        margin:'10px 0px'
    },
    smallIconMargin:{
        marginRight:10,
        paddingTop:8,
    },
    editButton:{
        margin:'20px 0px 0px 10px',
    },
    fullWidth:{
        width:'100%'
    }
}));

const TravelDetail =({
    getSingleTravelDetail,
    travel,
    travelDetail,
    match,
    travelTypes,
    userGroups,
    changeSingleTravelDetails,
    deleteSingleTravel,
    getUserGroups,
    addPhotosToTravel,
    setMessage,
    getAllTravelTypes
})=>{
    const classes = useStyles();
    const [selectedType, setSelectedType] = useState('');
    const translate = useTranslate();
    const [confirmIsOpen,setConfrimIsOpen] = useState(false);

    useEffect(()=>{
        getSingleTravelDetail(match.params.id);
        getUserGroups();
        !travelTypes.length && getAllTravelTypes();
    },[match.params.id,getSingleTravelDetail,getUserGroups])

    const handleSaveChanges =(dataType, value)=> {
        const travelTypeId = dataType === "travelType" ? findId(travelTypes,value) : travelDetail.travelTypeId;
        const travelGroupId = dataType === "group" ? value ? findId(userGroups,value): null : travelDetail.travelGroupId;
        const title = dataType === "title" ? value : travelDetail.title;
        const description = dataType === "description" ? value ? value : "" : travelDetail.description;
        changeSingleTravelDetails(match.params.id,title,description,travelTypeId,travelGroupId);
    }
    //Udostępnianie - share dialog -> łączenie kilku zdjęć - do dopracowania
    const handleShareTravel = () =>{
        let svgElements = document.body.querySelectorAll('svg');
        svgElements.forEach(function(item) {
            item.setAttribute("width", item.getBoundingClientRect().width);
            item.style.width = null;
        })

        //Canvas mapy 
        html2canvas(document.getElementById("leafletMapTravel"),{
            scrollY: -window.scrollY, 
            useCORS: true,
            allowTaint: true,
            logging: true,
            onclone: function(document) {
                document.querySelector(".leaflet-marker-pane").style.display = "none";
                document.querySelector(".leaflet-control").style.display = "none";
                document.getElementById("button-map-center").style.display = "none";
                document.querySelector(".leaflet-right").style.display = "none";
                document.querySelector(".leaflet-control-attribution").style.display = "none"; 
            },
            width: window.innerWidth > 600 ? window.innerWidth/2 : window.innerWidth ,
            scrollX: window.innerWidth > 600 ? -window.innerWidth/6 : -window.scrollX 
            
        }).then(canvas => {
           html2canvas(document.getElementById("travelStats"),{
            scrollY: -window.scrollY, 
            allowTaint: true,
            width: window.innerWidth >= 1300 ? 
               canvas.width : 
                window.innerWidth < 1300 && window.innerWidth >= 959 ? 
                    window.innerWidth - window.innerWidth/3 :  
                    window.innerWidth < 600 ? window.innerWidth : 
                window.innerWidth -20, 
            height : window.innerWidth >= 959 ? document.getElementById("travelStats").clientHeight : document.getElementById("travelStats").clientHeight - 100,
            onclone: function(document) {
                document.getElementById("travelStats").style.paddingLeft = "20px";
                document.getElementById("mapBox").style.paddingLeft = "30px";
                document.getElementById("groupBox").style.display="none";
                if(!travelDetail.description){
                    document.getElementById("descriptionBox").style.display="none"
                }
            }
        }).then((can)=>{
            let mainCanvas = document.createElement('canvas');
            let context = mainCanvas.getContext('2d');
            let photo = null //travelDetail.photos && travelDetail.photos.find(photo=>photo.isCover)

            mainCanvas.width = photo ? canvas.width+can.height+canvas.height * (16/9) : canvas.width;
            mainCanvas.height = canvas.height + can.height;

            context.drawImage(canvas, 0, 0);
            context.drawImage(can, 0, canvas.height);
            
           
                const fd = new FormData();
                fd.append("file", dataURItoBlob(mainCanvas.toDataURL()));
                fd.append("description",'share')
                uploadTravelImage(fd,match.params.id).then(res=>{
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${`${travelPhotos}/${res.data[0].filePath}`}`)
                });
               /* let image =  new Image();
                //image.crossOrigin = "anonymous";
                image.src = `${travelPhotos}/${photo.filePath}`;
                //image.crossOrigin = "anonymous";
                image.onload = function() {
                    context.drawImage(image,canvas.width +5 ,0,can.height+canvas.height * (16/9),can.height+canvas.height)
                    const fd = new FormData();
                    fd.append("file", dataURItoBlob(mainCanvas.toDataURL()));
                    fd.append("description",'share')
                    uploadTravelImage(fd,match.params.id).then(res=>{
                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${`${travelPhotos}/${res.data[0].filePath}`}`)
                    })
                    //document.body.appendChild(mainCanvas);
                };*/
                // 
            
            })
        })
    }
   



    return (
        <>
        <Title title = {translate('resources.travels.show.name')}/>

            {travel.isDetailsRequesting ? <CircularProgress/> :
            <>
            
            <SingleTravelMap/>
            <div id = "travelStats">
            <Grid container spacing={3}>
                <Grid item xs={12} md ={8}className={classes.flex} id ="mapBox">
                    <Box >
                        <MapIcon color="primary" className={classes.icon}/>
                    </Box>
                    <Box className={classes.fullWidth}>
                        <InlineEditTextField size="1.5rem" placeholder ={translate('resources.travels.fields.title')} defaultValue={travelDetail.title} save={handleSaveChanges} dataType="title" />
                        <Typography variant="subtitle2" className={classes.flex}> <EventNoteIcon color="primary"/> {travelDetail.startDate}</Typography>
                        <Typography variant="subtitle2" className={classes.flex}> <EventNoteIcon color="primary"/> {travelDetail.endDate}</Typography>
                    </Box>
                </Grid>
                <Hidden smDown>
                    <Grid container item md = {4} alignItems="flex-start" justify="flex-end" >
                    <div data-html2canvas-ignore="true">
                        <Button 
                            variant="contained" 
                            color="primary"
                            className={classes.editButton}
                            startIcon={<ShareIcon/>}
                            onClick={handleShareTravel}
                        >   
                            {translate('global.action.share')}
                        </Button>
                                            
                        <Button 
                            
                            variant="outlined" 
                            className={classes.editButton}
                            startIcon={<DeleteIcon color="primary"/>}
                            onClick={()=>{
                            setConfrimIsOpen(true);
                            }}
                        >
                        {translate('global.action.delete')}
                        </Button>
                        {/*<Button 
                            
                            variant="contained" 
                            className={classes.editButton}
                            color="primary"
                            onClick={()=>{
                                const url = new URL('http://hub.letfollowme.com:9000/.well-known/mercure');
                                url.searchParams.append('topic', 'https://api.letfollowme.com/api/v2' +'/travels/{id}');
                                
                                const eventSource = new EventSource(url,{withCredentials : true});
                                eventSource.onmessage = event => {
                                console.log(JSON.parse(event.data));}
                            }}
                        >
                        Sledz wycieczke
                        </Button>*/}
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
            <Hidden mdUp>
                <Grid container item xs = {12} alignItems="center" justify="flex-end" >
                    <div data-html2canvas-ignore="true">
                        <Button 
                            variant="contained" 
                            color="primary"
                            className={classes.editButton}
                            startIcon={<ShareIcon/>}
                            onClick={handleShareTravel}
                        >   
                            {translate('global.action.share')}
                        </Button>
                                            
                        <Button 
                            
                            variant="outlined" 
                            className={classes.editButton}
                            startIcon={<DeleteIcon color="primary"/>}
                            onClick={()=>{
                            setConfrimIsOpen(true);
                            }}
                        >
                        {translate('global.action.delete')}
                        </Button>
                        {/*<Button 
                            
                            variant="contained" 
                            className={classes.editButton}
                            color="primary"
                            onClick={()=>{
                                const url = new URL('http://hub.letfollowme.com:9000/.well-known/mercure');
                                url.searchParams.append('topic', 'https://api.letfollowme.com/api/v2' +'/travels/{id}');
                                
                                const eventSource = new EventSource(url,{withCredentials : true});
                                eventSource.onmessage = event => {
                                console.log(JSON.parse(event.data));}
                            }}
                        >
                        Sledz wycieczke
                        </Button>*/}
                    </div>
                </Grid>
                <Box m={2}/>
            </Hidden>
            <Grid container alignItems ="flex-start" spacing={3}>
                <Grid item xs={12} md = {3} className={classes.flex}>
                    <Box className={classes.smallIconMargin}>
                        {travelIconType(selectedType? selectedType : travelDetail.travelTypeName,"large")}
                        
                    </Box>
                    <Box>
                        <Typography variant="body2" color="primary">{translate('resources.travels.show.type')}</Typography>
                        <InlineEditSelect defaultValue={travelDetail.travelTypeName} save={handleSaveChanges} values = {travelTypes} dataType="travelType" change={setSelectedType} />
                       
                    </Box>
                </Grid>
                <Grid item xs={12} md = {3} className={classes.flex} id="groupBox">
                    <Box className={classes.smallIconMargin}>
                        <CropFree color="primary" fontSize="large"/>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="primary">{translate('resources.travels.show.group')}</Typography>
                        <InlineEditSelect defaultValue={travelDetail.travelGroupName} values = {userGroups} save={handleSaveChanges} dataType="group" nullValue dontTranslate/> 
                    </Box>
                </Grid>
                <Grid item xs={12} md = {6} className={classes.flex} id ="descriptionBox">
                    <Box className={classes.smallIconMargin}>
                        <MessageIcon color="primary" fontSize="large"/>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="primary">{translate('resources.travels.show.description')}</Typography>
                        <InlineEditTextField placeholder ="Opis"  defaultValue={travelDetail.description}  save={handleSaveChanges} dataType="description" multiline/>
                    </Box>
                </Grid>
            </Grid>
            <InfoStatsPanel stats = {travelDetail}/>
            
            </div>
            <PhotosSection photos = {travelDetail.photos} id={match.params.id} uploadPhoto={addPhotosToTravel}/>
            <ConfirmAlert 
                isOpened={confirmIsOpen} 
                toggleOpen={()=>setConfrimIsOpen(!confirmIsOpen)} 
                save ={()=> deleteSingleTravel(match.params.id)}
                title={translate("resources.travels.sure")}
            />
            
            </>
            
        }
        
        </>
    )
}
const mapStateToProps = state =>({
    travel: state.travels,
    travelDetail: state.travels.travelDetail,
    travelTypes: state.travels.travelTypes,
    userGroups: state.groups.userGroups,
    userStats: state.user.userStats
})
export default connect(mapStateToProps,{getSingleTravelDetail,changeSingleTravelDetails,deleteSingleTravel,getUserGroups,getOwnerStats,addPhotosToTravel,setMessage,getAllTravelTypes})(TravelDetail);