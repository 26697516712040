import React,{useRef, useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Map, TileLayer, Polyline, Marker,Popup } from 'react-leaflet';
import Button from '@material-ui/core/Button';
import { CardMedia, Card, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {travelPolylineType} from '../_helpers';
import {useTranslate} from 'react-admin';
import Control from 'react-leaflet-control';
import L from 'leaflet'; 
import { travelPhotos } from '../config/config';
import {toLocalTime} from '../_helpers';



const useStyles = makeStyles(() => ({
    cardContent:{
        overflow:'hidden',
        maxWidth: '100%',
        margin: '0px auto',
        height: mapHeight=> mapHeight.maxHeight,
    },
    popupImageStyle:{
        maxHeight:300,
        maxWidth: 300,
        height: 'auto',
        width:'auto',
     
    },
  }));


const SingleGroupMap =({travels,mapHeight})=> {
    const [logs, setLogs] = useState([]);
    const [travelsLogs, setTravelsLogs] = useState([]);
    const classes = useStyles({maxHeight: mapHeight});
    const translate = useTranslate();
    const [imageMarkers,setImageMarker] = useState([]);
    const [localization, setLocalization] = useState({
        coordinates:[50.01, 20.98],
        localizationPermission:false,
    })
    
    const mapRef = useRef(null);

    useEffect(()=>{
        const map = mapRef.current.leafletElement; 
        travels && travels.length === 0 &&
            navigator.geolocation.getCurrentPosition(function success(pos) {
                let crd = pos.coords;
                setLocalization({ coordinates:[crd.latitude,crd.longitude], localizationPermission:true,})
                
            })
        localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
    },[travels,localization.localizationPermission])
    
 
    useEffect(() => {
        const map = mapRef.current.leafletElement; 
        const travelsArray = travels || [] ;
        let bounds = [];
        
        const setPolylinesToMap = () =>{
            let trav =[];
            for(let i =0; i< travelsArray.length ; i++){
                let gpsLogs =[];
                let currentLogs=[];
                if(travelsArray.length){
                    travelsArray[i].logs.map((log, index)=>{
                        currentLogs.push([log.latitude, log.longitude])
                            if(log.paused || travelsArray[i].logs.length - 1 === index){
                                    gpsLogs=[...gpsLogs,currentLogs];
                                    bounds=[...bounds,currentLogs];
                                    currentLogs=[];     
                            } 
                    })
                    trav.push({ id: travelsArray[i].id, color:travelsArray[i].color, line:travelsArray[i].line,logs:gpsLogs})
                }
            }
            travels && travels.length ? setLogs(bounds) : setLogs([]);
            travels && travels.length ? setTravelsLogs(trav) : setTravelsLogs([]);
            
            bounds.length && map.fitBounds(bounds);
        
        }
        setPolylinesToMap();
        
    }, [travels]);

    useEffect(()=>{
        const markers =[];
        
        const images = travels && travels.reduce((acc,travel)=>{
            return acc.concat(travel.images)
        },[]);

        if(images && images.length){
            images.map((photo)=>{
                if(photo.latitude && photo.longitude){
                    markers.push(photo)
                }
                setImageMarker(markers);
            })
        } 

    },[])
    
    return (
        <Card>
           
            <CardMedia className={classes.cardContent}>
                <Map 
                    ref={mapRef}
                    center={localization.coordinates}
                    renderer={L.canvas()}
                    id="leafletMapGroup"
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {
                    travels && 
                        travelsLogs.map(logs => 
                            logs.logs.map((polyline,index)=>(
                                <Polyline 
                                    key={index}
                                    color={logs.color ? logs.color : 'green'}
                                    positions={polyline}
                                    dashArray={travelPolylineType(logs.line)}
                                    weight={8} 
                                />
                            ))  
                        )
                    }
                        { imageMarkers &&  imageMarkers.map((marker) => (
                            <Marker 
                                key = {marker.filePath}
                                position ={[marker.latitude,marker.longitude]}
                                icon = {
                                    L.divIcon({
                                        html: `<img src='${travelPhotos}/${marker.filePath}'/>`,
                                        className : marker.isCover ? 'image-icon-cover' : 'image-icon',
                                    })
                                }
                            >
                                <Popup>
                                    <div style={{display:'flex' ,flexDirection:'column', 'alignItems':'center'}}>
                                        <Typography variant = "subtitle1">{marker.description}</Typography>
                                        <Typography variant = "subtitle2">{marker.createdAt && toLocalTime(marker.createdAt)}</Typography>
                                        <img src={`${travelPhotos}/${marker.filePath}`} className = {classes.popupImageStyle}/>
                                    </div>
                                </Popup>
                            </Marker>
                            ))}
                    <Control position="bottomleft" >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            id="button-map-center"
                            onClick={()=>{
                                const map = mapRef.current.leafletElement; 
                                logs.length ? map.flyToBounds(logs) : localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
                            }}
                        >
                        {translate("global.action.center")}
                        </Button>
                    </Control>              
                </Map>
            </CardMedia>
            
        </Card>
    )
}
SingleGroupMap.propTypes = {
    mapHeight:PropTypes.number,
}
SingleGroupMap.defaultProps = {
    mapHeight: 300,
};


export default SingleGroupMap;