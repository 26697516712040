import React,{useState, useEffect} from 'react'
import AuthContainer from '../Components/AuthContainer'
import { TextField, Typography, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { useLogin, useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    marginInput:{
       margin:'10px 0'
    },
    marginButton:{
        margin:'20px 0px',
    },
    typography:{
        display:'flex',
        alignItems:'center',
        margin:'10px 0px 0px 0px ',
    },
    link:{
        color: theme.palette.primary.main, 
        textDecoration: 'inherit' ,
        cursor: 'pointer',
    },
    errorText:{
         color:"#ff0000",
         margin:'-20px 0 20px'
     }
    
}));
const Login = ({history}) => {

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const login = useLogin();
    const classes = useStyles();
    const translate = useTranslate();

    const loginUser = () => {
        
        if(username && password){
            setLoading(true);
            login({ username, password })
                .then(()=>history.push('/dashboard'))
                .catch(() => {
                    setLoading(false); 
                    setError(translate('login.error'))
                });
        }else{
            setError(translate('login.validate'));
        }
        
            
    };

    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            loginUser();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });

    useEffect(()=>{
        if(localStorage.getItem("token")){
            history.push('/dashboard')
        }
    },[])

    return (
        <AuthContainer title={translate('login.login')}>
            {error && <Typography variant="body1" className={classes.errorText}>{error}</Typography>}
            <TextField 
                label={translate('login.email')} 
                variant="outlined" 
                fullWidth 
                required
                autoComplete={username.toString()}
                id="email"
                autoFocus
                error={!username.length && error ? true : false}
                className={classes.marginInput} 
                value={username}
                onChange={e=>setUsername(e.target.value)}
            />
            <TextField 
                label={translate('login.password')}  
                variant="outlined" 
                type="password"
                fullWidth
                required
                error={!password.length && error ? true : false}
                className={classes.marginInput}
                value={password}
                onChange={e=>setPassword(e.target.value)}
            />
            <Button 
                color="primary" 
                variant="contained" 
                className={classes.marginButton} 
                onClick={loginUser} 
                disabled={loading}
            > 
            {loading ? 
                <CircularProgress color="secondary" size = {30}/> 
                : translate('login.signIn')
            }
            </Button>
            <span className={classes.typography}>
                <Typography 
                    variant="body1" 
                    color="textSecondary" 
                    display="inline"
                >
                    {translate('login.forgotPass')}
                </Typography>
                <Button variant="text" onClick = {()=>history.push('/reset-password')} className={classes.link}><strong>{translate('login.reset')}</strong></Button>
            </span>
            <span className={classes.typography}>
                <Typography 
                    variant="body1" 
                    color="textSecondary" 
                    display="inline"
                >
                    {translate('login.create')}
                </Typography>
                <Button variant="text" onClick = {()=>history.push('/register')} className={classes.link}><strong>{translate('login.signUp')}</strong></Button>
            </span>
        </AuthContainer>
        
    )
}

export default Login;
