export const types = {
    
    //Register action types
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    //Travels action types
    GET_TRAVELS_REQUEST: 'GET_TRAVELS_REQUEST',
    GET_TRAVELS_SUCCESS: 'GET_TRAVELS_SUCCESS',
    GET_TRAVELS_FAILURE: 'GET_TRAVELS_FAILURE',
    GET_TRAVEL_TYPES:'GET_TRAVEL_TYPES',
    GET_TRAVEL_DETAILS_SUCCESS: 'GET_TRAVEL_DETAILS_SUCCESS',
    GET_TRAVEL_DETAILS_FAIULURE:'GET_TRAVEL_DETAILS_FAIULURE',
    CHANGE_TRAVEL_DETAILS_SUCCESS:'CHANGE_TRAVEL_DETAILS_SUCCESS',
    CHANGE_TRAVEL_DETAILS_FAILURE: 'CHANGE_TRAVEL_DETAILS_FAILURE',
    DELETE_TRAVEL_SUCCESS: 'DELETE_TRAVEL_SUCCESS',
    DELETE_TRAVEL_FAILURE: 'DELETE_TRAVEL_FAILURE',

    //Groups action types
    GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
    GET_DETAILS_REQUEST: 'GET_DETAILS_REQUEST',
    GET_GROUPS_SUCCESS:'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAILURE:'GET_GROUPS_FAILURE',
    GET_GROUP_DETAILS_SUCCESS: 'GET_GROUP_DETAILS_SUCCESS',
    GET_GROUP_DETAILS_FAIULURE: 'GET_GROUP_DETAILS_FAIULURE',
    CHANGE_GROUP_DETAILS_SUCCESS: 'CHANGE_GROUP_DETAILS_SUCCESS',
    CHANGE_GROUP_DETAILS_FAILURE: 'CHANGE_GROUP_DETAILS_FAILURE',
    CREATE_TRAVEL_GROUP_FAILURE: 'CREATE_TRAVEL_GROUP_FAILURE',
    CREATE_TRAVEL_GROUP_SUCCESS: 'CREATE_TRAVEL_GROUP_SUCCESS',
    DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
    DELETE_GROUP_FAILURE: 'DELETE_GROUP_FAILURE',
    GET_TRAVEL_PHOTOS: 'GET_TRAVEL_PHOTOS',
    CHANGE_PHOTOS_META_DATA : 'CHANGE_PHOTOS_META_DATA',
    DELETE_TRAVEL_PHOTO: 'DELETE_TRAVEL_PHOTO',
    ADD_TRAVEL_PHOTOS: 'ADD_TRAVEL_PHOTOS',
    CHANGE_TRAVEL_IN_GROUP:'CHANGE_TRAVEL_IN_GROUP',

    //Profile action types
    REQUESTING_OWNER_DATA: 'REQUESTING_OWNER_DATA',
    GET_OWNER_DATA_SUCCESS: 'GET_OWNER_DATA_SUCCESS',
    GET_OWNER_DATA_FAILURE: 'GET_OWNER_DATA_FAILURE',
    GET_OWNER_STATS_SUCCESS: 'GET_OWNER_STATS_SUCCESS',
    GET_OWNER_STATS_FAILURE: 'GET_OWNER_STATS_FAILURE',
    CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_PASSWORD_FAILURE: 'CHANGE_USER_PASSWORD_FAILURE',
    CHANGE_USER_DATA_SUCCESS:'CHANGE_USER_DATA_SUCCESS',
    CHANGE_USER_DATA_FAILURE:'CHANGE_USER_DATA_FAILURE',
    CHANGE_USER_PHOTOS_ON_PROFILE: 'CHANGE_USER_PHOTOS_ON_PROFILE',
    DELETE_USER_PHOTOS_ON_PROFILE:'DELETE_USER_PHOTOS_ON_PROFILE',
    CHECK_INVITATIONS: 'CHECK_INVITATIONS',
    REMOVE_INVITATIONS: 'REMOVE_INVITATIONS',
    DELETION: 'DELETION',


    //Messages

    SET_MESSAGE:'SET_MESSAGE',
    SET_STATUS:'SET_STATUS',
    DISABLE_MESSAGE:'DISABLE_MESSAGE',
};