import React,{useEffect, useState} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Avatar , Grid, Typography, Box, Button} from '@material-ui/core';
import {Title, useTranslate} from 'react-admin';
import {getUserDetail, getUserDetailStats,inviteUserId} from '../../data.sercive';
import {photoUrl} from '../../config/config';
import {history} from '../../_helpers';
import StatsCard from '../../Components/StatsCard';
import CircuralProgress from '../../Components/CircularProgress';
import {setMessage} from '../../actions/message';
import { connect } from 'react-redux';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone';

const useStyles = makeStyles((theme) => ({
    jumbotronWrapper: {
        height: 'calc(300px + 10vw)',
        width:'100%',
        backgroundColor:'#F0F0F0',
        position:'absolute',
        top:64,
        left:0,
        overflow:'hidden',
                
    },
    jumbotronImage:{
        maxWidth:'100%',
        objectFit: 'cover',
        width: '100%',
        height:'100%',
    },
    mainGrid:{
        position:'absolute',
        top:'calc(270px + 10vw)',
        left:0,
        width:'100%',
        
    },
    avatarSize: {
        width: theme.spacing(20),
        height: theme.spacing(20),
      },
    flexRow:{
        display:'flex',
        flexDirection:'row',
        flexWrap: 'no-wrap',
        alignItems:'center'
    },
    flexColumn:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:5,
        width:'100%',
        position:'relative'
    },
    iconSize:{
        width:50,
        height:50
    },
    relative:{
        position:'relative',
        width: theme.spacing(20),
        marginBottom:20
    },
    gridPadding:{
        padding:'100px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    privateAccountWrapper:{
        height:'calc(100vh - 110px)',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
    },
    uploadPosition:{
        position:'absolute',
        top: 80,
        right: 15,
    },
    iconProfileStyle:{
        width:250, 
        height:250,
        color:'rgba(37,185,153,0.5)'
    }
    
    
}));
const ProfileDetail = ({match,setMessage}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [userDetail,setUserDetail] = useState('');
    const [userStats,setUserStats] = useState('');
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(true);
        getUserDetail(match.params.id)
            .then(detail=>{
                setIsLoading(false);
                setUserDetail(detail.data);
                (detail.data.publicLevel || detail.data.status === 1) && 
                    getUserDetailStats(match.params.id)
                        .then(stats=>setUserStats(stats.data))
                
            })
    },[match.params.id])

    const inviteUser =()=>{
        inviteUserId(match.params.id)
        .then((res)=>{
            setMessage(translate("resources.users.invite.success"))
        })
        .then(()=>history.push('/users/'))
        .catch(()=>setMessage(translate("resources.users.invite.failure"),"error"))
    }

    return (
        <>
            <Title title ={userDetail && userDetail.firstName ? `${translate('resources.profile.foreignName')} ${userDetail.firstName}` : translate('resources.profile.foreignName')}/>
            {isLoading ? <CircuralProgress/>
            :
            userDetail && (userDetail.publicLevel || userDetail.status === 1) ?  
            (<>
                (<div className={classes.jumbotronWrapper}>
                    {userDetail.coverPic && <img src = {userDetail.coverPic ? `${photoUrl}/${userDetail.coverPic}` : null} alt="jumbotron" className={classes.jumbotronImage}/>}
                </div>
                {userDetail && userDetail.status !==1 &&
                    <Button 
                        className = {classes.uploadPosition}
                        onClick={inviteUser}
                        variant="contained"
                        color="primary"
                        disabled={userDetail && userDetail.status === 0}
                    >
                        {translate('resources.users.user.buttonForFriend')}
                    </Button>
                }      
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    className={classes.mainGrid}
                >
                    <Grid item xs = {12} md = {4}>
                        <Box className = {classes.flexColumn}>
                            <Box className = {classes.relative} >
                                <Avatar className={classes.avatarSize} src={userDetail.profilePic && `${photoUrl}/${userDetail.profilePic}`}/>
                            </Box>
                            <Typography variant = "h4" component = "h2" paragraph>{userDetail.firstName && userDetail.lastName ? `${userDetail.firstName} ${userDetail.lastName}` : "Imie i nazwisko" }</Typography>
                            <Typography variant = "body1" component = "h4">{userDetail.sex && translate(`resources.profile.${userDetail.sex.toLowerCase()}`)}</Typography>
                            <Typography variant = "body1" component = "h4" paragraph>{userDetail.city}</Typography>
                            <Typography variant = 'h5' paragraph>{translate('resources.profile.about')}</Typography>
                            <Typography variant= "body2">{userDetail.description}</Typography>
                            
                        </Box>
                    </Grid>
                    <Grid 
                        container 
                        item xs ={12} md = {8}
                        display="row"
                        spacing={2}
                        className={classes.gridPadding}
                    >
                        <Grid item xs = {12} md = {6}  >
                            <StatsCard stats={userStats && userStats.generalStats[0]}/>
                        </Grid>
                        {userStats && userStats.statsByType && userStats.statsByType.map(stats=>(
                            <Grid item xs = {12} md = {6} key = {stats.typeName}>
                                <StatsCard stats={stats}/>
                            </Grid>
                        ))}
                    
                    </Grid>
                    
                    
                </Grid>
            </>
            ):(
                <div className={classes.privateAccountWrapper}>
                    {userDetail && userDetail.status=== 0 ? <HourglassFullTwoToneIcon className={classes.iconProfileStyle}/> : <PersonOutlineTwoToneIcon className={classes.iconProfileStyle}/>}
                    <Box m={1}/>
                    <Typography variant ="h4" paragraph align="center">{userDetail && userDetail.status=== 0 ? (`${userDetail.firstName} ${translate('resources.users.user.forFriendsInfo')}`) : (`${translate("resources.users.user.private")}`)}</Typography>
                    <Typography variant ="h6" paragraph align="center">{userDetail && userDetail.status=== 0 && translate("resources.users.user.wait")}</Typography>
                        {userDetail && userDetail.status !==0 && 
                            <Button 
                            onClick={inviteUser}
                            variant="contained"
                            color="primary"
                        >{translate('resources.users.user.buttonForFriend')}</Button>
                        }
                        <Box p={1}/>
                        
                        <Button 
                            variant="outlined"
                            color="primary"
                            onClick={()=>history.goBack(-1)}
                        >{translate('global.action.back')}
                        </Button>
                    
                    

                </div>
            )
            }
        </> 
    )
}

export default connect(null,{setMessage})(ProfileDetail);
