import React from 'react';
import { makeStyles} from '@material-ui/styles';
import { LocationOn } from "@material-ui/icons";
import { Grid,Typography, Paper } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SpeedIcon from '@material-ui/icons/Speed';
import { useTranslate } from 'react-admin';
import {ConvertMinutes} from '../_helpers';

const useStyles = makeStyles((theme) => ({
    flex: {
        display:'flex',
        alignItems:'center',
        margin:'10px 0'
    },
    infoPanel:{
        margin:"20px -20px",
        padding:"10px 20px 20px",
        [theme.breakpoints.down('xs')]: {
            margin: '20px 0px',
          },
        backgroundColor:"#fce4ec"
    },
    svgMargin:{
        marginRight:5
    }
}));

const InfoStatsPanel = ({stats}) => {
    const classes = useStyles();
    const translate = useTranslate();
    
    return (
        <Paper className={classes.infoPanel} elevation={0} square>
                <Grid container>
                {stats && stats.duration ?
                    (   
                        <Grid item xs = {6} md ={2} >
                            <Typography variant="body2" color="primary" className={classes.flex}><AccessTimeIcon className = {classes.svgMargin} />{translate('resources.travels.show.duration')}</Typography>
                            <Typography variant="h5">{ConvertMinutes(stats.duration)}</Typography>
                        </Grid>
                    ):(
                        <Grid item xs = {6} md ={2} >
                            <Typography variant="body2" color="primary" className={classes.flex}><AccessTimeIcon className = {classes.svgMargin} />{translate('resources.travels.show.duration')}</Typography>
                            <Typography variant="h5">0 min</Typography>
                        </Grid> 
                    )
                }
                    {stats && stats.distance ?
                    (
                        <Grid item xs = {6} md ={2}>
                            <Typography variant="body2" color="primary" className={classes.flex}><LocationOn className = {classes.svgMargin}/>{translate('resources.travels.show.distance')}</Typography>
                            <Typography variant="h5">{stats.distance < 1000 ? `${stats.distance.toFixed(0)}m` : `${(stats.distance/1000).toFixed(1)}km`}</Typography>
                        </Grid>
                    ):(
                        <Grid item xs = {6} md ={2}>
                            <Typography variant="body2" color="primary" className={classes.flex}><LocationOn className = {classes.svgMargin}/>{translate('resources.travels.show.distance')}</Typography>
                            <Typography variant="h5">0 km</Typography>
                        </Grid>
                    )
                }
                    {stats && stats.avg_speed ?
                        <Grid item xs = {6} md ={2}>
                            <Typography variant="body2" color="primary" className={classes.flex}><EqualizerIcon className = {classes.svgMargin}/>{translate('resources.travels.show.avg')}</Typography>
                            <Typography variant="h5">{stats.avg_speed} km/h</Typography>
                        </Grid>
                        :null
                    }
                    {stats && stats.max_speed ?
                        <Grid item xs = {6} md ={2}>
                            <Typography variant="body2" color="primary" className={classes.flex}><SpeedIcon className = {classes.svgMargin}/>{translate('resources.travels.show.max')}</Typography>
                            <Typography variant="h5">{stats.max_speed} km/h</Typography>
                        </Grid>
                        :null
                    }
                </Grid>
            </Paper>
    )
}

export default InfoStatsPanel;
