import React from 'react';
import {makeStyles} from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
    circleBorder: {
        border: "5px solid #25b999",
        borderRadius: "50px",
        margin: 10,
        width:70,
        height:70,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        fontWeight:700,
        fontSize:22
    },
    optionalTextStyle:{
      fontWeight:500,
      fontSize:12
    }
  
   
}));

const CircleBorder =({text,optionalText})=>{
    const classes = useStyles();
    return(
      <div className={classes.circleBorder}>{text ? text : ""}
        <div className={classes.optionalTextStyle}>{optionalText ? optionalText : null} </div>
      </div>
    )
  }
  export default CircleBorder;