import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import { AppBar, Toolbar, Typography, Button, Box, Container, Grid, Paper, Hidden, IconButton, TextField, Link, Drawer, List, ListItem } from '@material-ui/core';
import logo from '../assets/followme_logo_white_720.png';
import jumbotronImage from '../assets/FollowMe-landing.jpg';
import webImpulsLogo from '../assets/WEBimpuls-logo-2015-1200px.png'
import mapScreen from '../assets/MapScreen.png';
import mapScreen2 from '../assets/MapScreen2.png';
import googleIcon from '../assets/google-play-badge.png';
import MenuIcon from '@material-ui/icons/Menu';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ComputerIcon from '@material-ui/icons/Computer';
import ShareIcon from '@material-ui/icons/Share';
import {history} from '../_helpers';
import { Link as LinkScroll} from "react-scroll";


const useStyles = makeStyles((theme)=>({
    logoStyle:{
        height: 40, 
        width:130,  
    },
    toolbarStyle:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    jumbotronStyle:{
        height:'100vh',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-start',
        [theme.breakpoints.down('md')]: {
            alignItems:'center',
            textAlign:'center'
          },
    },
    jumbotronImageStyle:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100vh',
        objectFit:'cover',
        backgroundImage: `url('${jumbotronImage}')`,
        zIndex:-2,
    },
    imageMask:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100vh',
        background:'linear-gradient(180deg, rgba(0,0,0,0.3617822128851541) 0%, rgba(0,0,0,0.40940126050420167) 100%)',
        zIndex:-1,
    },
    titleStyle:{
        [theme.breakpoints.down('md')]: {
            fontWeight:700, 
            color:'white', 
            fontSize:'60px',
          },
        marginLeft:'-4px',
        fontWeight:900, 
        color:'white', 
        fontSize:'90px',
        
    },
    subtitleStyle:{
        color:"white",//'rgb(217, 217, 217)', 
        [theme.breakpoints.down('md')]: {
            fontSize:'1rem',
          },
    },
    servicesStyle:{
        marginTop:'-50px',
    },
    aboutStyle:{
        margin:"100px 0 150px",
        minHeight:500,
    },
    aboutTextStyle:{
        [theme.breakpoints.down('md')]: {
            marginTop:30
        },
    },
    phoneFrameStyle:{
       display:'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection:'column'
        },
    },
    mapScreenStyle:{
        "&:nth-child(2)":{
            transform:'translateY(100px)',
        },
        padding:5,
        [theme.breakpoints.down('md')]: {
            "&:nth-child(2)":{
                transform:'translateY(0px)',
            },
            width:'100%',
        },
    },
    footerStyle:{
        width:'100%',
        minHeight: 500,
        backgroundColor:'rgba(20, 20, 20, 1)',
        padding:'50px 50px 0px',
        [theme.breakpoints.down('md')]: {
            padding:'50px 15px 0px'
        },
    },
    linkStyle:{
        cursor:'pointer',
        '&:hover':{
            color:"#125c4c"
        }
    },
    drawerStyle:{
        width:'50%',
        backgroundColor:'rgba(20, 20, 20, 1)',
        color:'white',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
    },
    inputTextFieldStyle:{
        color: 'white',
    },
    googleButton:{
        width:140, 
        height:54, 
        cursor:'pointer' 
    }
    
    
       
}))

const LandingPage =()=> {
    const classes = useStyles();
    const [drawerIsOpen,setDrawerIsOpen] = useState(false);
    return (
        <>
         <AppBar position="fixed">
                <Container maxWidth="lg">
                    
                        <Toolbar className={classes.toolbarStyle} disableGutters>
                            <LinkScroll to="home"  smooth={true} className={classes.linkStyle}>
                                <img src = {logo} alt = "FollowMe" className = {classes.logoStyle} onClick={()=>history.push("/")}/>
                            </LinkScroll>
                            
                            <Hidden smDown>
                                <Box className={classes.toolbarStyle}>
                                    <Typography variant = 'subtitle1'>
                                        <LinkScroll to="home"  smooth={true} className={classes.linkStyle}>
                                            HOME
                                        </LinkScroll>
                                    </Typography>
                                    <Box mr={3}/>
                                    <Typography variant = 'subtitle1'>
                                        <LinkScroll to="services"  smooth={true} offset={-150} className={classes.linkStyle}>
                                            SERVICES
                                        </LinkScroll>
                                    </Typography>
                                    <Box mr={3}/>
                                    <Typography variant = 'subtitle1'>
                                        <LinkScroll to="about"  smooth={true} offset={-150} className={classes.linkStyle}>
                                            ABOUT
                                        </LinkScroll>
                                    </Typography>
                                    <Box mr={3}/>
                                    <Typography variant = 'subtitle1'>
                                        <LinkScroll to="contact"  smooth={true} className={classes.linkStyle}>
                                            CONTACT
                                        </LinkScroll>
                                    </Typography>
                                    <Box mr={10}/>
                                    <Button 
                                        variant="contained" 
                                        color="secondary"
                                        onClick={()=>history.push("/login")}
                                    >
                                        Login
                                    </Button>
                                    
                                </Box>
                            </Hidden>
                            <Hidden mdUp>
                                <IconButton onClick = {()=>setDrawerIsOpen(!drawerIsOpen)}>
                                    <MenuIcon color="secondary"/>
                                </IconButton>
                                <Drawer open={drawerIsOpen} onClose={()=>setDrawerIsOpen(!drawerIsOpen)} anchor="right" PaperProps = {{classes:{root:classes.drawerStyle}}}>
                                    <List>
                                        {['home', 'services', 'about', 'contact'].map((text, index) => (
                                        <ListItem button key={text} >
                                             <LinkScroll 
                                                to={text}  
                                                smooth={true} 
                                                className={classes.linkStyle} 
                                                onClick = {()=>setDrawerIsOpen(false)}
                                                offset={text === "services" || text ==="about" ? -100 : 0}
                                             >
                                                {text.toUpperCase()}
                                            </LinkScroll>
                                        </ListItem>
                                        ))}
                                    </List>
                                        
                                </Drawer>
                            </Hidden>
                        </Toolbar>
                </Container>
            </AppBar>
            <img src = {jumbotronImage} alt="jumbotron" className = {classes.jumbotronImageStyle}/>
            <div className ={classes.imageMask}/>
            
            <Container maxWidth="lg">
                <section className = {classes.jumbotronStyle} id="home">
                    <Typography variant="h1" className = {classes.titleStyle}>Collect travels with us</Typography>
                    <Typography variant="h6" className ={classes.subtitleStyle} paragraph>Explore, save and share your travels with friends</Typography>
                    <Box className={classes.toolbarStyle}>
                        <Button 
                            variant = "contained" 
                            color="primary" 
                            onClick={()=>history.push("/login")}
                        >
                            Get started
                        </Button>
                    
                        <img src = {googleIcon} alt = "GooglePlay" className={classes.googleButton} onClick={()=>history.push("/")}/>
                    </Box>
                    
                    
                </section>
                <section className ={classes.servicesStyle} id="services">
                    <Paper elevation={3} style ={{padding:'30px 50px'}}>
                        <Grid 
                            container 
                            item xs={12} 
                            justify="center"
                        >
                            <Typography variant = "h5">Services</Typography>
                        </Grid>
                        <Box mb={3}/>
                        <Grid 
                            container
                            direction="row"
                            justify="space-around"
                            
                            spacing={10}
                        >
                            <Grid 
                                container
                                item
                                direction="column"
                                alignItems="center"
                                xs={12}
                                md={4}
                            >
                                <LocationOnIcon fontSize="large" color="primary"/>
                                <Box mb={2}/>
                                <Typography variant = 'subtitle1'>
                                   Have you already had a mobile FollowMe app? 
                                   Start your journey and save your route in our app.
                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                direction="column"
                                alignItems="center"
                                xs={12}
                                md={4}
                            >
                                <ComputerIcon fontSize="large" color="primary"/>
                                <Box mb={2}/>
                                <Typography variant = 'subtitle1'>
                                    Have you already saved your trips? 
                                    Browse them, add photos, group and share with your friends.

                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                direction="column"
                                alignItems="center"
                                xs={12}
                                md={4}
                            >
                                <ShareIcon fontSize="large" color="primary"/>
                                <Box mb={2}/>
                                <Typography variant = 'subtitle1'>
                                    Share your travels, and travel groups in social media
                                </Typography>
                            </Grid>

                        </Grid>
                    </Paper>
                </section>
                <section className ={classes.aboutStyle} id="about">
                    <Grid container direction ="row" alignItems="center" justify='center'>
                        <Grid 
                            container 
                            direction="row"
                            alignItems="center" justify='flex-start'
                            item xs={10} md ={8}
                        >
                            <div className = {classes.phoneFrameStyle}>
                                <img src = {mapScreen} alt ="map" className={classes.mapScreenStyle}/>
                                <img src = {mapScreen2} alt ="map" className={classes.mapScreenStyle}/>    
                            </div>
                            
                        </Grid>
                        <Grid 
                            container 
                            direction ="column" 
                            item xs={12} md ={4}
                        >
                            
                            <Typography variant = "h3" paragraph className={classes.aboutTextStyle}>About</Typography>
                            <Typography variant = "subtitle1">
                                The FollowMe application is used to record trips, biking, walking and all activities as well as collecting them in a convenient and attractive way. All your trips can be grouped, combined, described and shared on community portals.
                                We wish you to visit the whole world with the FollowMe application! Have a nice trip!
                            </Typography>
                        </Grid>
                    </Grid>
            
                </section>
            </Container>
            <section className ={classes.footerStyle} id="contact">
            <Container maxWidth="lg">
            <Grid 
                container 
                direction ="row" 
                justify='center'
                spacing={4}
            >
                <Grid 
                    container
                    item
                    direction="column"
                    xs={12}
                    md={8}
                >   
                    <img src = {logo} alt = "FollowMe" className = {classes.logoStyle}/>
                    <Box mb={4}/>
                    <Typography variant = 'h5' color = "primary" paragraph>
                        Contact
                    </Typography>
                    <TextField 
                        variant = "outlined" 
                        placeholder="Email" 
                        label="Email"
                        InputProps={{
                            className: classes.inputTextFieldStyle,
                        }}
                        
                    />
                    <Box mb={2}/>
                    <TextField 
                        variant = "outlined" 
                        placeholder="Title"
                        label="Title"
                        InputProps={{
                            className: classes.inputTextFieldStyle,
                        }}
                    />
                    <Box mb={2}/>
                    <TextField 
                        variant = "outlined" 
                        placeholder="Message" 
                        multiline rows="3"
                        label="Message"
                        InputProps={{
                            className: classes.inputTextFieldStyle,
                        }}
                    />
                    <Box mb={2}/>
                    <Button variant='contained' color="primary">Send</Button>

                </Grid>
                <Grid 
                    container
                    item
                    direction="column"
                    xs={12}
                    md={4}
                >
                    <img src = {webImpulsLogo} alt = "WebImpuls.pl" className = {classes.logoStyle}/>
                    <Box mb={4}/>
                    <Typography variant = 'h5' color = "primary" paragraph>
                        Sitemap
                    </Typography>
                    <Typography variant = 'subtitle1'>
                        <Link href="/#/" color = "secondary">
                            Home
                        </Link>
                    </Typography>
                    <Box mb={1}/>
                    <Typography variant = 'subtitle1'>
                        <Link href="/#/login" color = "secondary">
                            Login
                        </Link>
                    </Typography>
                    <Box mb={1}/>
                    <Typography variant = 'subtitle1'  >
                        <Link href="/#/register" color = "secondary">
                            Register
                        </Link>
                    </Typography>
                    <Box mb={1}/>
                    <Typography variant = 'subtitle1'>
                        <Link href="/#/reset-password" color = "secondary">
                            Reset password
                        </Link>
                    </Typography>
                    
                    
                </Grid>
                
            </Grid>
            <Box mt={2}/>
            <Grid 
                container 
                direction ="row" 
                justify='center'
                alignItems='center'
                item xs={12}
            >
                
                <Typography variant = 'subtitle2' paragraph color = "secondary" style = {{color:'rgba(255,255,255,0.5)'}}> Copyright 2020 WEBimpuls Sp. z o.o.</Typography>
            </Grid>
            
            </Container>
            </section>
        
        </>
    )
}

export default LandingPage
