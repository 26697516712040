import {types} from '../actions/types';

const initialState={
    isRequesting:false,
    isDetailsRequesting:false,
    travels:'',
    requestSuccess:false,
    travelDetail:'',
    travelTypes:[],
    
}

const travels = (state=initialState, action) =>{
    switch(action.type){
        case types.GET_TRAVELS_REQUEST:
            return{
                ...state, isRequesting:true,
            }
            case types.GET_DETAILS_REQUEST:
            return{
                ...state, isDetailsRequesting:true,
            }
        case types.GET_TRAVELS_SUCCESS:
            return{
                ...state, isRequesting:false, requestSuccess:true,  travels: action.payload , travelDetail:''
            }
        case types.GET_TRAVELS_FAILURE:
            return{
                ...state,isRequesting:false, requestSuccess:false, 
            }
        case types.GET_TRAVEL_TYPES:
            return{
                ...state, travelTypes:action.payload,
            }
        case types.GET_TRAVEL_DETAILS_SUCCESS:
            return{
                ...state, isDetailsRequesting:false, requestSuccess:true, travelDetail:action.payload, 
            }
        case types.GET_TRAVEL_DETAILS_FAIULURE:
            return{
                ...state, isDetailsRequesting:false, requestSuccess:false,
            }
        case types.CHANGE_TRAVEL_DETAILS_SUCCESS:
            return {
                ...state, isRequesting:false, requestSuccess:true, travelDetail: Object.assign(state.travelDetail, action.payload)
            }
        case types.CHANGE_TRAVEL_DETAILS_FAILURE:
            return{
                ...state, isRequesting:false, requestSuccess:false,
            }
        case types.DELETE_TRAVEL_SUCCESS:

            const index = state.travels.findIndex(travel => travel.id === action.payload);
            const newArrayOfTravels = [...state.travels];
            newArrayOfTravels.splice(index,1)

            return{
                ...state, isRequesting:false, requestSuccess:true, travelDetail:'', travels:newArrayOfTravels
            }
        case types.DELETE_TRAVEL_FAILURE:
            return{
                ...state, isRequesting:false, requestSuccess:true,
            }
        case types.GET_TRAVEL_PHOTOS:
            return {
                ...state, travelDetail: Object.assign(state.travelDetail, {photos:action.payload})
            }
        case types.CHANGE_PHOTOS_META_DATA:
            const changedPhotoIndex = state.travelDetail.photos.findIndex(photo => photo.id === action.payload.id);
            const newPhoto = Object.assign(state.travelDetail.photos[changedPhotoIndex],{
                isCover: action.payload.isCover === 1 ? true : false, 
                isPublic: action.payload.isPublic === 1 ? true : false,
                longitude: action.payload.longitude,
                latitude: action.payload.latitude,
                description: action.payload.description,
                gpsLogId: action.payload.gpsLogId
             })
            let newArray = [...state.travelDetail.photos];
            newArray[changedPhotoIndex] = newPhoto

            return {
                ...state, travelDetail: {...state.travelDetail, photos: newArray}
            }
        case types.DELETE_TRAVEL_PHOTO:
            const photoIndex = state.travelDetail.photos.findIndex(photo => photo.id === action.payload);
            const newArrayOfTravelPhotos = [...state.travelDetail.photos];
            newArrayOfTravelPhotos.splice(photoIndex,1);

            return {
                ...state, travelDetail:Object.assign(state.travelDetail, {photos:newArrayOfTravelPhotos}),
            }
        case types.ADD_TRAVEL_PHOTOS:
            return{
                ...state, travelDetail: {...state.travelDetail, photos: [...state.travelDetail.photos, action.payload]}
            }
        default:
            return{
                ...state
            }
    }
}
export default travels;
