import React,{ useState, useEffect } from 'react'
import AuthContainer from '../Components/AuthContainer'
import { TextField, Typography, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { connect } from 'react-redux';
import {registerUser} from '../actions/register';
import {types} from '../actions/types';
import {history} from '../_helpers';
import {useTranslate} from 'react-admin';


const useStyles = makeStyles((theme) => ({
    marginInput:{
        margin:'10px 0'
     },
     marginButton:{
         margin:'20px 0px '
     },
     typography:{
        display:'flex',
        alignItems:'center',
        margin:'10px 0px 0px 0px ',
    },
     link:{
         textDecoration: 'inherit',
         color:theme.palette.primary.main
     },
     errorText:{
         color:"#ff0000",
         margin:'-20px 0 20px'
     }
    
}));

const Register =({registerStatus,registerUser,setRegisterError,match})=> {

    const [email,setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const classes = useStyles();
    const translate = useTranslate();


    const validateEmail = (email)=>{ 
    
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    useEffect(() => {
        checkIfInvited() ? setEmail(match.params.invite) : history.push('/register');
    }, [match.params.invite])

    const checkIfInvited = () =>{
        // Brak sprawdzania po stronie serwera czy podany adres email jest z polecenia
        if(match.params && match.params.invite && validateEmail(match.params.invite)){
             return true
        }else{
            return false;
        }
        
    }

    const registerSubmit = () => {
        if(confirmedPassword && email && password){
            confirmedPassword === password ? registerUser(email,password) : setRegisterError(translate('register.validate.first'))
        }
        else{
            setRegisterError(translate('register.validate.second'));
        }
        
    }
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            registerSubmit();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });

    return (
        <AuthContainer title={translate('register.register')}>
            {registerStatus.registerFailure && <Typography variant="body1" className={classes.errorText}>{registerStatus.error === "error" ? translate('register.validate.third') : registerStatus.error}</Typography>}
            <TextField 
                label={translate('register.email')} 
                variant="outlined" 
                fullWidth 
                type="email"
                autoComplete={email.toString()}
                id="email"
                autoFocus
                required
                disabled={match.params && match.params.invite ? true : false}
                error={!email.length && registerStatus.error ? true : false}
                className={classes.marginInput}
                value={email}
                onChange={event => setEmail( event.target.value )}
            />
            <TextField 
                label={translate('register.password')} 
                variant="outlined" 
                fullWidth 
                type="password"
                required
                error={!password.length && registerStatus.error ? true : false}
                className={classes.marginInput}
                value={password}
                onChange={event => setPassword( event.target.value )}
            />
            <TextField 
                label={translate('register.confirm')} 
                variant="outlined" 
                fullWidth 
                type="password"
                required
                error={!confirmedPassword.length && registerStatus.error ? true : false}
                className={classes.marginInput}
                value={confirmedPassword}
                onChange={event => setConfirmedPassword( event.target.value )}
            />
            <Button 
                color="primary" 
                variant="contained" 
                className={classes.marginButton} 
                onClick={registerSubmit} 
                disabled={registerStatus.registering}>
                {registerStatus.registering ? 
                    <CircularProgress color="secondary" size = {30}/> 
                    : 
                    translate('register.signUp')
                }
            </Button>
            <span className={classes.typography}>
            <Typography 
                variant="body1" 
                color="primary" 
            >
                {translate('register.already')}     
            </Typography>
            <Button variant = "text"  onClick={()=>history.push('/login')} className={classes.link}><strong>{translate('register.signIn')}</strong></Button>
            </span>
        </AuthContainer>
    )
}

const mapStateToProps= state =>({
    registerStatus: state.register
})
const mapDispatchToProps = dispatch => ({
    setRegisterError: (error)=>dispatch({type:types.REGISTER_FAILURE, payload:error}),
    registerUser: (email,password)=>dispatch(registerUser(email,password))
})


export default connect(mapStateToProps,mapDispatchToProps)(Register);
