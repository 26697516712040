import React from 'react';
import {Box, Typography, Button } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {history} from '../_helpers';
import {useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    errorWrapper:{
        widht:'100%',
        height:'100vh',
        display:'flex',
        flexDirection: "column",
        justifyContent:'center',
        alignItems:'center',
        background: 'radial-gradient(circle,#76D3C0,#25BA9A)',
    },
    button:{
        marginTop:30,
    }
}));

const Error = ({location}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Box className={classes.errorWrapper}>
            <Typography variant="h1" color="secondary">{location.state ? location.state.error : "404"} </Typography>
            <Typography variant="h3" color="secondary" paragraph>{translate("global.error")}</Typography>
            <Button variant="outlined" color="secondary" onClick={()=>history.push('/dashboard')}>{translate('global.action.back')}</Button>
        </Box>
    )
}

export default Error
