import React,{useEffect} from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslate} from 'react-admin';
import {disableMessage} from '../actions/message';

const useStyles = makeStyles((theme) => ({
    snackbarContent:{
       backgroundColor: (stat)=> stat.status === "error" ? "#ff0000" : theme.palette.primary.main  
    },
    
}));



const Message = ({message,disableMessage}) => {
    const translate = useTranslate();
    const classes = useStyles({status:message.status});

    //CRUD - informacje zwrotne 
    const switchMessage = (message) =>{
        switch(message){
            case "delete":
                return translate("global.action.deleteResponse");
            case "create":
                return translate("global.action.createResponse");
            case "update":
                return translate("global.action.updateResponse");
            case "error":
                return translate("global.action.errorResponse");
            default:
                return message
        }
    }

    useEffect(()=>{
        const timer = setTimeout(()=>{
            disableMessage();
        },3000)
        return ()=> clearTimeout(timer)
    },[message])

    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            open={message.isOpen}
        >
        <SnackbarContent className={classes.snackbarContent}
            message={switchMessage(message.message)}
            />
        </Snackbar>
    );
}
const mapStateToProps = (state)=>({
    message:state.message
})
export default connect(mapStateToProps,{disableMessage})(Message);
