import React, { useEffect, useState } from 'react';
import {Title, useTranslate} from 'react-admin';
import MaterialTable from 'material-table';
import {history} from '../../_helpers';
import {getUserGroups,createUserGroup,deleteSingleGroup} from '../../actions/groups';
import {connect} from 'react-redux';
import CircleBorder from '../../Components/CircleBorder';
import {makeStyles} from '@material-ui/styles';
import {Paper} from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
  tableContainer:{
    width:'calc(100vw - 277px)',
    overflowX: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 'calc(100vw - 37px)'
    },
    [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 20px)'
    },
}
}))

const TravelGroups = ({userGroups,getUserGroups,createUserGroup,deleteSingleGroup}) => {
    const translate = useTranslate();
    const classes = useStyles();

    useEffect(()=>{
      getUserGroups();
    },[])
  
    const [state] = useState({
        columns: [
        { title: translate('resources.travelgroups.fields.count'), field: 'count', editable: 'never', render:rowData=> <CircleBorder text = {rowData.count}/>},
        { title: translate('resources.travelgroups.fields.name'), field: 'name' , validate: rowData => rowData.group === '' ? { isValid: false, helperText: 'Pole wymagane' } : true},
        { title: translate('resources.travelgroups.fields.description'), field: 'description'},
        { title: translate('resources.travelgroups.fields.dateCreated'), field: 'dateCreated' ,editable: 'never' , type: 'date'},
        ],
    });

  return (
    <>
    <Title title ={translate('resources.travelgroups.name')}/>
    <Paper className={classes.tableContainer}>
      <MaterialTable
        title={translate('resources.travelgroups.name')} 
        columns={state.columns}
        data={userGroups.userGroups}
        options={{
              actionsColumnIndex: -1,
              headerStyle: {
                  fontWeight: 600,
                },  
                paging:false,
                
          }}
        
        editable={{
          onRowAdd: (newData) =>
          new Promise((resolve) => {
            createUserGroup(newData.name,newData.description);
            resolve();
          }),
          onRowDelete: (data) =>
          new Promise((resolve,reject)=>{
            deleteSingleGroup(data.id)
            resolve()
          })
        }}
        localization={{
          pagination: {
              labelRowsSelect: translate('tableTooltip.pagination.labelRowsSelect'),
              firstTooltip: translate('tableTooltip.pagination.firstTooltip'),
              previousTooltip:translate('tableTooltip.pagination.previousTooltip'),
              nextTooltip:translate('tableTooltip.pagination.nextTooltip'),
              lastTooltip:translate('tableTooltip.pagination.lastTooltip'),

          },
          toolbar: {
              searchTooltip: translate('tableTooltip.toolbar.searchTooltip'),
              searchPlaceholder: translate('tableTooltip.toolbar.searchPlaceholder')
          },
          header: {
              actions: translate('tableTooltip.header.actions')
          },
          
          body: {
              emptyDataSourceMessage: translate('tableTooltip.body.emptyDataSourceMessage'),
              addTooltip:  translate('tableTooltip.body.addTooltip'),
              deleteTooltip:  translate('tableTooltip.body.deleteTooltip'),
              editRow:{
                  deleteText: translate('tableTooltip.body.editRow.deleteText'),
                  cancelTooltip: translate('tableTooltip.body.editRow.cancelTooltip'),
                  saveTooltip: translate('tableTooltip.body.editRow.saveTooltip'),
              }
          }
      }}
        isLoading= {userGroups.isRequesting}
        onRowClick= {(event, rowData)=> history.push(`/travelgroups/${rowData.id}/show`)}
        
      

      />
      </Paper>
    </>   
  );  
       
}
const mapStateToProps = state =>({
  userGroups: state.groups,
})


export default connect(mapStateToProps,{getUserGroups,createUserGroup,deleteSingleGroup})(TravelGroups);

