export default {
  language: "Język",
  global: {
    action: {
      add: "Dodaj",
      back: "Cofnij",
      cancel: "Anuluj",
      confirm: "Potwierdź",
      create: "Utwórz",
      delete: "Usuń",
      edit: "Edytuj",
      list: "Lista",
      remove: "Usuń",
      save: "Zapisz",
      search: "Szukaj",
      show: "Pokaż",
      sort: "Sortuj",
      send: "Wyślij",
      undo: "Cofnij",
      expand: "Rozszerz",
      close: "Zamknij",
      invite: "Zaproś",
      share: "Udostępnij",
      recover: "Przywróć",
      logout: "Wyloguj",
      center: "Wyśrodkuj",
      deleteResponse: "Usunięto",
      updateResponse: "Zaktualizowano",
      createResponse: "Utworzono",
      
    },
    select:{
      all: "Wszystkie",
      general: "Ogólne statystyki",
      cycling: "Kolarstwo",
      walking: "Chodzenie",
      running: "Bieganie",
      car: "Samochód",
      motorbike: "Motor",
      ["by wheelchair"]: "Wózek inwalidzki",
      boating: "Łódka",
      kayaking: "Kajak",
      airplane: "Samolot",
      other: "Inne",
      none: "Żaden",
      male: "Mężczyzna",
      female: "Kobieta",
      global: "Globalny",
      ['for followers']: "Dla znajomych",
    },
    type: {
      all: "Wszystkie",
      general: "Ogólne statystyki",
      cycling: "Kolarstwo",
      walking: "Chodzenie",
      running: "Bieganie",
      car: "Samochód",
      motorbike: "Motor",
      bywheelchair: "Wózek inwalidzki",
      boating: "Łódka",
      kayaking: "Kajak",
      airplane: "Samolot",
      other: "Inne",
      none: "Żaden"
    },
    filters: {
      filters: "Filtry",
      filter: "Filtruj",
      clear: "Wyczyść",
      none: "Żadna"
    },
    trips: "Podróże",
    photos: "Galeria",
    messages:{
      language:"Zmieniono język na angielski",
    },
    error:"Ups... Coś poszło nie tak",
    errorInfo: "Funkcja tymczasowo niedostępna",
  },
  slider: {
    firstSlogan: "Zbieraj wycieczki na twoim urządzeniu mobilnym by później...",
    secondSlogan: "zarządzać nimi na stronie oraz...",
    thirdSlogan: "dzielić się z innymi na facebooku czy twitterze"
  },
  login: {
    login: "Zaloguj się",
    email: "Adres e-mail",
    password: "Hasło",
    signIn: "Zaloguj się",
    forgotPass: "Zapomniałeś hasła?",
    reset: "Resetuj",
    create: "Nie masz konta?",
    signUp: "Zarejestruj się",
    error: "Niepoprawny login lub hasło",
    validate: "Wypełnij wszystkie pola",
    
  },
  recovery: {
    
    passRecovery: "Odnawianie hasła",
    error:"'Ups... coś poszło nie tak'",
    validate:{
      first: "Podane hasła się różnią",
      second:"Wypełnij wszystkie pola"
    },
    newPassword: "Nowe hasło",
    confirmedPassword: "Potwierdź hasło",
    reset: "Resetuj",
    back: "Wróc do strony",
    loginPage: "logowania",
  },
  register: {
    register: "Zarejestruj się",
    email: "Adres e-mail",
    password: "Hasło",
    confirm: "Potwierdź hasło",
    signUp: "Zarejestruj się",
    already: "Masz już konto?",
    signIn: "Zaloguj się",
    validate:{
      first: "Podane hasła się różnia",
      second: "Wypełnij wszystkie pola",
      third: "Takie dane są już zajęte",
    }
    
  },
  reset: {
    sendResetEmail:'Na twoją skrzynkę pocztową wysłaliśmy email z dalszymi instrukcjami',
    header: "Zresetuj hasło",
    email: "Adres e-mail",
    back: "Wróć do strony logowania",
    error: "Konto z takim adresem email nie istnieje",
    validate: "Wypełnij wszystkie pola",
    reset: "Resetuj",
  },
  cancel: {
    confirm: "Potwierdź anulowanie konta",
    back: "Wróć do strony ",
    login: "Logowania",
    page: " "
  },
  resources: {
    dashboard: {
      name: "Ekran startowy",
      welcome: "Witaj z powrotem",
      review: "Przeglądnij swoje ostatnie pięć wycieczek.",
      reviewSubtitle: "Klikając na wycieczkę wyświetlisz ją na mapie.",
      showMore: "ZOBACZ WSZYSTKIE",
      stats: "Twoje statystyki z ostatniego miesiąca",
      empty: "Wygląda na to że nie byłeś aktywny w ostatnim miesiącu. Więc nie możemy zaprezentować Ci żadnych statystyk."
    },
    travels: {
      name: "Wycieczki",
      tableTitle:"Twoje wycieczki",
      travelsTableHeaders: {
        distance: "Dystans",
        title: "Tytuł",
        group: "Grupa wycieczki",
        type: "Typ wycieczki",
        date: "Data wycieczki",
        action: "Detale",
        duration:"Czas trwania",
      },
      filters: {
        distance: "Zakres dystansu [km]",
        date: "Zakres daty",
        duration: "Zakres czasu [h]",
        type: "Typ wycieczki",
        group: "Grupa wycieczki",
        from: "Od",
        to: "Do",
      },
      fields: {
        distance: "Dystans",
        title: "Tytuł",
        travelGroupName: "Grupa wycieczki",
        travelType: "Typ wycieczki",
        travelDate: "Data wycieczki",
      },
      setings: "Ustawienia",
      more: "Pokaż więcej",
      deleteTravel: "Usuń tę wycieczkę",
      sure: "Czy na pewno chcesz usunąć tę wycieczkę?",
      startTracking: "Śledź wycieczkę",
     
      show: {
        name: "Dane wycieczki",
        logs: "Logi",
        start: "Początek: ",
        end: "Koniec: ",
        type: "Typ",
        group: "Grupa",
        description: "Opis",
        duration: "Czas trwania",
        distance: "Dystans",
        avg: "Śrd prędkość",
        max: "Max prędkość",
        merge: "Połącz",
        split: "Podziel"
      },
      photo:{
        photoSure: "Czy na pewno chcesz usunąć to zdjęcie?",
        cover:"Udostępnij",
        uncover:"Nie udostępniaj",
        photoPrivate: "Prywatny",
        photoPublic: "Publiczny",
        description:'Opis',
        info: "Wskaż miejsce na trasie do umieszczenia zdjęcia",
        show: "Pokaż/ukryj zdjęcia",
        crop: "Przytnij obraz"
      },
      logs: {
        date: "Pokaż logi",
        longitude: "Dł. geograficzna",
        latitude: "Sz. geograficzna"
      },
      merge: {
        header: "Wybierz wycieczki które chcesz włączyć w tę wycieczkę",
        accept: "Połącz teraz",
        cancel: "Anuluj Łączenie",
        title: "Tytuł",
        travelGroup: "Grupa wycieczki",
        travelType: "Typ wycieczki",
      },
      split: {
        info: "Wskaż gdzie chcesz rozdzielić wycieczkę",
        accept: "Podziel teraz",
        cancel: "Anuluj dzielenie",
        information: "Uważaj, masz zamiar rozdzielić wycieczkę w tym miejscu!"
      },
      crop:{
        crop: "Przytnij",

      },
      playTravel: {
        play:"Oglądaj",
        stop: "Zatrzymaj",
        reset: "Resetuj",
        delay: "Opóźnienie",
        color: "Kolor",
        weight: "Grubość",
      }
    },
    travelgroups: {
      name: "Grupy wycieczek",
      fields: {
        count: "Ilość wycieczek",
        name: "Nazwa",
        description: "Opis",
        dateCreated: "Data utworzenia"
      },
      new: {
        new: "Nowa grupa",
        header: "Wpisz nazwę grupy aby ją utworzyć",
        groupName: "Nazwa grupy"
      },
      addTravels: "Wycieczki",
      deleteGroup: "Usuń tę grupę",
      sure: "Czy na pewno chcesz usunąć tę grupę?",
      show: {
        name: "Detale grupy",
        created: "Utworzona:",
        time: "Zakres Daty:",
        description: "Opis",
        duration: "Czas",
        distance: "Dystans",
        travelList: {
          distance: "Dystans",
          title: "Tytuł",
          type: "Typ",
          date: "Data"
        }
      }
    },
    users: {
      name: "Użytkownicy",
      filters: {
        search: "Szukaj użytkownika",
        country: "Kraj",
        city: "Miasto"
      },
      fields: {
        profilePic: "Zdjęcie profilowe",
        firstName: "Imię",
        lastName: "Nazwisko",
        country: "Kraj",
        city: "Miasto"
      },
      user:{
        forFriendsInfo:'dostał zaproszenie do znajomych',
        wait: "Czekaj na odpowiedź ...",
        buttonForFriend:"Zaproś go, by móc przeglądać jego profil",
        private: "Ten profil jest prywatny",
      },
      usersCategory:{
        allPublicUsers: "Publiczni użytkownicy",
        followUsers: "Obserwowani",
        invitations: "Zaproszenia",
        inviteEmail: "Zaproś przez email",
      },
      invite:{
        success: "Wysłano zaproszenie. Czekaj na odpowiedź.",
        failure: "Ten użytkownik otrzymał już od Ciebie zaproszenie, czekaj na odpowiedź.",
        emailFailure: "Ten użytkownik nie istnieje, lub otrzymał juz od Ciebie zaproszenie.",
        inviteEmailText: "Jeśli użytkownika którego szukasz nie ma na liście, to znaczy, że jego profil jest prywatny. Jeśli znasz jego email, wyślij mu zaproszenie.",
      },
      inviteRequest:{
        confirm: "Potwierdz zaproszenie",
        discard: "Odrzuć zaproszenie"
      },
      inviteResponse:{
        confirm: "Dodano do znajomych",
        discard: "Usunięto ze znajomych",
      }
    },
    profile: {
      name: "Mój profil",
      edit: "Edytuj profil",
      foreignName: "Profil użytkownika",
      profilePrivate: "Prywatny",
      profilePublic: "Publiczny",
      publicLevelGlobal: "Globalny",
      publicLevelForFollowers: "Tylko znajomi",
      public: "Czy chcesz aby twój profil był publiczny?",
      fields: {
        firstName: "Imię",
        lastName: "Nazwisko",
        description: "O mnie",
        country: "Kraj",
        city: "Miasto",
        sex: "Płeć",
        birthday: "Data urodzenia",
        changePassword: "Zmień hasło",
        currentPassword: "Teraźniejsze hasło",
        newPassword: "Nowe hasło",
        email: "E-mail",
       
      },

      accCancel: "Anulowanie konta",
      emailConfirm: "Na Twój email wysłaliśmy link potwierdzający usunięcie konta",
      sure: "Czy na pewno chcesz anulować konto?",
      photoSure: "Czy na pewno chcesz usunąć to zdjęcie?",
      expire: "Twoje konto wygaśnie:",
      accountCancelation: "ANULUJ KONTO",
      cancelDeletion: "Przerwij usuwanie",
      canceledDeletion: "Przerwano usuwanie konta",

      other: "Żadna",
      male: "Mężczyzna",
      female: "Kobieta",
      about: "O mnie",
      day: "dzień",
      days: "dni",
      stats: "Statystyki",
      statTypes: {
        amount: "Ilość wycieczek",
        duration: "Czas trwania",
        distance: "Dystans",
        days: "Dni"
      }
    },
    about: {
      name: "O nas",
      first:
        "Aplikacja FollowMe jest używana żeby zapisywać wszelkiego rodzaju wycieczki, oraz zbierać je w bardzo poręczny i atrakcyjny sposób. Każda wycieczka może zostać zgrupowana, połączona, opisana i dzielona na portalach społecznościowych.",
      second:
        "Życzymy Ci byś zwiedził cały świat z naszą aplikacją! Miłej podróży!",
      copyright: "Copyright WEBimpuls Sp. z o. o. 2017-2019 r.",

      third:
        "Ta aplikacja została stworzona przez nasz zespół informatyczny. Firma WEBimpuls operuje na rynku od roku 2010. W naszej firmie łączymy dwa rodzaje umiejętność: programowania oraz marketingu po to by rozwijać firmy w internecie. Projektujemy i tworzymy strony, sklepy, portale oraz zaawansowane aplikacje webowe. Nasz zespół marketingowy opiekuje się obrazem internetowym naszych klientów. Wysokiej jakości usługi zapewniane przez nas są wynikiem lat doświadczenia naszych ludzi. W każdym momencie możesz skorzystać z naszej wiedzy i doświadczenia - jesteśmy zadowoleni z tego że możemy się nimi podzielić.",
      fourth: "Zapraszamy Cię do współpracy",

      contact: "Skontaktuj się z nami",
      company: "Firma",
      konrad: "Projekt menadżer",
      android: "Programiści Android",
      web: "Programiści Web",
      back: "Programista części serwerowej"
    },
    invite: {
      name: "Zaproś znajomych",
      header: "Podziel się tą aplikacją ze znajomymi i rodziną",
      email: "Poprzez E-mail",
      write: "Wpisz tu adres email swojego znajomego",
      address: "Adres E-mail",
      social: "Sieci społecznościowe",
      on: "Na portalach społecznościowych"
    },
    live:{
      name: "Śledź wycieczki",
      followers: "Obserwujący",
      publicLive: "Udostępnij publicznie",
      privateLive: "Udostępnij prywatnie",
      invitedUsers: "Zaproszeni użytkownicy",

    }
  },
  tableTooltip:{
    pagination: {
      labelRowsSelect:"wierszy",
      firstTooltip:"Pierwsza strona",
      previousTooltip:"Poprzednia strona",
      nextTooltip:"Następna strona",
      lastTooltip:"Ostatnia strona"

    },
    toolbar: {
        searchTooltip: "Szukaj",
        searchPlaceholder: "Szukaj"
    },
    header: {
        actions: 'Akcje'
    },
    
    body: {
        emptyDataSourceMessage: 'Brak rekordów do wyświetlenia',
        addTooltip: "Dodaj",
        deleteTooltip: "Usuń",
        editRow:{
            deleteText:"Na pewno chcesz usunąć?",
            cancelTooltip:"Anuluj",
            saveTooltip:"Zapisz",
        }
    }
  }
};
