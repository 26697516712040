import {types} from '../actions/types';

const initialState={
    registering:false,
    user:'',
    registerSuccess:false,
    registerFailure:false,
    error:''
}



const registration =(state = initialState, action) => {
    switch(action.type){
        case types.REGISTER_REQUEST : 
            return{
                ...state, registering:true
            };
        case types.REGISTER_REGISTER_SUCCESS :
            return{
                ...state, user:action.payload, 
            };
        case types.REGISTER_FAILURE :
            return{
                ...state, registerFailure:true, registering:false, error:action.payload, 
            };
        default: 
            return {...state}
        
    };
};
export default registration;