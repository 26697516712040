import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#25b999",
      contrastText: "#fff"
    },
    secondary: {
      main: '#fff',
    },
    text:{
      secondary:'rgb(191, 191, 191)'
    }
    
  },
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
  
});
export default theme;