import {types} from '../actions/types';

const initialState={
    isOpen:false,
    message:'',
    status:'',
}
const message = (state=initialState, action) =>{
    switch(action.type){
        case types.SET_MESSAGE:
            return{
                ...state, isOpen:true, message:action.payload.message ,status: action.payload.status
            }
        case types.DISABLE_MESSAGE:
            return{
                ...state, isOpen:false, status:'', message:'',
            }
            
        default:
            return state
    }
}
export default message