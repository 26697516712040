import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Button, Avatar , Grid, Typography,  Box, Dialog, DialogTitle, DialogContent,TextField, DialogActions, FormControlLabel, Paper, Select, MenuItem} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {Title, useTranslate} from 'react-admin';
import { connect } from 'react-redux';
import {getOwnerStats,changeUserPassword,changeUserData,changeUserPhotos,deleteUserPhotos,deletionActions} from '../../actions/profile';
import CircleProgress from '../../Components/CircularProgress';
import { photoUrl } from '../../config/config';
import StatsCard from '../../Components/StatsCard';
import SaveIcon from '@material-ui/icons/Save';
import InlineEditTextField from '../../Components/InlineEditTextField';
import InlineEditSelect from '../../Components/InlineEditSelect';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmAlert from '../../Components/ConfirmAlert';
import { deleteAccount, cancelDeletingAccount } from '../../data.sercive';
import {setMessage} from '../../actions/message';
import LocaleSwitcher from '../../Components/LocaleSwitcher';




const useStyles = makeStyles((theme) => ({
    jumbotronWrapper: {
        height: 'calc(300px + 10vw)',
        width:'100%',
        background:'#F0F0F0',
        position:'absolute',
        top:64,
        left:0,
                
    },
    jumbotronImage:{
        maxWidth:'100%',
        objectFit: 'cover',
        width: '100%',
        height:'100%',
    },
    circleButton:{
        borderRadius:50,
        padding:15,
        
    },
    uploadPosition:{
        position:'absolute',
        top: 80,
        right: 15,
    },
    savePosition:{
        position:'absolute',
        top: 80,
        right: 90,
    },
    inputFile:{
        display:'none',
    },
    mainGrid:{
        position:'absolute',
        top:'calc(280px + 10vw)',
        left:0,
        width:'100%',
        
    },
    avatarSize: {
        width: theme.spacing(20),
        height: theme.spacing(20),
      },
    flexRow:{
        display:'flex',
        flexDirection:'row',
        flexWrap: 'no-wrap',
        alignItems:'center'
    },
    flexColumn:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:5,
        width:'100%',
        position:'relative'
    },
    iconSize:{
        width:50,
        height:50
    },
    uploadAvatarPosition:{
        position:'absolute',
        top: '50%',
        right: '-70px',
        transform: (profilePic)=> profilePic.profilePic ? 'translateY(-110%)': 'translateY(-50%)',
    },
    saveAvatarPosition:{
        position:'absolute',
        top: '50%',
        left: '-70px',
        transform:'translateY(-50%)',
    },
    relative:{
        position:'relative',
        width: theme.spacing(20),
        marginBottom:20
    },
    gridPadding:{
        padding:'100px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    profileContainer:{
        padding:'0px 30px'
    },
    papperStyle:{
        padding:'5px 20px',
        position:'relative',
    },
    paperPublicWrapper:{
       display:'flex',
       alignItems:'center',
       justifyContent:'center'
    },
    icon:{
        display: 'none'
    },
    select: {
        userSelect:'text',
        cursor:'pointer',
        padding:'5px 24px' ,
        "&:active, &:focus":{
            padding: '5px 24px',
            backgroundColor: 'rgb(230, 230, 230)',
            borderRadius:10,
        },     
    },
    buttonRoot:{
        minWidth: 20,
    },
    deleteCoverPosition:{
        position:'absolute',
        top: 80,
        right: 80,
    },
    deleteAvatarPosition:{
        position:'absolute',
        top: '50%',
        right: '-70px',
        transform: 'translateY(5%)',
        
    }
    
}));

const Profile = ({changeUserPhotos,user,getOwnerStats,changeUserPassword,changeUserData,deleteUserPhotos,setMessage,deletionActions}) => {
    const classes = useStyles({profilePic: user.userData.profilePic});
    const translate = useTranslate();
    const [changePasswordDialog, setToggleChangePasswordDialog] = useState({
        isOpen:false,
        oldPassword:'',
        newPassword:'',
    });
    const [profilePic, setProfilePic] = useState('');
    const [coverPic, setCoverPic] = useState('');
    const [profileIsPublic, setProfileStatus] = useState(false);
    const [publicLevel,setPublicLevel] = useState(false);
    const [editable,setEditable] = useState({
        editable:false,
        currentState:'',
    })
    const [deletePhotoConfirmIsOpen,setDeletePhotoConfirmIsOpen] = useState({
        isOpen:false,
        photoType:'',

    })
    const [deleteAccountConfirmIsOpen, setDeleteAccountConfirmIsOpen] = useState(false);

    useEffect(()=>{
        getOwnerStats();   
        user.userData && setProfileStatus(user.userData.public); 
        user.userData && setPublicLevel(user.userData.publicLevel);
        user.userData && setEditable({...editable, currentState:{publicLevel:user.userData.publicLevel, public:user.userData.public}}) 
    },[user.userData])

    const saveProfilePic=()=>{
        let formData = new FormData();
        formData.append('profilePhoto', profilePic);
        changeUserPhotos(formData);
        setTimeout(()=>{
            URL.revokeObjectURL(profilePic)
            setProfilePic('')
        },500)
        
    }
    const saveCoverPic=()=>{
        let formData = new FormData();
        formData.append('coverPhoto', coverPic);
        changeUserPhotos(formData);
        setTimeout(()=>{
            URL.revokeObjectURL(coverPic)
            setCoverPic('')
        },500)
    }
    const toggleDialogChangePassword = () =>{
        setToggleChangePasswordDialog({...changePasswordDialog, isOpen:!changePasswordDialog.isOpen})
    }
    const handleSaveChanges =(dataType,value)=>{
        const firstName = dataType === "firstName" ? value : user.userData.firstName;
        const lastName = dataType === "lastName" ? value : user.userData.lastName;
        const description = dataType === "description" ? value : user.userData.description;
        const sex = dataType === "sex" ? value : user.userData.sex;
        const dateOfBirth = dataType === "dateOfBirth" ? value : user.userData.dateOfBirth;
        const publicProfile = profileIsPublic;
        const publicLev = publicLevel;
        const country = dataType === "country" ? value : user.userData.country;
        const city = dataType === "city" ? value : user.userData.city;
        changeUserData( firstName, lastName,description,sex,dateOfBirth,country, city,publicProfile,publicLev)
    }
    const handleCloseProfileStatus=()=>{
        setEditable({...editable, editable:false});
        setPublicLevel(editable.currentState.publicLevel);
        setProfileStatus(editable.currentState.public);
    }


    return (
        <>
            <Title title ={translate('resources.profile.name')}/>
            {user.isRequesting ? 
                <CircleProgress/>
            : 
            <>
            <div className={classes.jumbotronWrapper}>
                {coverPic ? 
                    (<img src = {coverPic && URL.createObjectURL(coverPic)} alt = "img" className = {classes.jumbotronImage}/>
                    ):(user.userData && user.userData.coverPic && <img src = {user.userData && user.userData.coverPic ? `${photoUrl}/${user.userData.coverPic}` : null} className = {classes.jumbotronImage} alt = "img"/>)
                }
            </div>
            
                
                    <input accept="image/*"  id="icon-button-file-back" type="file" className={classes.inputFile} onChange={e=>setCoverPic(e.target.files[0])}/>
                    <label htmlFor="icon-button-file-back">
                    <Button 
                        variant="contained" 
                        color="primary"  
                        classes={{root:classes.buttonRoot}}
                        className={`${classes.circleButton} ${classes.uploadPosition}`}
                        component="span"
                        
                    >
                        <SaveAltIcon/>
                    </Button>
                    </label>
                    { user.userData && user.userData.coverPic && 
                        <Button 
                            variant="contained"  
                            classes={{root:classes.buttonRoot}}
                            className={`${classes.circleButton} ${classes.deleteCoverPosition}`}
                            component="span"
                            onClick={()=>setDeletePhotoConfirmIsOpen({...deletePhotoConfirmIsOpen, isOpen:true, photoType:'cover'})}
                        >
                            <DeleteIcon />
                        </Button>
                    }
             
            
            {coverPic && 
                <Button 
                    variant="contained" 
                    color="primary"  
                    classes={{root:classes.buttonRoot}}
                    className={`${classes.circleButton} ${classes.savePosition}`}
                    component="span"
                    onClick={()=>saveCoverPic()}
                >
                    <SaveIcon/>
                </Button>
            
            }
            
            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.mainGrid}
            >
                <Grid container item xs = {12} md = {4} className={classes.profileContainer}>
                    <Box className = {classes.flexColumn}>
                        <Box className = {classes.relative}>
                            {profilePic &&
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    classes={{root:classes.buttonRoot}} 
                                    className={`${classes.circleButton} ${classes.saveAvatarPosition}`}
                                    component="span"
                                    onClick={()=>saveProfilePic()}
                                    
                                >
                                    <SaveIcon/>
                                </Button>
                            }
                            <Avatar className={classes.avatarSize} src = {profilePic ? URL.createObjectURL(profilePic) : user.userData && user.userData.profilePic && `${photoUrl}/${user.userData.profilePic}`}/>
                            
                                <>
                                <input accept="image/*" id="icon-button-file-avatar" type="file" className={classes.inputFile} onChange={e=>setProfilePic(e.target.files[0])} />
                                <label htmlFor="icon-button-file-avatar">
                                <Button 
                                    variant="contained" 
                                    color="primary"  
                                    classes={{root:classes.buttonRoot}}
                                    className={`${classes.circleButton} ${classes.uploadAvatarPosition}`}
                                    component="span"
                                    
                                    
                                >
                                    <SaveAltIcon/>
                                </Button>
                                </label>
                                {user.userData && user.userData.profilePic && 
                                    <Button 
                                        variant="contained" 
                                         
                                        classes={{root:classes.buttonRoot}}
                                        className={`${classes.circleButton} ${classes.deleteAvatarPosition}`}
                                        component="span"
                                        onClick={()=>setDeletePhotoConfirmIsOpen({...deletePhotoConfirmIsOpen, isOpen:true, photoType:'profile'})}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                }
                                </>
                            
                        </Box>
                            <InlineEditTextField size="2rem" placeholder ={translate('resources.profile.fields.firstName')} defaultValue={user.userData.firstName} save={handleSaveChanges} dataType="firstName" align="center"/>
                            <InlineEditTextField size="2rem" placeholder ={translate('resources.profile.fields.lastName')} defaultValue={user.userData.lastName} save={handleSaveChanges} dataType="lastName" align="center"/>
                        <Box m={2}/>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<EditIcon />} 
                            onClick={()=> {
                                toggleDialogChangePassword()
                            }}
                        > 
                            {translate('resources.profile.fields.changePassword')}
                        </Button>
                        <Box m={1}/>
                        {user.userData && user.userData.expirationDate ? (
                            <>
                            <Typography variant ="body1" paragraph style={{color:'#ff0000'}}>
                                {`${translate('resources.profile.expire')} ${user.userData.expirationDate}`}
                            </Typography>
                            <Button 
                                variant="outlined"
                                color="primary"
                                onClick={()=>
                                    cancelDeletingAccount()
                                        .then(()=>{
                                            setMessage(translate('resources.profile.canceledDeletion'));
                                            deletionActions();
                                        })
                                        
                                }
                            >
                                {translate('resources.profile.cancelDeletion')}
                            </Button>
                        </>


                            ):(
                                <Button 
                                    variant="contained" 
                                    startIcon={<DeleteIcon />} 
                                    onClick={()=> {
                                        setDeleteAccountConfirmIsOpen(true)
                                    }}
                                > 
                                    {translate('resources.profile.accCancel')}
                                </Button>
                            )
                        }
                        <Box m={2}/>
                        <div className={classes.paperPublicWrapper}>
                        <Paper variant="outlined" className={classes.papperStyle}>
                            <Grid 
                                container 
                                item xs={12} 
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={profileIsPublic} 
                                    onChange={()=>{
                                        setProfileStatus(!profileIsPublic)
                                        setEditable({...editable,editable:true})
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                }
                                label={!profileIsPublic ? translate('resources.profile.profilePrivate'): translate('resources.profile.profilePublic')}
                            />
                            
                            
                            {profileIsPublic && //<InlineEditSelect defaultValue={user.userData.publicLevel ? "Global" : "For followers"} values = {[{name:"Global"},{name:"For followers"}]} dataType = "publicLevel" save={handleSaveChanges} alignCenter/>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    inputProps={{classes:{ icon:classes.icon, select:classes.select, root: classes.root}}}
                                    value={publicLevel}
                                    color = "primary"
                                    onChange={(e)=>{
                                        setPublicLevel(e.target.value);
                                        setEditable({...editable,editable:true})
                                    }}
                                    disableUnderline = {true}
                                >
                                    <MenuItem value={true}>{translate('resources.profile.publicLevelGlobal')}</MenuItem>
                                    <MenuItem value={false}>{translate('resources.profile.publicLevelForFollowers')}</MenuItem> 
                                </Select>
                            }
                            </Grid>
                        </Paper>
                        {editable.editable && 
                           <>
                                <SaveIcon 
                                    onClick={()=>{
                                        handleSaveChanges();
                                        setEditable({...editable,editable:false})   
                                    }}
                                    color="primary" 
                                    style={{
                                        cursor:'pointer',
                                        margin:'0px 5px',
                                    }}
                                /> 
                                <CloseIcon 
                                    onClick={handleCloseProfileStatus}
                                    color="primary" 
                                    style={{
                                        cursor:'pointer',
                                    }}
                                />  
                           </>
                        }   
                        </div>
                        
                        
                        <Box m={1}/>                 
                        <InlineEditTextField fieldType ="date" align="center" defaultValue={ user.userData.dateOfBirth} save={handleSaveChanges} dataType="dateOfBirth" />
                        <InlineEditSelect defaultValue={user.userData.sex} values = {[{name:"Male"},{name:"Female"}]} save={handleSaveChanges} dataType="sex" alignCenter nullValue/>
                        <InlineEditTextField align="center" placeholder = {translate('resources.profile.fields.country')} defaultValue={user.userData.country} save={handleSaveChanges} dataType="country"/>
                        <InlineEditTextField align="center" placeholder = {translate('resources.profile.fields.city')} defaultValue={user.userData.city } save={handleSaveChanges} dataType="city"/>
                        <Box m={1} />
                        <LocaleSwitcher/>
                        <Box m={1}/>
                        <Typography variant = 'h5' component = "h3">{translate('resources.profile.fields.description')}</Typography>
                        <InlineEditTextField align="center"  placeholder = {translate('resources.profile.fields.description')} defaultValue={user.userData.description } save={handleSaveChanges} dataType="description"/>
                        <Box m={2}/>
                        
                    </Box>
                    
                    
                    
                </Grid>
                <Grid 
                    container 
                    item xs ={12} md = {8}
                    display="row"
                    spacing={2}
                    className={classes.gridPadding}
                >
                    <Grid item xs = {12} md = {6}  >
                        <StatsCard stats={user.userStats && user.userStats.generalStats && user.userStats.generalStats[0]}/>
                    </Grid>
                    {user.userStats && user.userStats.statsByType && user.userStats.statsByType.map(stats=>(
                        <Grid item xs = {12} md = {6} key = {stats.typeName}>
                            <StatsCard stats={stats}/>
                        </Grid>
                    ))}
                    
                    
                </Grid>
               
                                
            </Grid>
            </>
            }
             <Dialog open={changePasswordDialog.isOpen} onClose={()=>toggleDialogChangePassword()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('resources.profile.fields.changePassword')}</DialogTitle>
                <DialogContent>
                
                <TextField
                    autoFocus
                    margin="dense"
                    label={translate('resources.profile.fields.currentPassword')}
                    type="password"
                    value={changePasswordDialog.oldPassword}
                    onChange={(e)=>setToggleChangePasswordDialog({...changePasswordDialog, oldPassword:e.target.value})}
                    fullWidth
                />
                 <TextField
                    margin="dense"
                    label={translate('resources.profile.fields.newPassword')}
                    type="password"
                    value={changePasswordDialog.newPassword}
                    onChange={(e)=>setToggleChangePasswordDialog({...changePasswordDialog, newPassword:e.target.value})}
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>toggleDialogChangePassword()} color="primary">
                    {translate('global.action.cancel')}
                </Button>
                <Button 
                    onClick={()=>{
                        const {oldPassword,newPassword} = changePasswordDialog
                        changeUserPassword(oldPassword,newPassword); 
                        toggleDialogChangePassword();
                    }} 
                    color="primary">
                    {translate('global.action.save')}
                </Button>
                </DialogActions>
            </Dialog>
            <ConfirmAlert 
                isOpened={deletePhotoConfirmIsOpen.isOpen} 
                toggleOpen={()=>setDeletePhotoConfirmIsOpen({...deletePhotoConfirmIsOpen, isOpen:!deletePhotoConfirmIsOpen.isOpen})} 
                save ={()=> deleteUserPhotos(deletePhotoConfirmIsOpen.photoType)}
                title={translate("resources.profile.photoSure")}
            />
            <ConfirmAlert 
                isOpened={deleteAccountConfirmIsOpen} 
                toggleOpen={()=>setDeleteAccountConfirmIsOpen(!deleteAccountConfirmIsOpen)} 
                save ={()=>{
                    deleteAccount()
                    .then(()=>setMessage(translate('resources.profile.emailConfirm')))
                }}
                title={translate("resources.profile.sure")}
            />
        </> 
    )
}
const mapStateToProps = state =>({
    user: state.user
})

export default connect(mapStateToProps,{getOwnerStats,changeUserPassword,changeUserData,changeUserPhotos,deleteUserPhotos,setMessage,deletionActions})(Profile);
