import React,{useRef, useEffect,useState} from 'react';
import  Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Map, TileLayer, Polyline, Marker,Popup } from 'react-leaflet';
import Button from '@material-ui/core/Button';
import { CardMedia, Slider , CardActions, IconButton, MenuItem, Menu, Typography, Box, Paper, Table, TableRow, TableBody,TableCell,TableHead, List, ListItem, ListItemIcon, Checkbox, ListItemText, Tooltip} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import L from 'leaflet'; 
import {colorConverter, travelPolylineType} from '../_helpers';
import {useTranslate} from 'react-admin';
import Control from 'react-leaflet-control';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SettingsIcon from '@material-ui/icons/Settings';
import LoopIcon from '@material-ui/icons/Loop';
import markerImg from '../assets/map-marker-icon.png';
import { travelPhotos } from '../config/config';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {toLocalTime} from '../_helpers';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { splitTravel, mergeTravels, getTravels } from '../data.sercive';
import CropIcon from '@material-ui/icons/Crop';
import { getSingleTravelDetail} from '../actions/travels';
import CheckIcon from '@material-ui/icons/Check';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { setMessage } from '../actions/message';
import CircularProgress from './CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { Input } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    cardContent:{
        overflow:'hidden',
        maxWidth: '100%',
        margin: '0px auto',
        height: mapHeight=> mapHeight.maxHeight,
        position:'relative',
    },
    buttonActions:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    setingsIcon:{
        margin:'-10px',
        padding:13,
        '&:hover':{
            color:'#19816B',
        },
    },
    menu:{
        width:300
    },
    popupImageStyle:{
        height:300,
        width: 300,
        objectFit: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundsize: 'cover',
    },
    logsWrapper:{
        position:'absolute',
        top:10,
        right:10,
        height:480,
        zIndex:1000,
        padding:'15px 5px',
        overflow:'auto'
    },
    fontWeightStyle:{
        fontWeight:600,
    },
    smallInfoPanel:{
        position:'absolute',
        top:10,
        right:10,
        minHeight:50,
        minWidth:100,
        zIndex:1000,
        padding:'15px 5px',
        overflow:'auto'
    }
  }));


const SingleTravelMap =({travelPosition,mapHeight,getSingleTravelDetail,setMessage})=> {
    const [logs, setLogs] = useState([]);
    const [isPlaying,setIsPlaying] = useState(false);
    let [progress, setProgress] = useState({
        count:0,
        isStop:true,
        isEnd:true,
        speed: 500,
        weight: 10,
        color:'',
        current:[]
    });
    let [polylineSize] = useState([]);
    const classes = useStyles({maxHeight: mapHeight});
    const translate = useTranslate();
    const mapRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPhotos, setShowPhotos] = useState(true);
    const [imageMarkers,setImageMarker] = useState([]);
    const [showLogs,setShowLogs] = useState(false);
    const [currentLogs, setCurrentLogs] = useState([]);
    const [avgTravelPosition, setAvgTravelPosition] = useState('')
    const [showTravelsToMerge, setShowTravelsToMerge] = useState({
        isOpen:false,
        travels:[],
        checkedTravels:[],
    });
    const [splitTravelLogs,setSplitTravelLogs] = useState({
        isOpen:false,
        logs:''
    })
    const [localization, setLocalization] = useState({
        coordinates:[50.01, 20.98],
        localizationPermission:false,
    })

    let myIcon = L.icon({
        iconUrl: markerImg,
        iconSize: [38, 38],
        iconAnchor: [18, 38],
        popupAnchor: [-3, -76],
        shadowUrl: '',
        shadowSize: [68, 95],
        shadowAnchor: [22, 94]
    });
    

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseInfoMapPopup = (current) =>{
        if(current === splitTravelLogs){
            setShowTravelsToMerge({...showTravelsToMerge,isOpen:false})
            setShowLogs(false);
        }
        else if(current === showLogs){
            setShowTravelsToMerge({...showTravelsToMerge,isOpen:false})
            setSplitTravelLogs({...splitTravelLogs, isOpen:false});
        }
        else if(current === showTravelsToMerge){
            setShowLogs(false);
            setSplitTravelLogs({...splitTravelLogs, isOpen:false});
        }
    }
    useEffect(()=>{
        const map = mapRef.current.leafletElement; 
        travelPosition.travelDetail && travelPosition.travelDetail.logs === 0 &&
            navigator.geolocation.getCurrentPosition(function success(pos) {
                let crd = pos.coords;
                setLocalization({ coordinates:[crd.latitude,crd.longitude], localizationPermission:true,})
                 
            })
        localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
    },[travelPosition.travelDetail,localization.localizationPermission])

    useEffect(() => {
        let gpsLogs =[];
        const map = mapRef.current.leafletElement; 
        const mapLogs = travelPosition.travelDetail.logs || [] ;
        let currentLogs=[];
        const drawTravelLine = (mapLogs) =>{
            let lat = 0;
            let lng = 0;
            mapLogs.map((log, i)=>{
                lat += log.latitude;
                lng += log.longitude;
                currentLogs.push([log.latitude, log.longitude])
                if(log.latitude ===null){
                    return
                }
                if(log.paused || mapLogs.length - 1 === i){
                        gpsLogs=[...gpsLogs,currentLogs];
                        currentLogs=[]; 
                }
                return gpsLogs;
            })
            
            setLogs(gpsLogs) 
            gpsLogs.length && map.fitBounds(gpsLogs)
            
            const avg = [lat/mapLogs.length, lng/mapLogs.length];
            setAvgTravelPosition(avg);
            
        }
        drawTravelLine(mapLogs);
        
    }, [travelPosition.travelDetail]);
    
    //Sprawdzanie dlugości odcinków, do rysowania lini na mapie "LIVE"
    useEffect(()=>{
        let tempIndex = 0;
        const mapLogs = travelPosition.travelDetail.logs || [] ;

       mapLogs.length > 1  && mapLogs.filter((obj, index)=>{
            if(obj.latitude ===null){
                return
            }
            if(obj.paused || mapLogs.length -1 === index ) {
                polylineSize.push({MinValue: tempIndex <= 0 ? 0 : tempIndex + 1, MaxValue: index});
                tempIndex = index;
            }
            return null
        })

    },[travelPosition.travelDetail.logs])

    useEffect(() => {
        const map = mapRef.current.leafletElement; 
        const mapLogs = travelPosition.travelDetail.logs && travelPosition.travelDetail.logs[0].latitude ?  travelPosition.travelDetail.logs : [] ;
        let marker; 
        let mapTab = [];
        let currentLogs = [];
        let polyline;
        
       
            const interval = setInterval(()=>{
               
                if(progress.count === mapLogs.length){
                    marker && map.removeLayer(marker);  
                    clearInterval(interval);
                    polyline && map.removeLayer(polyline)
                    logs.length && map.fitBounds(logs)
                    setProgress({...progress, isStop: true, isEnd:true})
                }
                else
                {   
                    if(progress.isStop){
                        clearInterval(interval);
                    }else{
                        mapTab = [];
                        currentLogs = [];
                        

                        for (let i = 0; i < progress.count + 1; i++) {
                            mapTab.push(mapLogs[i]);
                        }

                        polylineSize.length && polylineSize.filter((polyline)=>{
                            if (polyline.MinValue <= progress.count) {
                                currentLogs.push(mapTab.slice(polyline.MinValue, polyline.MaxValue + 1).map( log => [log.latitude, log.longitude]));
                                setProgress({...progress, current: progress.current = currentLogs})
                            }
                            return null
                        })
                        
                        marker ? marker.setLatLng([mapLogs[progress.count].latitude, mapLogs[progress.count].longitude]) : marker = L.marker([mapLogs[progress.count].latitude, mapLogs[progress.count].longitude],{icon:myIcon}).addTo(map) 
                        
                        polyline.setLatLngs(currentLogs, {
                            dashArray:travelPolylineType(travelPosition.travelDetail.line), 
                            weight:progress.weight,
                            color:travelPosition.travelDetail.color ,
                            
                        })

                        setProgress({...progress, count: progress.count++  })
                        currentLogs.length && progress.count !== mapLogs.length &&  map.setView([mapLogs[progress.count].latitude,mapLogs[progress.count].longitude])
                    }    
                }
            },progress.speed)
            polyline = mapLogs.length && L.polyline([[mapLogs[0].latitude, mapLogs[0].longitude]], {
                dashArray:travelPolylineType(travelPosition.travelDetail.line), 
                weight:progress.weight,
                color:travelPosition.travelDetail.color ,
                
            }).addTo(map)
        
        return ()=>{
            clearInterval(interval); 
            setTimeout(()=>{
                polyline && map.removeLayer(polyline)
               marker && map.removeLayer(marker);  
            },progress.speed + 100)
            

        };    
        
    },[ isPlaying,progress.speed,progress.weight,progress.isEnd])
    
    useEffect(()=>{
        const markers =[];
        const {photos} =travelPosition.travelDetail;
        if(photos && photos.length){
            photos.map((photo)=>{
                if(photo.latitude && photo.longitude){
                    markers.push(photo)
                }
                setImageMarker(markers);
            })
        } 
        else{
            setImageMarker([])
        }

    },[travelPosition.travelDetail, travelPosition.travelDetail.photos])

    useEffect(()=>{
        const map = mapRef.current.leafletElement; 
        const addMarker = (e) =>{  
            let markerPos = (e.latlng)
            const searchAllPontis = travelPosition.travelDetail.logs.find(log=>
                Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.003 
                && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.003)
            const searchSpecific = travelPosition.travelDetail.logs.find(log=>
                Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.002 
                    && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.002)
            const searchMoreSpecific = travelPosition.travelDetail.logs.find(log=>
                Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.001 
                    && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.001)
            const searchPrecise = travelPosition.travelDetail.logs.find(log=>
                Math.abs(log.latitude - markerPos.lat.toFixed(6)) < 0.0001 
                    && Math.abs(log.longitude - markerPos.lng.toFixed(6)) < 0.0001)
        
            if(searchPrecise){
                setSplitTravelLogs({...splitTravelLogs, logs:{lat:searchPrecise.latitude, lng:searchPrecise.longitude, id:searchPrecise.id}})
            }else if(searchMoreSpecific){
                setSplitTravelLogs({...splitTravelLogs, logs:{lat:searchMoreSpecific.latitude, lng:searchMoreSpecific.longitude, id:searchMoreSpecific.id}})
            }else if(searchSpecific){
                setSplitTravelLogs({...splitTravelLogs, logs:{lat:searchSpecific.latitude, lng:searchSpecific.longitude, id:searchSpecific.id}})
            }else if (searchAllPontis){
                setSplitTravelLogs({...splitTravelLogs, logs:{lat:searchAllPontis.latitude, lng:searchAllPontis.longitude, id:searchAllPontis.id}})
            }       
        }
  
        if(splitTravelLogs.isOpen){
            map.on("click", addMarker);
        }          

        return ()=> {map.off("click", addMarker);}
        
    })
        
        

    return (
        <Card>
            <CardMedia className={classes.cardContent}>
                <Map 
                    ref={mapRef}
                    center={localization.coordinates}
                    id="leafletMapTravel"
                    renderer={L.canvas()}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {
                        logs && progress.isEnd ? (
                            <>
                            
                                {logs.map((polyline,index) =>(
                                    <Polyline 
                                    key={index}
                                    color={progress.color ? progress.color : travelPosition.travelTypes[travelPosition.travelDetail.travelTypeId-1] ? travelPosition.travelTypes[travelPosition.travelDetail.travelTypeId-1].color : travelPosition.travelDetail.color ? travelPosition.travelDetail.color : "green"}
                                    positions={polyline}
                                    dashArray={travelPosition.travelTypes[travelPosition.travelDetail.travelTypeId-1] ? travelPolylineType(travelPosition.travelTypes[travelPosition.travelDetail.travelTypeId-1].line) : travelPolylineType(travelPosition.travelDetail.line)}
                                    weight={progress.weight} 
                                    />
                                ))}

                                {travelPosition.travelDetail.logs && travelPosition.travelDetail.logs[0].latitude && 
                                <>
                                    <Marker 
                                        icon={
                                            new L.Icon({
                                            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
                                            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                                            iconSize: [25, 41],
                                            iconAnchor: [12, 41],
                                            popupAnchor: [1, -34],
                                            shadowSize: [41, 41]
                                            })
                                        }
                                        position={[travelPosition.travelDetail.logs[0].latitude, travelPosition.travelDetail.logs[0].longitude]}
                                    /> 
                                    <Marker 
                                        icon={
                                            new L.Icon({
                                                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                                                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                                                iconSize: [25, 41],
                                                iconAnchor: [12, 41],
                                                popupAnchor: [1, -34],
                                                shadowSize: [41, 41]
                                            })
                                        }
                                        position={[travelPosition.travelDetail.logs[travelPosition.travelDetail.logs.length-1].latitude,travelPosition.travelDetail.logs[travelPosition.travelDetail.logs.length-1].longitude]}
                                    /> 
                                </>
                            }
                                
                        </>
                        ):( 
                            <>
                                {progress.current.length &&  progress.isStop && progress.current.map((polyline,index) =>(
                                    <Polyline 
                                    key={index}
                                    color={travelPosition.travelDetail.color}
                                    positions={polyline}
                                    dashArray={travelPolylineType(travelPosition.travelDetail.line)}
                                    weight={progress.weight} 
                                    />
                                ))}
                                {progress.isStop && 
                                    <Marker 
                                            position ={[travelPosition.travelDetail.logs[progress.count].latitude, travelPosition.travelDetail.logs[progress.count].longitude]}
                                            icon={myIcon}
                                    />
                                }
                                
                            </>
                            
                        )
                    }
                    {progress.isEnd && showPhotos && imageMarkers.length ? imageMarkers.map((marker) => marker.latitude ? (
                            <Marker 
                                key = {marker.filePath}
                                position ={[marker.latitude,marker.longitude]}
                                icon = {
                                    L.divIcon({
                                        html: `<img src='${travelPhotos}/${marker.filePath}'/>`,
                                        className : marker.isCover ? 'image-icon-cover' : 'image-icon',
                                    })
                                }
                            >
                                <Popup>
                                    <div style={{display:'flex' ,flexDirection:'column', 'alignItems':'center'}}>
                                        <Typography variant = "subtitle1">{marker.description}</Typography>
                                        <Typography variant = "subtitle2">{marker.createdAt && toLocalTime(marker.createdAt)}</Typography>
                                        <img src={`${travelPhotos}/${marker.filePath}`} className = {classes.popupImageStyle}/>
                                    </div>
                                </Popup>
                            </Marker>
                        ): null)
                        :null    
                    }
                    {currentLogs.length > 0 &&
                        <Marker 
                            position ={currentLogs}
                        />
                    }
                    {splitTravelLogs.logs &&
                        <Marker 
                            position ={[splitTravelLogs.logs.lat,splitTravelLogs.logs.lng]}
                        />
                    }
                        <>
                        <Control position="topright" >
                            <div>
                                <Paper elevation={3}>
                                    <Tooltip title={translate('resources.travels.setings')} placement="left">
                                        <IconButton    
                                            aria-controls="simple-menu" 
                                            aria-haspopup="true" 
                                            onClick={handleClick}
                                            color="primary"
                                            className={classes.setingsIcon}
                                            
                                        >
                                            <SettingsIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </Paper>
                                <Menu id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem 
                                        disableTouchRipple={true} 
                                        className={classes.menu}
                                    >
                                        <Typography>
                                            {translate('resources.travels.playTravel.delay')}
                                        </Typography>
                                        <Box pr={2}/>
                                        <Slider 
                                            value={progress.speed} 
                                            onChange={(event, newValue)=>setProgress({...progress,speed:newValue })} 
                                            min={200} 
                                            max ={1000}
                                        />
                                    </MenuItem>
                                    <MenuItem 
                                        disableTouchRipple={true} 
                                        className={classes.menu}
                                    >
                                        <Typography>
                                            {translate('resources.travels.playTravel.weight')}
                                        </Typography>
                                        <Box pr={2}/>
                                        <Slider 
                                            value={progress.weight} 
                                            onChange={(event, newValue)=>setProgress({...progress,weight:newValue })} 
                                            min={1} 
                                            max ={12}
                                        />
                                    </MenuItem>
                                    <MenuItem 
                                        disableTouchRipple={true} 
                                        className={classes.menu}
                                    >
                                        <Typography>
                                            {translate('resources.travels.playTravel.color')}
                                        </Typography>
                                        <Box pr={2}/>
                                        <Input 
                                            type = "color" 
                                            fullWidth 
                                            disableUnderline = {true}
                                            value={progress.color || colorConverter(travelPosition.travelDetail.color)}
                                            onChange={(e)=>setProgress({...progress,color:e.target.value })}
                                        />
                                    </MenuItem>
                                </Menu>
                            </div>
                        </Control>
                        </>
                    
                    <Control position="topright" >
                        <div>
                            <Paper elevation={3}>
                                <Tooltip title={translate('resources.travels.photo.show')} placement="left">
                                    <IconButton    
                                        aria-controls="simple-menu" 
                                        aria-haspopup="true" 
                                        onClick={()=>setShowPhotos(!showPhotos)}
                                        color="primary"
                                        className={classes.setingsIcon}
                                        
                                    >
                                        <PowerSettingsNewIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        </div>
                    </Control>
                    
                    <Control position="bottomleft" >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            id="button-map-center"
                            onClick={()=>{
                                const map = mapRef.current.leafletElement; 
                                logs.length ? map.flyToBounds(logs) : localization.localizationPermission ? map.setView(localization.coordinates,12) : map.setView(localization.coordinates,5)
                            }}
                        >
                        {translate("global.action.center")}
                        </Button>
                    </Control>   
                    <Control position="topleft" >
                        <div>
                            <Paper elevation={3}>
                                <Tooltip title={translate('resources.travels.logs.date')} placement="right">
                                    <IconButton    
                                        color="primary"
                                        className={classes.setingsIcon}
                                        onClick={()=>{
                                            setShowLogs(!showLogs)
                                            setCurrentLogs([])
                                            handleCloseInfoMapPopup(showLogs)
                                        }}
                                        
                                    >
                                        <LocationOnIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        </div>
                    </Control>    
                    <Control position="topleft" >
                        <div>
                            <Paper elevation={3}>
                                <Tooltip title={translate('resources.travels.merge.header')} placement="right">
                                    <IconButton    
                                        onClick={()=>{
                                            handleCloseInfoMapPopup(showTravelsToMerge)
                                            setShowTravelsToMerge({...showTravelsToMerge, isOpen:!showTravelsToMerge.isOpen})
                                            !showTravelsToMerge.travels.length && getTravels().then(res=>setShowTravelsToMerge({...showTravelsToMerge,isOpen:true, travels:res.data}));

                                        }}
                                        color="primary"
                                        className={classes.setingsIcon}
                                        
                                    >
                                        <MergeTypeIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        </div>
                    </Control>   
                    <Control position="topleft" >
                        <div>
                            <Paper elevation={3}>
                                <Tooltip title={translate('resources.travels.crop.crop')} placement="right">
                                    <IconButton    
                                        onClick={()=>{
                                            handleCloseInfoMapPopup(splitTravelLogs)
                                            setSplitTravelLogs({...splitTravelLogs, isOpen:!splitTravelLogs.isOpen})
                                        }}
                                        color="primary"
                                        className={classes.setingsIcon}
                                        
                                    >
                                        <CropIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        </div>
                    </Control>   
                </Map>
                {showLogs && 
                    <Paper elevation={3} className={classes.logsWrapper}>
                        <Box display="flex" justifyContent="space-around" alignItems="center">
                            <Typography variant = "subtitle1" className={classes.fontWeightStyle}>Logs</Typography>
                            <Button 
                                color="primary"
                                className={classes.fontWeightStyle}
                                onClick={()=>{ 
                                    const csvData = travelPosition.travelDetail.logs.map(travel=>[travel.latitude,travel.longitude]);
                                    const csv = csvData.map(e => e.join(",")).join("\n")
                                    let pom = document.createElement('a');
                                    let blob = new Blob([csv],{type: 'text/csv;charset=utf-8;'});
                                    let url = URL.createObjectURL(blob);
                                    pom.href = url;
                                    pom.setAttribute('download', 'Logs.csv');
                                    pom.click();
                                }}
                            >
                                Export
                            </Button>
                            
                            
                        </Box>
                            <Box display="flex" justifyContent="center" mt={1} onClick={()=> avgTravelPosition && setCurrentLogs(avgTravelPosition)} style = {{cursor:'pointer'}}>
                                <Typography variant="subtitle2">{`AVG: ${avgTravelPosition && avgTravelPosition[0].toFixed(6)} | ${avgTravelPosition && avgTravelPosition[1].toFixed(6)}`}</Typography>
                            </Box>
                            <Table className={classes.table} id="logsTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><Typography variant = "subtitle2" className={classes.fontWeightStyle}>Lat</Typography></TableCell>
                                        <TableCell align="center"><Typography variant = "subtitle2" className={classes.fontWeightStyle}>Lng</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { travelPosition.travelDetail.logs.map(travel=>( 
                                        <TableRow hover key = {travel.id} onClick={()=>setCurrentLogs([travel.latitude,travel.longitude])}>
                                            <TableCell>{travel.latitude}</TableCell>
                                            <TableCell>{travel.longitude}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                    </Paper>
                }
                {showTravelsToMerge.isOpen &&
                    <Paper elevation={3} className={classes.logsWrapper}>
                        <Box display="flex" justifyContent="space-around" alignItems="center">
                            <Typography variant = "subtitle1" className={classes.fontWeightStyle}>{translate('resources.travels.name')}</Typography>
                            {showTravelsToMerge.checkedTravels.length > 0 && 
                            <Box>
                                <Tooltip title={translate('resources.travels.merge.accept')} placement="bottom">
                                    <IconButton    
                                        color="primary" 
                                        onClick={()=>{
                                            const travelTableId = showTravelsToMerge.checkedTravels.map(checked => ({id:checked.id}))
                                            mergeTravels(travelPosition.travelDetail.id,travelTableId)
                                                .then(()=>getSingleTravelDetail(travelPosition.travelDetail.id))
                                                .then(()=>setMessage('update'))
                                                .catch(()=>setMessage('error','error'))
                                            }}
                                    >
                                        <CheckIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={translate('resources.travels.merge.cancel')} placement="bottom">
                                    <IconButton    
                                        color="primary" 
                                        onClick={()=>setShowTravelsToMerge({...showTravelsToMerge, isOpen:!showTravelsToMerge.isOpen, checkedTravels:[]})}
                                    >
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            }
                        </Box>  
                        <List>
                            {showTravelsToMerge.travels.length ?
                                showTravelsToMerge.travels.map(travel=>travel.id === travelPosition.travelDetail.id ? null : (
                                    <ListItem dense button key={travel.id}>
                                        <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={showTravelsToMerge.checkedTravels.find(checked =>checked.id === travel.id ) ? true : false}
                                            disableRipple
                                            color="primary"  
                                            onChange={()=>{
                                                const index = showTravelsToMerge.checkedTravels.findIndex(checked => checked.id === travel.id);
                                                const newArray = [...showTravelsToMerge.checkedTravels];
                                                if(index!==-1){
                                                    newArray.splice(index,1)
                                                }
                                                else{
                                                    newArray.push(travel)
                                                }
                                                setShowTravelsToMerge({...showTravelsToMerge, checkedTravels:newArray})                                            
                                            }} 
                                        />
                                        </ListItemIcon>
                                        <ListItemText  primary={travel.title} />
                                    </ListItem>
                                
                            ))
                            :
                            <CircularProgress/>}      
                        </List>
                    </Paper>
                }
                {splitTravelLogs.isOpen && 
                <Paper elevation={3} className={classes.smallInfoPanel}>
                    <Box display="flex" justifyContent="space-around" alignItems="center">
                        <Typography variant = "subtitle1" className={classes.fontWeightStyle}>{translate('resources.travels.split.info')}</Typography>
                        {splitTravelLogs.logs && 
                            <Box>
                                <Tooltip title={translate('resources.travels.split.accept')} placement="bottom">
                                    <IconButton    
                                        color="primary" 
                                        onClick={()=>{
                                            splitTravel(travelPosition.travelDetail.id,splitTravelLogs.logs.id)
                                            .then(()=>getSingleTravelDetail(travelPosition.travelDetail.id))
                                            .then(()=>setMessage('update'))
                                            .catch(()=>setMessage('error','error'))
                                        }}
                                    >
                                        <CheckIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={translate('resources.travels.split.cancel')} placement="bottom">
                                    <IconButton    
                                        color="primary" 
                                        onClick={()=>setSplitTravelLogs({...splitTravelLogs, logs:'',isOpen:false})}
                                    >
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    </Box>  
                    {splitTravelLogs.logs &&
                    <Table className={classes.table} id="logsTable">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><Typography variant = "subtitle2" className={classes.fontWeightStyle}>Lat</Typography></TableCell>
                                <TableCell align="center"><Typography variant = "subtitle2" className={classes.fontWeightStyle}>Lng</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        
                                <TableRow hover>
                                    <TableCell align="center">{splitTravelLogs.logs.lat}</TableCell>
                                    <TableCell align="center">{splitTravelLogs.logs.lng}</TableCell>
                                </TableRow>
                            
                        </TableBody>
                    </Table>
                    }
                </Paper>
                }
            </CardMedia>
                <CardActions>
                         {!progress.isStop ? 
                                <IconButton 
                                    color="primary" 
                                    onClick={ () => {
                                        setProgress({...progress, isStop: true}); 
                                        setIsPlaying(false)
                                    }} 
                                >
                                   <PauseIcon/>
                                </IconButton>
                                :
                                travelPosition.travelDetail.logs &&  progress.count ===  travelPosition.travelDetail.logs.length ?
                                <IconButton 
                                    color="primary" 
                                    onClick={ () => { setProgress({...progress, count:0, isStop: false, isEnd:false}); setIsPlaying(true)}}
                                >
                                    <LoopIcon/>
                                </IconButton>
                                :
                                <IconButton 
                                    color="primary" 
                                    onClick={ () =>{ setProgress({...progress, isStop: false, isEnd:false}); setIsPlaying(true);}}
                                
                                >
                                    <PlayArrowIcon/>
                                </IconButton>
                            }
                        
                        <Slider 
                            value={progress.count} 
                            onChange={(event,newValue)=>{
                                setIsPlaying(false); 
                                setProgress({...progress, count: newValue, isStop:false, isEnd:false}); 
                                setTimeout(()=>{
                                    setIsPlaying(true);
                                },100)
                            }} 
                            max={travelPosition.travelDetail.logs && travelPosition.travelDetail.logs.length}
                        />
                </CardActions>
            
        </Card>
    )
}
SingleTravelMap.propTypes = {
    mapHeight:PropTypes.number,
}
SingleTravelMap.defaultProps = {
    mapHeight: 500,
};
const mapStateToProps = state =>({
    travelPosition: state.travels,
});

export default connect(mapStateToProps,{getSingleTravelDetail,setMessage})(SingleTravelMap);