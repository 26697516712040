import React from 'react'
import { Card, TableContainer, Table, TableCell, Paper, TableRow, TableHead, TableBody } from '@material-ui/core'
import {makeStyles} from "@material-ui/styles"
import TravelRow from './TravelRow'
import {useTranslate} from 'react-admin';

const useStyles = makeStyles((theme) => ({
    cardTable: {
      marginTop:20,
     
    },
    tableContainer:{
        width:'calc(100vw - 297px)',
        overflowX: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 57px)'
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 37px)'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100vw - 20px)'
        },
    }
}));

const TravelsTable =({showTravel,travels})=> {
    const classes = useStyles();
    const translate = useTranslate();
    return (        
            <Card className={classes.cardTable}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table  aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">{translate("resources.travels.travelsTableHeaders.distance")}</TableCell>
                            <TableCell align="center">{translate("resources.travels.travelsTableHeaders.date")}</TableCell>
                            <TableCell align="center">{translate("resources.travels.travelsTableHeaders.title")}</TableCell>
                            <TableCell align="center">{translate("resources.travels.travelsTableHeaders.type")}</TableCell>
                            <TableCell align="center">{translate("resources.travels.travelsTableHeaders.group")}</TableCell>
                            <TableCell align="center">{translate("resources.travels.travelsTableHeaders.duration")}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {travels && travels.map(travel=>(
                                <TravelRow 
                                    key = {travel.id}
                                    showTravel={showTravel} 
                                    {...travel} 
                                />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
    )
}
export default TravelsTable;
