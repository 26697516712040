import React,{ useState,useEffect } from 'react'
import { Select, MenuItem, ClickAwayListener } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslate} from 'react-admin';

const useStyles = makeStyles(()=>({
    icon:{
        display: 'none'
    },
    
    select: {
        userSelect:'text',
        cursor:'pointer',
        padding:(align)=>align.alignCenter && '5px 24px' ,
        "&:active, &:focus":{
            padding: (align)=>align.alignCenter ?'5px 24px' : '5px 24px 5px 5px' ,
            backgroundColor: 'rgb(230, 230, 230)',
            borderRadius:10,
        },     
    },
  
}))

//Edytowalny select po przez klikniecie
const InlineEditSelect = ({defaultValue,values,color,save,dataType, change, nullValue, alignCenter,dontTranslate}) => {
    const translate = useTranslate();
    const [editable, setEditable] = useState(false);
    const [value,setValue] = useState('');
    const classes = useStyles({alignCenter:alignCenter, editable:editable});
    useEffect(()=>{
        setValue(defaultValue);
    },[defaultValue])
 
    
    const exitEditing =()=>{
        setValue(defaultValue);
        change && change(defaultValue);
        setEditable(false);
    }
    
    const handleSaveChanges = (e) =>{
        e.stopPropagation();
        save(dataType,value) 
        setEditable(false);
    }


        
    return (
        <ClickAwayListener onClickAway={exitEditing} >
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            inputProps={{classes:{ icon:classes.icon, select:classes.select, root: classes.root}}}
            endAdornment={editable &&
                    <>
                        <SaveIcon color="primary" 
                            onClick={(e)=>handleSaveChanges(e)}
                            style={{
                                cursor:'pointer',
                                margin:'0px 5px',
                            }}
                        /> 
                        <CloseIcon color="primary" 
                            onClick={()=>exitEditing()}
                            style={{
                                cursor:'pointer',
                            }}
                        /> 
                    </>
                
            }
           
            disableUnderline = {defaultValue ? true : editable ? true : value ? true : false}
            value={value ||''}
            color = {color || "primary"}
            onChange={(e)=>{
                setValue(e.target.value)
                change && change(e.target.value)
            }}
            onMouseDown={(e)=>{
                
                !editable && setValue(defaultValue)
                setEditable(true)
            }}
            >
                {nullValue && <MenuItem value={''}>-</MenuItem>}
                {values ? 
                    values.map(item=>(<MenuItem key = {item.name} value={item.name}>{dontTranslate ? item.name : item.name ?  translate(`global.select.${item.name.toLowerCase()}`): null}</MenuItem>))
                    :
                    <MenuItem value={value}>{value}</MenuItem>
                 }
                
            </Select>
            </ClickAwayListener>
    )
}

export default InlineEditSelect