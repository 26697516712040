import React,{ useState } from 'react';
import {makeStyles} from '@material-ui/styles';
import {useTranslate} from 'react-admin';
import { Typography, Button } from '@material-ui/core';
import { confirmDeletingAccount, cancelDeletingAccount } from '../../data.sercive';
import {connect} from 'react-redux';
import {setMessage} from '../../actions/message';
import { deletionActions } from '../../actions/profile';
import {history} from '../../_helpers';

const useStyles = makeStyles(() => ({
    privateAccountWrapper:{
        height:'calc(100vh - 110px)',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
    },
}))
  

function ProfileDelete({match,setMessage,deletionActions}) {
    const classes = useStyles();
    const translate = useTranslate();
    const [expireTime, setExpireTime] = useState('');
    return (
        <div className={classes.privateAccountWrapper}>
            <Typography variant ="h4" paragraph>{translate('resources.profile.sure')}</Typography>
            {expireTime && 
                <>
                    <Typography variant ="h6" paragraph>
                        {`${translate('resources.profile.expire')} ${expireTime}`}
                    </Typography>
                    <Button 
                        variant="outlined"
                        color="primary"
                        onClick={()=>
                            cancelDeletingAccount()
                                .then(()=>{
                                    setMessage(translate('resources.profile.canceledDeletion'));
                                    deletionActions();
                                    history.push('/profile')
                                })
                                
                        }
                    >
                        {translate('resources.profile.cancelDeletion')}
                    </Button>
                </>
            }   
            {!expireTime && 
                <Button 
                    variant="contained"
                    onClick={()=>{
                        confirmDeletingAccount(match.params.token)
                            .then((res)=>{
                                let now = new Date();
                                now.setDate(now.getDate()+30);
                                let dd = now.getDate();
                                let mm = now.getMonth()+1;
                                let y = now.getFullYear();
                                setExpireTime(res.data)
                                deletionActions(`${y}-${mm}-${dd}`);
                            })
                        }}
                >
                    {translate('resources.profile.accountCancelation')}
                </Button>
                
            }
              
                
            
            

        </div>
    )
}

export default connect(null,{setMessage, deletionActions})(ProfileDelete);
