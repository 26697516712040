import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslate} from 'react-admin';


//Alert do krytycznych działan - np usuwanie danych -> wymagane potwierdzenie
export default function ConfirmAlert({isOpened, toggleOpen, title, save}) { 
    const translate = useTranslate();

  return (
      <Dialog
        open={isOpened}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogActions>
            <Button onClick={toggleOpen} color="primary">
                {translate("global.action.cancel")}
            </Button>
            <Button 
                onClick={()=>{
                  save(); 
                  toggleOpen()
                }}
                color="primary" 
                autoFocus
            >
                {translate("global.action.confirm")}
            </Button>
        </DialogActions>
      </Dialog>
  );
}