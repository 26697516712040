import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import {history} from './_helpers';
import authProvider from './actions/authProvider';
import createAdminStore from './createAdminStore';
import {baseUrlApi} from './config/config';
import { Provider } from 'react-redux';

const dataProvider = baseUrlApi;

ReactDOM.render(
    <Provider
        store={createAdminStore({
            authProvider,
            dataProvider,
            history,
        })}
    >
        <App />
    </Provider>
    
, document.getElementById("root"));
