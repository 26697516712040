import {types} from './types';
import {getTravelGroups,getTravelGroupDetail,createTravelGroup,changeTravelGroupData,deleteGroup} from '../data.sercive';
import {history} from '../_helpers';
import {setMessage} from './message';
import authProvider from './authProvider';

function groupRequest(){
    return { type: types.GET_GROUPS_REQUEST}
}

export const getUserGroups = () => {
    return dispatch => {
        dispatch(groupRequest())

        getTravelGroups()
            .then(response=>{
                dispatch(getUserGroupsRequestSuccess(response.data))            
            })
            .catch((err)=>{
                authProvider.refreshToken()
                dispatch(getUserGroupsRequestFailure())
            })
    }

    function getUserGroupsRequestSuccess(groups){
        return { type: types.GET_GROUPS_SUCCESS, payload:groups}
    }
    function getUserGroupsRequestFailure(){
        return { type: types.GET_GROUPS_FAILURE}
    }
}
export const getUserGroupDetails = (groupId) => {
    return dispatch => {
        dispatch(groupRequest())

        getTravelGroupDetail(groupId)
            .then(response=>{
                dispatch(getGroupDetailsRequestSuccess(response.data))            
            })
            .catch((err)=>{
                authProvider.refreshToken()
                dispatch(getGroupDetailsRequestFailure())
            })
    }

    function getGroupDetailsRequestSuccess(groups){
        return { type: types.GET_GROUP_DETAILS_SUCCESS, payload:groups}
    }
    function getGroupDetailsRequestFailure(){
        return { type: types.GET_GROUP_DETAILS_FAIULURE}
    }
}
export const createUserGroup = (name,description) => {
    return dispatch => {
        dispatch(groupRequest())

        createTravelGroup(name,description)
            .then(response=>{
                dispatch(setMessage("create"));
                dispatch(createTravelGroupSuccess(Object.assign(response.data,{...response.data, dateCreated:response.data.dateCreated.date, count:response.data.count.toString()})));
            })
            .catch(err=>{
                dispatch(createTravelGroupFailure())
                history.push({pathname: '/error'});
            })
    }
    function createTravelGroupSuccess(group){
        return{type: types.CREATE_TRAVEL_GROUP_SUCCESS, payload:group}
    }
    function createTravelGroupFailure(){
        return{type: types.CREATE_TRAVEL_GROUP_FAILURE}
    }
}
export const changeSingleGroupDetails = (groupId,name,description,travels,travelsToDelete) => {
    return dispatch => {
        changeTravelGroupData(groupId,name,description,travels,travelsToDelete)
            .then(response=>{
                dispatch(setMessage("update"));
                dispatch(changeGroupDetailsRequestSuccess(response.data,groupId));
            })
            .catch(err=>{
                history.push({pathname: '/error'});
                dispatch(changeGroupDetailsRequestFailure())
            })

    }
    function changeGroupDetailsRequestSuccess(details,id){
        return { type: types.CHANGE_GROUP_DETAILS_SUCCESS, payload:Object.assign(details,{id:id})}
    }
    function changeGroupDetailsRequestFailure(){
        return { type: types.CHANGE_GROUP_DETAILS_FAILURE}
    }
}
export const deleteSingleGroup = (groupID) => {
    return dispatch => {
        dispatch(groupRequest());

        deleteGroup(groupID)
            .then(response => {
                dispatch(setMessage("delete"));
                history.push('/travelGroups')
                dispatch(deleteGroupRequestSuccess(groupID))
            })
            .catch(err=>{
                history.push({pathname: '/error'});
                dispatch(deleteGroupRequestFailure())
            })

    }
    function deleteGroupRequestSuccess(id){
        return { type: types.DELETE_GROUP_SUCCESS, payload:id}
    }
    function deleteGroupRequestFailure(){
        return { type: types.DELETE_GROUP_FAILURE}
    }
}
