import React,{useEffect} from 'react';
import TravelsTable from '../Components/TravelsTable';
import { connect } from 'react-redux';
import { getFiveTravelsToDashboard} from '../actions/travels';
import { CircularProgress, Paper, Typography, Grid, Box} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import {Title, useTranslate} from 'react-admin';
import StatsCard from '../Components/StatsCard';
import DashboardMap from '../Components/DashboardMap';

const useStyles = makeStyles(() => ({
    tableWrapper: {
      display:'flex',
      justifyContent:'center',
      marginTop: 30,
    },
    tableInfo: {
      padding:20,
      width:'100%'
    },
    gridXs:{
      margin:0,
    }
}));


const Dashboard =({getFiveTravelsToDashboard,travels,user})=>{
    const classes = useStyles();
    const translate = useTranslate();

    useEffect(() => {
      if(localStorage.getItem('token')){
        getFiveTravelsToDashboard();
      }
      
    }, [])

    return(
        <>
        <Title title ={translate("resources.dashboard.name")}/>
            <DashboardMap />            
            {travels.isRequesting ? 
                <div className = {classes.tableWrapper}>
                  <CircularProgress/>
                </div>
              :
                travels.travels.length ? 
                  <TravelsTable showTravel travels = {travels.travels}/>
                :
                <div className = {classes.tableWrapper}>
                  <Paper className= {classes.tableInfo}>
                    <Typography variant = "h5" component = "p">{translate("resources.dashboard.empty")}</Typography>
                  </Paper>
                </div>
            } 
            <Box mb={2}/>
            {user.userStats && user.userStats.generalStats &&
              <Grid 
                  container 
                  item xs ={12} md = {12}
                  display="row"
                  spacing={1}
                  classes = {{['spacing-xs-1']:classes.gridXs}}
              >
                  <Grid item xs = {12} md = {4} >
                      <StatsCard stats={user.userStats.generalStats[0]}/>
                  </Grid>
                  {user.userStats && user.userStats.statsByType && user.userStats.statsByType.map(stats=>(
                      <Grid item xs = {12} md = {4} key = {stats.typeName}>
                          <StatsCard stats={stats} style={{margin:5}}/>
                      </Grid>
                  ))}
                  
                  
              </Grid>
            }
        </>
    )
}
const mapStateToProps = state =>({
  travels: state.travels,
  user: state.user
})

export default connect(mapStateToProps, {getFiveTravelsToDashboard})(Dashboard);