import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {MenuItemLink,getResources,useTranslate} from 'react-admin';
import { makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import logo from '../assets/followme_logo_white_720.png';
import {useSelector} from 'react-redux';


const useStyles = makeStyles((theme)=>({
    image: {
        objectFit: 'cover',
        maxWidht:200,
        width:'100%',
        padding:'10px 20px',
    },
    link:{
        color:theme.palette.secondary.main,
        textDecoration:'none',
        margin:"10px 0",
    },
}));

const AdminDrawer=({ onMenuClick })=> {
    const classes = useStyles();
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const translate = useTranslate();
    return (
    <div>
        <Link to = {"/dashboard"}>
            <img className={classes.image} src={logo} alt = 'FollowMe'/>
        </Link>
        <Divider />
        <List>
        {resources.map(resource => (
            <div  key={resource.name}>
            <MenuItemLink
               
                className = {classes.link}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`)}
                leftIcon = {resource.icon}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {resource.name === "travelgroups" || resource.name === "profile" ? (
                <Divider
                  key={resource.name + "_divider"}
                />
              ): null
            }
            </div>
        ))}
            <Divider/>
        </List>
        
       
    </div>
    )
}
export default AdminDrawer;