import React from 'react'
import { makeStyles, Box, Grid, Paper, Typography} from '@material-ui/core';
import logo from '../assets/FollowMe-logo-white.png';
import PropTypes from 'prop-types';
import {history} from '../_helpers';

const useStyles = makeStyles((theme) => ({
    backgroundTheme:{
       width:'100%',
       minHeight:'100vh',
       background: 'radial-gradient(circle,#76D3C0,#25BA9A)',
       backgroundPosition: '50% 50%',
       backgroundRepeat: 'no-repeat',
       backgroundSize: 'cover',
    },
    logo:{
        width:'100%',
        minWidth: 150,
        maxWidth: 300,
        margin:"50px 0",
        cursor:'pointer'
    },
    formBox:{
        [theme.breakpoints.up('xs')]:{
            padding:'0px 60px 60px',
            width:500,
            
        },
        [theme.breakpoints.down('sm')]:{
            width:'calc(100vw - 30px)',
            padding:'0px 20px 30px'
        },
        
        color: theme.palette.secondary.main
    },
    title:{
        margin:'50px 0',
        color:theme.palette.primary.dark,
        fontWeight:500,
    }
}));

//Ogolny kontener do autoryzacji - logowanie/rejestracja/reset hasła
const AuthContainer = ({children, title}) => {
    const classes = useStyles();

    return (
        <Box className={classes.backgroundTheme}>
            <Grid 
                container 
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item >
                    <img src={logo} alt="FollowMe" className={classes.logo} onClick={()=>history.push('/')}/>
                </Grid>
                <Grid item >
                    <Paper className={classes.formBox}>
                        <Grid container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                             <Typography 
                                variant = "h3" color="primary" className={classes.title}
                            >
                                {title}
                            </Typography>

                            {children}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AuthContainer

AuthContainer.propTypes ={
    title : PropTypes.string.isRequired,
}