import React,{useState} from 'react'
import { Typography, Grid, Box, Button, IconButton, Dialog} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteIcon from '@material-ui/icons/Delete';
import { travelPhotos } from '../config/config';
import TravelImage from '../Components/TravelImage';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {makeStyles} from '@material-ui/styles';
import {useTranslate} from 'react-admin';
import {connect} from 'react-redux';
import {setMessage} from '../actions/message';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CropIcon from '@material-ui/icons/Crop';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    imageStyle:{
        height:'100%',
        width:'100%',
        objectFit:'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition:'transform 0.3s ease',
       
    },
    imageBox:{
        height:150,
        width:150,
        border:'2px solid #fce4ec',
        borderRadius:"5px",
        overflow:'hidden',
        cursor:'pointer',
        transition: 'all ease-in 200ms',
        '&:active':{
            transform: 'scale(0.95)'
        }
    },
    input:{
        display:'none'
    },
    uploadButton:{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        cursor:'pointer',
        transition: 'all ease-in 200ms',
        '&:active':{
            transform: 'scale(0.95)'
        }

    },
    uploadedImage:{
        height:150,
        width:150,
        border:'2px solid blue',
        borderRadius:"5px",
        overflow:'hidden',
        position:'relative',
    },
    imageEditWrapper:{
        background: 'linear-gradient(180deg, rgba(0,0,0,0.5662640056022409) 40%, rgba(0,0,0,0.18531162464985995) 92%, rgba(0,0,0,0) 100%)',
        position: 'absolute',
        top: 0,
        left: 0,
        width:'100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
}));

function PhotosSection({photos, id, uploadPhoto, setMessage, type}) {
    const classes = useStyles();
    const translate = useTranslate();
    const [openImageDialog, setToggleImageDialog] = useState({
        isOpen:false,
        image:{},
        travelId:'',
    });
    const [loadedImage, setImage] = useState([]);
    const [isCroped, setIsCroped] = useState({
        isOpen:false,
        imgSrc:'',
        crop: {},
        index:'',
        pixelCrop:'',
    });

    const handleShowImage = (image) => {
        setToggleImageDialog({...openImageDialog, isOpen:true, image:image, travelId:id});
    };
    const handleCloseImage = () =>{
        setToggleImageDialog({isOpen:false});
    }
    const handleDeleteUploadedPhoto = (index)=>{
        let newArrayOfPhotos = [...loadedImage];
        newArrayOfPhotos.splice(index,1);
        setImage(newArrayOfPhotos);
    }

    //Wysyła zdjęcia jedno po drugim 
    const handleUploadPhotos = ()=>{
        const fd = new FormData();
        let images = loadedImage;
        const uploader = setInterval(async ()=>{
            fd.append("file", images[0]);
            await uploadPhoto(fd,id)
            images.splice(0,1)
            setImage([]);
            if(!images.length){
                clearInterval(uploader);
                setMessage("create");
            }
        },100)
    }

    const handleOnCropChange = (crop) =>{
        setIsCroped({...isCroped, crop:crop})
    }

    const handleOnCropComplete = (crop,pixelCrop) =>{
        setIsCroped({...isCroped, crop:crop, pixelCrop:pixelCrop})
    }

    const imageToBase64 = (image,index)=>{
        let reader = new FileReader();
        let baseString;
        reader.onloadend = function () {
            baseString = reader.result;
            setIsCroped({...isCroped, isOpen:true , imgSrc: baseString, index:index});
        };
        reader.readAsDataURL(image);
    }

    //przycinanie zdjęcia
    const getCroppedImg =(imageBase,pixelCrop,index)=> {
        let myImage = new Image();
        myImage.src = imageBase;

        const canvas = document.createElement("canvas");
        canvas.width = myImage.naturalWidth * (pixelCrop.width/100)
        canvas.height = myImage.naturalHeight * (pixelCrop.height/100)
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            myImage,
            myImage.naturalWidth * (pixelCrop.x/100),
            myImage.naturalHeight * (pixelCrop.y/100),
            canvas.width,
            canvas.height,
            0,
            0,
            canvas.width,
            canvas.height
        )
        canvas.toBlob(blob => {
            const images = [...loadedImage];
            images[index] = blob;
            setImage(images);
        })
        
        
    }
    

    return (
        <>
            <Box display="flex" alignItems="center" mb={1}> 
                <PhotoCameraIcon color = "primary"/> 
                <Box mr={1}/>
                <Typography variant = 'subtitle1' color="primary">
                    {translate('global.photos')}
                </Typography>
            </Box>
            <Grid container alignItems="center" spacing = {1} justify={window.innerWidth > 400 ? 'flex-start' : 'center'}>
               
                {photos && photos.length ? photos.map(image=>(
                    image.description !== "share" && 
                    <Grid item key = {image.id} onClick={()=>handleShowImage(image)}>
                        <Box className={classes.imageBox}>
                            <img src={`${travelPhotos}/${image.filePath}`} alt="FollowMe" className={classes.imageStyle}/>
                        </Box>
                    </Grid>
                )):null}
                {loadedImage && loadedImage.length ? loadedImage.map((image,index)=>
                    <Grid item key={index} >
                        <Box className={classes.uploadedImage}>
                            <Box className={classes.imageEditWrapper}>
                                <IconButton 
                                    onClick={()=>imageToBase64(image,index)}
                                >
                                    <CropIcon fontSize="small" color="secondary"/>
                                </IconButton>
                                <IconButton 
                                    onClick={()=>handleDeleteUploadedPhoto(index)}
                                >
                                    <DeleteIcon fontSize="small" color="secondary"/>
                                </IconButton>
                            </Box>
                            <img src={URL.createObjectURL(image)} alt="FollowMe" className={classes.imageStyle}/>

                        </Box>
                    </Grid>
                ): null}    
                {type !== "group" && 
                    <Grid item>
                        <Grid container alignItems="center" justify="center" className={classes.imageBox}>
                            <input accept="image/*" className={classes.input} multiple id="icon-button-file" type="file"   
                                onChange={e=>{
                                    if(e.target.files.length === 1 && e.target.files[0]){
                                        let files = [...loadedImage]; 
                                        files.push(e.target.files[0]); 
                                        setImage(files)
                                    }else if(e.target.files.length > 1){
                                        let files = [...loadedImage];
                                        for(let i = 0; i <e.target.files.length; i++ ){
                                            files.push(e.target.files[i])
                                        }
                                        setImage(files)
                                    }
                                    
                            }}/>
                            <label htmlFor="icon-button-file" className={classes.uploadButton}>            
                                    <AddAPhotoIcon fontSize="large" color="primary"/>
                            </label>
                        </Grid>
                    </Grid>
                }
                
                {loadedImage && loadedImage.length ? 
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={()=>uploadPhoto && type !== "group" ? handleUploadPhotos() : setMessage(translate('global.errorInfo'),"error")}
                    >
                       {translate("global.action.add")}
                    </Button>
                </Grid> : null }
            </Grid>
            {openImageDialog.image && 
                <TravelImage 
                    open = {openImageDialog.isOpen} 
                    close={handleCloseImage} 
                    image = {{image:openImageDialog.image, travelId:openImageDialog.travelId}} 
                    type ={type}
                />
            }
            <Dialog onClose={()=>setIsCroped({...isCroped, isOpen:false})} open={isCroped.isOpen} scroll="body">
                <Box display ='flex' alignItems="center" justifyContent="space-between" pl={1}>
                    <Typography variant = "h6">{translate('resources.travels.photo.crop')}</Typography>
                    <Box>
                        {isCroped.pixelCrop && 
                            <IconButton 
                                onClick={()=>{
                                    getCroppedImg(isCroped.imgSrc,isCroped.pixelCrop,isCroped.index);
                                    setIsCroped({ 
                                        isOpen:false,
                                        imgSrc:'',
                                        crop: {},
                                        index:'',
                                        pixelCrop:'',
                                    })
                                
                                }}
                            > 
                                <CheckIcon color="primary"/>
                            </IconButton>
                        }
                        <IconButton 
                            onClick={()=>setIsCroped({ 
                                isOpen:false,
                                imgSrc:'',
                                crop: {},
                                index:'',
                                pixelCrop:'',
                            })
                            }
                        >
                            <CloseIcon color="primary"/>
                        </IconButton>
                    </Box>
                </Box>
                {loadedImage.length && 
                
                    <ReactCrop 
                        crop={isCroped.crop}
                        src = {isCroped.imgSrc}
                        onChange={handleOnCropChange}
                        onComplete = {handleOnCropComplete}
                    />
                }
            </Dialog>
        </>
    )
}

export default connect(null,{setMessage})(PhotosSection);
