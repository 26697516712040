import React,{useState, useEffect} from 'react'
import AuthContainer from '../Components/AuthContainer'
import { TextField, Typography, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { sendLinkToRecoveryPassword } from '../data.sercive';
import {useTranslate} from 'react-admin';
import {setMessage} from '../actions/message';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    marginInput:{
       margin:'10px 0'
    },
    marginButton:{
        margin:'20px 0px 10px 0px '
    },
    link:{
        color: theme.palette.primary.main, 
        textDecoration: 'inherit' ,
        cursor: 'pointer',
    },
    errorText:{
         color:"#ff0000",
         margin:'-20px 0 20px'
     }
    
}));
const ResetPassword = ({history,setMessage}) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [recoveryEmail,setRecoveryEmail] = useState('');
    const classes = useStyles();
    const translate = useTranslate();
   
    const sendEmailToRecoveryPassword = () =>{
        if(recoveryEmail){ 
            setLoading(true)
            sendLinkToRecoveryPassword(recoveryEmail)
                .then((res)=>{
                    setLoading(false);
                    setMessage(translate('reset.sendResetEmail'))
                })
                .catch(err=>{
                    setLoading(false)
                    setError(translate('reset.error'));
                })
        }
        else{
            setError(translate('reset.validate'));
        }
        
    }
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            sendEmailToRecoveryPassword();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
        document.removeEventListener("keydown", listener);
        };
      });

    return (
        <AuthContainer title={translate('reset.header')}>
            {error && <Typography variant="body1" className={classes.errorText}>{error}</Typography>}
            <TextField 
                label={translate('reset.email')} 
                variant="outlined" 
                fullWidth 
                required
                autoComplete="email"
                id="email"
                autoFocus
                error={!recoveryEmail.length && error ? true : false}
                className={classes.marginInput} 
                value={recoveryEmail}
                onChange={(e)=>setRecoveryEmail(e.target.value)}
            />
            <Button 
                color="primary" 
                variant="contained" 
                className={classes.marginButton} 
                onClick={()=>sendEmailToRecoveryPassword()}
            > 
            {loading ? 
                <CircularProgress color="secondary" size = {30}/> 
                : translate('reset.reset')
            }
            </Button>
            <span className={classes.marginButton}>
            
            <Button onClick={()=>{history.push('/login')}} className={classes.link}><strong>{translate('reset.back')}</strong></Button>
            </span>
          
           
        </AuthContainer>
        
    )
}

export default connect(null,{setMessage})(ResetPassword);
