import React from 'react';
import { Route } from 'react-router-dom';
import  Register  from '../AuthPages/Register';
import Error from '../Pages/Error';
import Recovery from '../AuthPages/Recovery';
import ResetPassword from '../AuthPages/ResetPassword';
import ProfileDelete from '../Pages/Profile/ProfileDelete';
import Users from '../Pages/Users';
import LandingPage from '../Pages/LandingPage';

export default [
    <Route exact path ="/" render={(props)=><LandingPage {...props}/>} noLayout/>,
    <Route exact path ="/register" render = {(props)=><Register {...props}/>} noLayout/>,
    <Route path ="/register/:invite" render = {(props)=><Register {...props}/>} noLayout/>,
    <Route exact path ="/error" render={(props)=><Error {...props}/>} noLayout/>,
    <Route exact path ="/recovery/:token" render={(props)=><Recovery {...props}/>} noLayout/>,
    <Route exact path ="/reset-password" render={(props)=><ResetPassword {...props}/>} noLayout/>,
    <Route exact path ="/cancel/:token" render={(props)=><ProfileDelete {...props}/>}/>,
    <Route exact path ="/follow/user/request" render={(props)=><Users {...props}/>}/>,
];