import React,{ useState,useEffect } from 'react'
import { TextField, ClickAwayListener } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


//Edytowalny input po przez klikniecie
const LiveEditTextField = ({defaultValue,size,color,save,align,fieldType,placeholder,dataType,multiline,helperText}) => {
    const [editable, setEditable] = useState(false);
    const [value,setValue] = useState('');

    const handleSaveChanges = (e) =>{
        e.stopPropagation();
        value ? save(dataType,value) : save(dataType,null)
        setEditable(false);
    }

    useEffect(()=>{
        defaultValue === null ? setValue(''): setValue(defaultValue);
    },[defaultValue])
 
    const exitEditing =()=>{
        setValue(defaultValue);
        setEditable(false);
    }
    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            handleSaveChanges(event);
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });
    
    return (
        <ClickAwayListener onClickAway={exitEditing} >
        <TextField 
            InputProps={{ 
                disableUnderline: defaultValue ? true : editable ? true : value ? true : false, 
                readOnly:!editable, 
                style: {
                    fontSize: size || '1rem',
                    
                } ,
                endAdornment: ( 
                    <>
                        {editable &&
                        <>
                            <SaveIcon color="primary" 
                                onClick={(e)=>handleSaveChanges(e)}
                                style={{
                                    cursor:'pointer',
                                    margin:'0px 5px',
                                }}
                            /> 
                            <CloseIcon color="primary" 
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    exitEditing();
                                }}
                                style={{
                                    cursor:'pointer',
                                }}
                            /> 
                        </>
                        }
                    </>
                )
                
            }} 
            inputProps={{
                style:{
                    backgroundColor: editable && 'rgb(230, 230, 230)',
                    borderRadius: editable && 10,
                    textAlign: align || 'left',
                    padding:'5px 0px',
                    paddingLeft: fieldType==="date" ? '50px' : editable ? 5 : 0,
                    cursor: 'pointer',
                }                
            }}
            placeholder={!defaultValue ? placeholder : editable ? placeholder : ""}
            type= {fieldType || "text"}
            value = {value ||''}
            color = {color || "primary"}           
            onChange={(e)=>setValue(e.target.value)}
            onClick={(e)=>{
                e.stopPropagation();
                !editable && setValue(defaultValue)
                setEditable(true)
            }}
            multiline={multiline}
            
            
        />
        </ClickAwayListener>
    )
}

export default LiveEditTextField
